import styled, {keyframes} from "styled-components";
import * as React from "react";
import {useContext, useEffect, useRef, useState} from "react";
import {useParams} from "react-router-dom";
import {BsTrash} from "react-icons/bs";
import {MbjButton, MbjIconButton, MbjModal2, useModal} from "@mbj-front-monorepo/ui";
import {MyActivite} from "../CompetencesInterfaces";
import {CompetenceRooterContext} from "../CompRooter";
import {FaCheck, FaCheckCircle} from "react-icons/fa";
import {
  useAddNiveauVerif,
  useGetConnected,
  useGetEmployee,
  useGetMemberMe,
  useGetOneUser,
  useGetUserExpert,
  useMe
} from "@mbj-front-monorepo/data-access";


interface OneActiviteRCtrlProps{
  className?:string;
  Activite:MyActivite;
}

const OneActiviteRCtrl = (props:OneActiviteRCtrlProps)=>{
  const Context = useContext(CompetenceRooterContext);
  const { id } = useParams();
  const UserConnectedQuery = useGetConnected();
  const [realIdUser, setRealIdUser] =useState(0);
  const UserExpertsQuery = useGetUserExpert(realIdUser);
  const [AuthorizeToCertif, setAuthorizeToCertif] = useState(false);
  const {isShowing, toggle} = useModal();
  const mutationVerif = useAddNiveauVerif();
  const CertifComp = ()=>{
    const datas:any = {
      Employee:`/api/employees/${id||0}`,
      /** Format: iri-reference */
      UserVerif: `/api/users/${realIdUser||0}`,
      Activite: `/api/activites/${props.Activite.id}`,
      niveau: props.Activite.Niveau||0,
    }
    mutationVerif.mutate(datas, {
      onSuccess:()=>{
        toggle();
      }
    })

  }
  useEffect(()=>{
    if(UserConnectedQuery.data && UserExpertsQuery.data){
      const roles = UserConnectedQuery.data.roles;
      const droits = UserConnectedQuery.data.droits;
      let ActivitesIn = false
      UserExpertsQuery.data.Activites?.forEach(i=>{
        if((parseInt(i) === props.Activite.id)) ActivitesIn = true;
      })
      if(ActivitesIn){
        console.log('youpi')
      }
      setAuthorizeToCertif((droits?.indexOf(7+"")!==-1 || roles?.indexOf("ROLE_ENTREPRISE")!==-1 || ActivitesIn))

    }
  }, [UserConnectedQuery.data, UserExpertsQuery.data, props.Activite])
  useEffect(() => {
    if(UserConnectedQuery.data){
      setRealIdUser(UserConnectedQuery.data.id||0)
    }
  }, [UserConnectedQuery.data]);
  const context = useContext(CompetenceRooterContext);
  const refWrappy = useRef<HTMLDivElement>(null);
  const refWrappy2 = useRef<HTMLDivElement>(null);
  const [widthOver, setWidthOver] = useState(0);
  const [wrappyStdvis, setWrappyStdvis] = useState(true);
  const ClickOn = (e:any)=>{
    if(id && context && context.ClickNiveau && !context.ReadOnly){
      console.log('click>>>'+widthOver/20);
      context.ClickNiveau(props.Activite.id, widthOver/20, props.Activite.idObject||0)
    }
  }
  const TrashThisLine = ()=>{
    if(context && context.Trash){
      context.Trash(props.Activite.idObject||0)
    } else {
      alert("Pas de fonction de suppression")
    }
  }
  useEffect(() => {
    const handle = (e:any)=>{
      if(refWrappy.current && !context.ReadOnly){
        const elt = refWrappy.current.getBoundingClientRect();
        const leftE = elt.left;
        const topE = elt.top;
        const widthE = elt.width;
        const heightE = elt.height;
        const leftMe = e.clientX;
        const topMe = e.clientY;
        if((leftMe>leftE && leftMe < (leftMe+widthE)) && (topMe>topE && topMe<(topE+heightE))){
          setWrappyStdvis(false);
          console.log('on_it')
          const lg = (leftMe - leftE)/widthE*100;
          let wTo=0;
          if(lg<20){
            wTo = 20
          } else if(lg<40){wTo = 40}
          else if(lg<60){wTo = 60}
          else if(lg<80){wTo = 80}
          else {wTo = 100}
          setWidthOver(wTo);
        } else {
          setWrappyStdvis(true);
          setWidthOver(0);
        }
      }
    }
    document.addEventListener('mousemove', handle);
    return ()=>{
      document.removeEventListener('mousemove', handle);
    }
  }, [refWrappy, context]);
  return (
    <div className={`one_activite ${props.className}`}>
      <div className={`content_link`}>
        <div className={`libelle`}>
          <div className={`content_libelle`}>
            {!context.ReadOnly &&
              <MbjIconButton
                  onClick={TrashThisLine}
                  ariaLabel={"Supprimer cette compétence"}
                  icon={<BsTrash />}
                  themeColor={"warning"}
                  size={"xs"}
                  isRound={false}
                  disabled={props.Activite.Niveau === 0}
              />
            }
            <div style={{
              display: "flex",
              justifyContent: "flex-start",
              gap: "6px",
              alignItems: "center"
            }}>{props.Activite.isValidate ?
              <div className={"wrapperCert"} aria-label={"Niveau confirmée"}><FaCheck/></div> : ''}
              <span>{props.Activite.libelle}</span>
            </div>
          </div>
        </div>
        <div className={`wrapper_wrapper`}>
          <div className={`grid`}>
            <div className="grid_nivo"/>
            <div className="grid_nivo"/>
            <div className="grid_nivo"/>
            <div className="grid_nivo"/>
            <div className="grid_nivo"/>
          </div>
          <div className={`wrapper_niveau`} ref={refWrappy} onClick={ClickOn}>
            <div className={`wrappy_niveau ${wrappyStdvis ? 'vis' : 'invisible'}`} style={{width:(props.Activite.Niveau/5*100)+"%"}}/>
            <div className={`wrappy_niveau`} style={{width:widthOver+"%"}} ref={refWrappy2}/>
          </div>
        </div>
      </div>
      <MbjModal2 open={isShowing} closeMe={toggle} titre={"Certification niveau"} Wd={"500px"}>
        <div className={"in_modal_certif"} style={{padding:"1rem"}}>
          <p style={{textAlign:"center", fontSize:"16px"}}>Souhaitez-vous certifier que le collaborateur à le niveau <span style={{fontWeight:"bold"}}>{props.Activite.Niveau}</span><br/> sur l'activité<br/> <span style={{fontWeight:"bold"}}>{props.Activite.libelle}</span> ?</p>
          <div className="wrap_send_certif" style={{display:"flex", justifyContent:"center", gap:"10px", padding:"1rem"}}>
            <MbjButton themeColor={"grey"} size={"sm"} onClick={toggle}>Annuler</MbjButton>
            <MbjButton themeColor={"primary"} size={"sm"} disabled={mutationVerif.isLoading} onClick={CertifComp} isPending={mutationVerif.isLoading}>Oui je le certifie</MbjButton>
          </div>
        </div>
      </MbjModal2>
    </div>
  )
}

const Progress = () => keyframes`
    from {
        width: 0;
    }

`;

export const OneActiviteR = styled(OneActiviteRCtrl)`
  color:black;
  display: block;
  .in_modal_certif{
    padding: 1rem;
    p{
      text-align: center;
    }
    .wrap_sender_certif{
      padding: 1rem;
      display: flex;
      justify-content: center;
      gap:10px;
    }
  }
  .Link_to_certif{
    color:${props => props.theme.third};
    &:hover{
      cursor: pointer;
        filter: brightness(95%);
      text-decoration: underline;
    }
  }
  .wrapperCert{
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background: ${props=>props.theme.thirdLighten};
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: relative;
    &:hover::before{
      content: attr(aria-label);
      position: absolute;
      top: calc(100% + 3px);
      left: 50%;
      width: max-content;
      transform: translateX(-50%);
      padding: 0.25rem;
      background: black;
      color: white;
      font-size: 11px;
      z-index: 4;
    }
    svg{
      font-size: 12px;
      margin: auto;
      fill:white;
    }
  }
  .content_link{
    border-top: solid ${props=>props.theme.greyLighten} 1px;
    display: flex;
    justify-content: stretch;
  }
  .libelle{
    font-size: 14px;
    font-weight: bold;
    color:${props=>props.theme.dark};
    width: 40%;
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .content_libelle{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 6px;
    }
  }
  .wrapper_wrapper{
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
  }
  .grid{
    display: flex;
    z-index: 2;
    position: absolute;
    left: 0;
    top:0;
    width: 100%;
    height: 100%;
    justify-content: flex-start;
    .grid_nivo{
      border-right: dashed ${props=>props.theme.grey} 1px;
      width: 20%;
    }
  }
  .wrapper_niveau{
    width: 100%;
    height: 20px;
    border-radius: 4px;
    background: #ccc;
    overflow: hidden;
    z-index: 3;
    position: absolute;
    left: 0;
    top:50%;
    transform: translateY(-50%);
    &:hover{
      cursor: pointer;
    }
  }
  .wrappy_niveau{
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    background: ${props=>props.theme.third};
    transition: width 0.3s;
    animation: ${Progress} 1.5s ease-out;
    border-radius: 4px;
    &.invisible{
      opacity: 0;
    }
  }
`
