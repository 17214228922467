import styled from "styled-components";
import {useGetFormationsOFMBJ, useGetOFMBJMe, useGetSessionFormationsOFMBJ} from "@mbj-front-monorepo/data-access";
import {useEffect, useRef, useState} from "react";
import {ListApprenantSession} from "./ListApprennantSession";

interface ByFormationApprenantCtrlProps {
  className?:string;
}

const ByFormationApprenantCtrl = ({className}: ByFormationApprenantCtrlProps) => {
  const MeQuery = useGetOFMBJMe()
  const [idMe, setIdMe] = useState(0)
  const FormationsQuery = useGetFormationsOFMBJ(idMe)
  const [FormationCurrent, setFormationCurrent] = useState<{id:number, libelle:string}|null>(null)
  const SessionsQuery = useGetSessionFormationsOFMBJ(FormationCurrent ? FormationCurrent.id : 0)
  const [SessionCurrent, setSessionCurrent] = useState<{id:number, libelle:string}|null>(null)
  const refFormation = useRef<HTMLSelectElement>(null)
  const refSession = useRef<HTMLSelectElement>(null)
  useEffect(() => {
    if(MeQuery.data){
      setIdMe(MeQuery.data.id)
    }
  }, [MeQuery.data]);
  const ChangeFormation = ()=>{
    if(refFormation.current){
      const val = refFormation.current.value;
      console.log(val)
      const myForm = FormationsQuery.data?.find(f=>f.id === parseInt(val))
      if(myForm) {
        setFormationCurrent({id: (myForm.id || 0), libelle: (myForm.libelle || '')})
      } else {
        setFormationCurrent(null)
      }
    }
  }
  const ChangeSession = ()=>{
    if(refSession.current) {
      const val = refSession.current.value;
      const Session = SessionsQuery.data?.find(f => f.id === parseInt(val))
      if (Session) {
        setSessionCurrent({
          id: (Session.id || 0),
          libelle: (new Date((Session.startAt as string).slice(0, 10) + "T12:00:00")).toLocaleDateString()
        })
      } else {
        setSessionCurrent(null)
      }
    }
  }
  return (
    <div className={`by_formations ${className}`}>
      <div className="wrappy_select">
        <div className="wrap_selector">
          <label>Choix de la formation</label>
          <select ref={refFormation} value={FormationCurrent && FormationCurrent.id ? FormationCurrent.id : 0} onChange={ChangeFormation}>
            <option value={0}>--Choix de la formation--</option>
            {FormationsQuery.data?.map((item, idx)=>(
              <option value={item.id as number} key={`CF${item.id as number}${idx}`}>{item.libelle as string}</option>
            ))}
          </select>
        </div>
        {FormationCurrent &&
          <div className="wrap_selector">
            <label>Choix de la session</label>
            <select ref={refSession} value={SessionCurrent ? SessionCurrent.id : 0} onChange={ChangeSession}>
              <option value={0}>--Choix de la Session--</option>
              {SessionsQuery.data?.map((item, idx) => (
                <option value={item.id as number} key={`Session${item.id as number}${idx}`}>{(new Date((item.startAt as string).slice(0,10)+"T12:00:00")).toLocaleDateString()}</option>
              ))}
            </select>
          </div>
        }
      </div>
      {SessionCurrent &&
        <div className="wrap_result">
          <ListApprenantSession idSession={SessionCurrent.id}/>
        </div>
      }
    </div>

  )
}

export const ByFormationApprenant = styled(ByFormationApprenantCtrl)`
  .wrap_result{
    height: 600px;
  }
  .wrappy_select{
    margin: 30px 0;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    gap: 6px;
    label{
      display: block;
      color:${props => props.theme.primary};
      font-weight: bold;
    }
    select{
      height: 36px;
      border-radius: 4px;
      border:${props => props.theme.primary} 1px solid;
    }
  }
`
