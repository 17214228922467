import styled from "styled-components";
import { Controller, useForm } from "react-hook-form";
import { MbjButton, MbjFlex, MbjSimpleInput, MbjSimpleInputContainer } from "@mbj-front-monorepo/ui";
import React, { useRef } from "react";
import { Operation, useUpdateFormateur } from "@mbj-front-monorepo/data-access";
import Select, { SelectInstance } from "react-select";

interface FormateurUpdateFormCtrlProps {
  className?: string;
  formateur: Operation<"getFormateurItem">;
}

const FormateurUpdateFormCtrl = (props: FormateurUpdateFormCtrlProps) => {
  const updateMutation = useUpdateFormateur(props.formateur.id || -1);
  const selectRef = useRef<SelectInstance<{ label?: string; value?: string } | null>>(null);

  const methods = useForm({
    mode: "onChange",
    defaultValues: {
      nom: props.formateur.nom,
      prenom: props.formateur.prenom,
      statut: props.formateur.statut,
      mail: props.formateur.mail,
    },
  });

  return (
    <form
      className={props.className}
      onSubmit={methods.handleSubmit((data) => {
        console.log(data);
        updateMutation.mutate(data);
      })}
    >
      <MbjFlex direction={"column"}>
        <MbjSimpleInputContainer
          errors={methods.formState.errors}
          label={"Statut* :"}
          labelShrink={"0"}
          direction={"row"}
        >
          <Controller
            name={"statut"}
            control={methods.control}
            rules={{ required: { value: true, message: "Statut requis" } }}
            render={({ field }) => (
              <Select
                ref={selectRef}
                options={[
                  { value: "Permanent", label: "Permanent" },
                  { value: "Externe", label: "Intervenant extérieur" },
                ]}
                isClearable={true}
                defaultValue={{
                  label: field.value,
                  value: field.value,
                }}
                onChange={(selectedOption, triggeredAction) => {
                  if (selectedOption) {
                    field.onChange(selectedOption.value);
                  }
                  if (triggeredAction.action === "clear") {
                    field.onChange(null);
                  }
                }}
              />
            )}
          />
        </MbjSimpleInputContainer>
        <MbjSimpleInputContainer errors={methods.formState.errors} label={"Nom du formateur*"}>
          <MbjSimpleInput
            name={"nom"}
            id={"nom"}
            type={"text"}
            noPadding
            register={methods.register}
            registerOptions={{ required: { value: true, message: "Nom requis" } }}
          />
        </MbjSimpleInputContainer>
        <MbjSimpleInputContainer errors={methods.formState.errors} label={"Prénom du formateur*"}>
          <MbjSimpleInput
            name={"prenom"}
            id={"prenom"}
            type={"text"}
            noPadding
            register={methods.register}
            registerOptions={{ required: { value: true, message: "Prénom requis" } }}
          />
        </MbjSimpleInputContainer>
        <MbjSimpleInputContainer errors={methods.formState.errors} label={"Mail du formateur"}>
          <MbjSimpleInput name={"mail"} id={"mail"} type={"email"} noPadding register={methods.register} />
        </MbjSimpleInputContainer>
        <MbjFlex justify={"flex-end"}>
          {methods.formState.isDirty && (
            <MbjButton
              size={"md"}
              type="button"
              onClick={() => {
                methods.reset(props.formateur);
                selectRef.current?.selectOption({ label: props.formateur.statut, value: props.formateur.statut });
              }}
              themeColor={"warning"}
            >
              Réinitialiser
            </MbjButton>
          )}
          <MbjButton isPending={updateMutation.isLoading}>Confirmer</MbjButton>
        </MbjFlex>
      </MbjFlex>
    </form>
  );
};

export const FormateurUpdateForm = styled(FormateurUpdateFormCtrl)((props) => ({}));
