import styled from "styled-components";
import {useParams} from "react-router-dom";
import {useGetFormationsByStagaire} from "@mbj-front-monorepo/data-access";
import {useEffect, useMemo, useState} from "react";
import {OneFormationThere} from "./formation";
import {MbjLoading} from "@mbj-front-monorepo/ui";
import {FormationPast} from "./PagesGestion/FormationPast";
import {FormationCurrent} from "./PagesGestion/FormationCurrent";
import {FormationFuture} from "./PagesGestion/FormationFuture";

interface GestionFormationCtrlProps {
  className?:string;
}

const GestionFormationCtrl = (props: GestionFormationCtrlProps) => {
  const {id} = useParams();
  const FormationsQuery = useGetFormationsByStagaire(id ? parseInt(id) : 0)
  const [actualItem, setActualItem] = useState("current")
  const [formationsToAffiche, setFormationsToAffiche] = useState<OneFormationThere[]>([])
  const FormationsPast = useMemo(()=>{
    const list:OneFormationThere[] = [];
    if(FormationsQuery.data){
      FormationsQuery.data.forEach(f=>{
        if(f.endAt && new Date(f.endAt) < new Date()){
          list.push(f)
        }
      })
    }
    return list;
  }, [FormationsQuery.data])
  const FormationsCurrent = useMemo(()=>{
    const list:OneFormationThere[] = [];
    if(FormationsQuery.data){
      FormationsQuery.data.forEach(f=>{
        if(f.endAt && f.startAt && new Date(f.startAt) <= new Date() && new Date(f.endAt) >= new Date()){
          list.push(f)
        }
      })
    }
    return list;
  }, [FormationsQuery.data])
  const FormationsFuture = useMemo(()=>{
    const list:OneFormationThere[] = [];
    if(FormationsQuery.data){
      FormationsQuery.data.forEach(f=>{
        if(f.startAt && new Date(f.startAt) > new Date()){
          list.push(f)
        }
      })
    }
    return list;
  }, [FormationsQuery.data])
  useEffect(() => {
    setFormationsToAffiche(actualItem === "past" ? FormationsPast : actualItem === 'current' ? FormationsCurrent : FormationsFuture)
  }, [FormationsPast, FormationsCurrent, FormationsFuture, actualItem]);
  return (
    <div className={`gestion_formation ${props.className}`}>
      {FormationsQuery.isLoading ? <MbjLoading/> : (!FormationsQuery.data || FormationsQuery.isError) ? "Error BD" :
        <div className="in_gestion">
          <div className="side_gestion_formation">
            <div className={`item_gestion ${actualItem === 'past' ? 'current' : ''}`} onClick={()=>setActualItem('past')}>
              <span>Formations terminées</span>
            </div>
            <div className={`item_gestion ${actualItem === 'current' ? 'current' : ''}`} onClick={()=>setActualItem('current')}>
              <span>Formations en cours</span>
            </div>
            <div className={`item_gestion ${actualItem === 'future' ? 'current' : ''}`} onClick={()=>setActualItem('future')}>
              <span>Formations à venir</span>
            </div>
          </div>
          <div className="main_gestion">
            <div className="wrap_formations">
              {formationsToAffiche.length === 0 &&
                <div className={"no_formation"}>Aucune formation {actualItem === 'current' ? 'en cours' : actualItem === 'past' ? "terminée" : "à venir"}</div>
              }
              {formationsToAffiche.map((item, idx)=>{
                if(actualItem === 'past'){
                  return <FormationPast Formation={item} key={`FP${idx}`}/>
                } else if(actualItem === 'current'){
                  return <FormationCurrent Formation={item} key={`FC${idx}`}/>
                } else {
                  return <FormationFuture Formation={item} key={`FF${idx}`}/>
                }
              })}
            </div>
          </div>
        </div>
      }
    </div>
  )
}

export const GestionFormation = styled(GestionFormationCtrl)`
  width: 100%;
  margin: 12px auto;
  background: white;
  .wrap_formations{
    height: 150px;
    overflow-y: auto;
    padding: 1rem;
  }
  .no_formation{
    width: 100%;
    text-align: center;
    padding: 1rem;
    font-size: 17px;
    font-style: italic;
  }
  .in_gestion{
    display: flex;
    justify-items: flex-start;
    align-items: stretch;
    width: 100%;
  }
  .main_gestion{
    flex-grow: 1;
    border-left: solid ${props => props.theme.primary} 1px;

  }

  .item_gestion{
    padding: 0.25rem 0.5rem;
    &:hover{
      cursor: pointer;
      background: ${props=>props.theme.primaryLighten};
      color: white;
    }
    &.current{
      background: ${props => props.theme.primary};
      color: white;
    }
  }
  .wrap_one_formation{
    padding: 0.25rem 0.5rem;
    background: #ccc;
    border-radius: 4px;
    .libelle_formation{
      font-weight: bold;
    }
  }
`
