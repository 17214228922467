import styled from "styled-components";
import {
  useGetMetiers,
  useGetOneStagiaire,
  useGetProximitesMetier,
  useRefreshProxiMetierStagiaire
} from "@mbj-front-monorepo/data-access";
import {useParams} from "react-router-dom";
import {MbjButton, MbjLoading, MbjModal2, MbjWidgetClassic, useModal} from "@mbj-front-monorepo/ui";
import {useState} from "react";
import {AllDebouche} from "./AllDebouche";
import {RecommandationsDebouche} from "./RecommandationsDebouche";
import {ConsultDebouche} from "./ConsultDebouche";
import {ComparateurStagiaireMetier} from "../../../../components/Comparateur/ComparateurStagiaireMetier";

interface OneApprenantDeboucheCtrlProps {
  className?:string;
}

const OneApprenantDeboucheCtrl = ({className}: OneApprenantDeboucheCtrlProps) => {
  const {id} = useParams();
  const StagiaireQuery = useGetOneStagiaire(parseInt(id as string))
  const ProxiQuery = useGetProximitesMetier(parseInt(id as string))
  const {isShowing, toggle} = useModal()
  const [idMetierCurrent, setidMetierCurrent] = useState<number>(0)
  const [idProxiCurrent, setidProxiCurrent] = useState<number>(0)
  const mutationRefresh = useRefreshProxiMetierStagiaire(parseInt(id as string))
  const setMyId = (id:number, idProxi:number)=>{
    setidMetierCurrent(id);
    setidProxiCurrent(idProxi);
    toggle();
  }
  const RefreshMe = ()=>{
    mutationRefresh.mutate()
  }
  return (
    <div className={`one_app_debouche ${className}`}>
      <div className="last_recalc">
        {StagiaireQuery.isLoading ? <MbjLoading/>: (StagiaireQuery.isError || !StagiaireQuery.data) ? "ErrorBD":
          <div className="in_recalc">
            <div className="date">
              <div className="libelle">Dernière mise à jour des débouchés : </div>
              <div className="last_maj">{StagiaireQuery.data.lastMobiliteCalc ? new Date(StagiaireQuery.data.lastMobiliteCalc).toLocaleDateString() : 'Jamais calculé'}</div>
            </div>
            <div className="wrap_refresh">
              <MbjButton size={"sm"} onClick={RefreshMe} isPending={mutationRefresh.isLoading}>Rafraîchir le calcul</MbjButton>
            </div>
          </div>
        }
      </div>
      {ProxiQuery.isLoading ? <MbjLoading/> : (!ProxiQuery.data || ProxiQuery.isError) ? "Error...":
        <div className="wrap_proxis" style={{height:"250px"}}>
          <MbjWidgetClassic bodyDirection={"row"} gap={"10px"}>
            <MbjWidgetClassic basis={"calc(33.33% - 20px)"}>
              <RecommandationsDebouche Proxis={ProxiQuery.data} setIdMetier={setMyId}/>
            </MbjWidgetClassic>
            <MbjWidgetClassic basis={"calc(33.33% - 20px)"}>
              <AllDebouche Proxis={ProxiQuery.data} setIdMetier={setMyId}/>
            </MbjWidgetClassic>
            <MbjWidgetClassic basis={"calc(33.33% - 20px)"}>
                <ConsultDebouche setIdMetier={setMyId}/>
            </MbjWidgetClassic>
          </MbjWidgetClassic>
        </div>
      }
      <MbjModal2 Wd={"80%"} closeMe={toggle} open={isShowing} titre={"Mobilité"}>
        <div style={{padding:"1rem", height:"60vh", overflowY:"auto"}}>
          {id !== "0" && idMetierCurrent!==0 && idProxiCurrent!==0 ?
            <ComparateurStagiaireMetier idStagiaire={parseInt(id as string)} idMetier={idMetierCurrent} idProxi={idProxiCurrent}/>:
            <p>{id} {idMetierCurrent} {idProxiCurrent}</p>
          }
        </div>
      </MbjModal2>
    </div>
  )
}

export const OneApprenantDebouche = styled(OneApprenantDeboucheCtrl)`
  padding: 2%;
  height: 100%;
  .last_recalc{
    padding: 1rem 0;
  }
  .in_recalc{
    display: flex;
    justify-content: space-between;
    align-items: center;
    .date{
      display: flex;
      justify-content: flex-start;
      gap: 8px;
    }
  }
`
