import styled from "styled-components";
import { InformationsStagiaireCard } from "./InformationsStagiaireCard";
import { FormationsCard } from "./components/FormationsCard";
import { useParams } from "react-router-dom";
import { useGetOneStagiaire } from "@mbj-front-monorepo/data-access";
import { MbjErrorData, MbjLoading } from "@mbj-front-monorepo/ui";

interface InformationsOneApprenantCtrlProps {
  className?: string;
}

const InformationsOneApprenantCtrl = ({ className }: InformationsOneApprenantCtrlProps) => {
  const { id } = useParams();
  const stagiaireQuery = useGetOneStagiaire(id ? parseInt(id) : 0);

  if (stagiaireQuery.isLoading) {
    return <MbjLoading />;
  }

  if (stagiaireQuery.isError) {
    return <MbjErrorData />;
  }

  return (
    <div className={`infos_one_apprenant ${className}`}>
      <div className="container_grid">
        <div className="block_infos is-block-apprenant">
          <div className="head">Informations</div>
          <div className="body">
            <InformationsStagiaireCard stagiaire={stagiaireQuery.data} />
          </div>
        </div>
        <div className="block_infos is-block-formations">
          <div className="head">Formations</div>
          <div className="body">
            <FormationsCard />
          </div>
        </div>
      </div>
    </div>
  );
};

export const InformationsOneApprenant = styled(InformationsOneApprenantCtrl)((props) => ({
  padding: "1% 2%",
  height: "100%",
  ".container_grid": {
    display: "grid",
    height: "100%",
    gridTemplateColumns: "repeat(3, 1fr)",
    gridTemplateRows: "repeat(4, 1fr)",
    gap: "12px",
  },

  ".is-block-apprenant": {
    gridColumn: "1/2",
    gridRow: "1/5",
  },
  ".is-block-formations": {
    gridColumn: "2/-1",
    gridRow: "1/3",
  },
  ".block_infos": {
    background: "white",
    borderRadius: "4px",
    display: "flex",
    flexDirection: "column",
    ".head": {
      background: props.theme.primary,
      padding: "0.25rem",
      color: "white",
      borderRadius: "4px 4px 0 0",
    },
    ".body": {
      flexGrow: 1,
      overflowY: "auto",
    },
  },
}));
