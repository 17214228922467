import styled from "styled-components";
import {useParams} from "react-router-dom";
import {useGetStagiaireRecrutement} from "@mbj-front-monorepo/data-access";
import {MbjLoading} from "@mbj-front-monorepo/ui";
import {OneBlockDash} from "./OneBlockDash";

interface DashRecrutementCtrlProps {
  className?:string;
}

const DashRecrutementCtrl = ({className}: DashRecrutementCtrlProps) => {
  const {id} = useParams()
  const RecrutementsQuery = useGetStagiaireRecrutement(parseInt(id as string))
  return (
    <div className={`dash_recrut ${className}`}>
      {RecrutementsQuery.isLoading ? <MbjLoading/> : (!RecrutementsQuery.data || RecrutementsQuery.isError) ? 'Error...':
        <div className="in_dash_recrut">
          <OneBlockDash BigNumber={RecrutementsQuery.data.recrutStagiaireSearch?.length||0} libelle={"Postes à pouvoir"}/>
          <OneBlockDash BigNumber={RecrutementsQuery.data.recrutStagiaireSearchMetier?.length||0} libelle={"Métiers"}/>
          <OneBlockDash BigNumber={RecrutementsQuery.data.recrutStagiaireAlliance?.length||0} libelle={"Autres recrutements Alliance(s)"}/>
        </div>
      }
    </div>
  )
}

export const DashRecrutement = styled(DashRecrutementCtrl)`
  padding: 2%;
  .in_dash_recrut{
    display: grid;
    gap: 12px;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 300px;
  }
`
