import styled from "styled-components";
import {Operation} from "@mbj-front-monorepo/data-access";

interface OneFormationLineCtrlProps {
  className?:string;
  Formation:Operation<"getFormationsStagiaireItem">
}

const OneFormationLineCtrl = ({className, Formation}: OneFormationLineCtrlProps) => {
  return (
    <div className={`one_formation_line ${className}`}>
      <div className="lib_form">{Formation.libelle}</div>
      <div className="lib_dates">{Formation.startAt ? new Date(Formation.startAt).toLocaleDateString() : "--"}</div>
      <div className="lib_dates">{Formation.endAt ? new Date(Formation.endAt).toLocaleDateString() : "--"}</div>
    </div>
  )
}

export const OneFormationLine = styled(OneFormationLineCtrl)`
  padding: 0.2rem 0.25rem;
  display: flex;
  justify-content: flex-start;
  gap: 8px;
`
