import { Navigate, Route, Routes } from "react-router-dom";

import * as React from "react";
import { NotFound } from "../../components/NotFound/NotFound";
import styled from "styled-components";
import AlliancesDashboard from "./Dashboard/AlliancesDashboard";
import { MesAlliancesRooter } from "./MesAlliances/MesAlliancesRooter";

interface AllianceRooterCtrlProps {
  className?: string;
}

export const AllianceRooterCtrl = ({ className }: AllianceRooterCtrlProps) => {
  return (
    <Routes>
      <Route>
        <Route path={""} element={<Navigate to={"dasboard"} />} />
        <Route path={"dashboard"} element={<AlliancesDashboard />} />
        <Route path={"mes_alliances/*"} element={<MesAlliancesRooter />} />
        <Route path={"*"} element={<NotFound />} />
      </Route>
    </Routes>
  );
};

export const AllianceRooter = styled(AllianceRooterCtrl)``;
