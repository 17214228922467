import styled from "styled-components";
import {useGetFormationsOFMBJ, useGetOFMBJMe, useGetStagiaireByOF} from "@mbj-front-monorepo/data-access";
import {useEffect, useState} from "react";
import {BigNumber} from "./BigNumber";

interface NombreApprenantsCtrlProps {
  className?:string;
}

const NombreApprenantsCtrl = (props: NombreApprenantsCtrlProps) => {
  const OFMEQuery = useGetOFMBJMe();
  const [idOF, setidOF] = useState<number>(0)
  const StagiairesQuery = useGetStagiaireByOF(idOF)
  useEffect(() => {
    if(OFMEQuery.data) setidOF(OFMEQuery.data.id)
  }, [OFMEQuery.data]);
  return (
    <div className={`nb_formation ${props.className}`}>
      <BigNumber nb={StagiairesQuery.data?.length} isPending={StagiairesQuery.isLoading}/>
    </div>
  )
}

export const NombreApprenants = styled(NombreApprenantsCtrl)`
  width: 100%;
  height: 100%;
`
