import styled from "styled-components";

interface ExpertisesCtrlProps {
  className?: string;
}

const ExpertisesCtrl = (props: ExpertisesCtrlProps) => {
  return <div className={props.className}>Expertises</div>;
};

export const Expertises = styled(ExpertisesCtrl)((props) => ({
  padding: "1% 2%",
}));
