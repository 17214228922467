import styled from "styled-components";
import {MbjButton} from "@mbj-front-monorepo/ui";
import React from "react";

interface ModalValideValidationCtrlProps {
  className?:string;
  confirm:()=>void;
  closeMe:()=>void;
}

const ModalValideValidationCtrl = ({className, confirm, closeMe}: ModalValideValidationCtrlProps) => {
  return (
    <div className={`modal_valide_validation ${className}`}>
      <p>
        En cliquant sur "Confirmer", vous reconnaîtrez aux apprenants sélectionnés l’atteinte du niveau de maîtrise visé
        par cette formation, pour chaque compétence qui en était l’objet.<br/><br/>
        Vous pourrez également valider des niveaux de maîtrise différents pour ces compétences, apprenant par apprenant
        en vous reportant à leur dossier individuel
      </p>
      <div className="wrap_sender">
        <MbjButton size={"sm"} type="button" onClick={closeMe} themeColor={"grey"}>
          Annuler
        </MbjButton>
        <MbjButton size={"sm"} type="button" onClick={()=>confirm()} themeColor={"third"}>
          Confirmer
        </MbjButton>
      </div>
    </div>
  )
}

export const ModalValideValidation = styled(ModalValideValidationCtrl)`
  padding: 0.5rem 2rem;
  p{
    text-align: center;
    font-size: 17px;
  }
  .wrap_sender{
    padding: 1rem;
    display: flex;
    justify-content: center;
    gap: 10px;
    align-items: center;
  }
`
