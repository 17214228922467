import styled from "styled-components";
import {useGetOFMBJMe, useGetStagiaireByOF, useGetStagiaireSession} from "@mbj-front-monorepo/data-access";
import {useEffect, useState} from "react";
import {
  createColumnHelper,
  getCoreRowModel, getFacetedMinMaxValues, getFacetedRowModel, getFacetedUniqueValues,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable
} from "@tanstack/react-table";
import {getDateFR, GetElementType} from "@mbj-front-monorepo/utils";
import {
  createSelectColumn,
  MbjButton,
  MbjLink,
  MbjLoading,
  MbjScrollArea,
  MbjTableV2,
  MbjWidgetClassic
} from "@mbj-front-monorepo/ui";
import * as React from "react";
import {GoPlus} from "react-icons/go";
import {CiUnlock} from "react-icons/ci";

interface ListApprenantSessionCtrlProps {
  className?:string;
  idSession:number;
}

const ListApprenantSessionCtrl = ({className, idSession}: ListApprenantSessionCtrlProps) => {
  const StagiaireSessionQuery = useGetStagiaireSession(idSession)
  const columnHelper = createColumnHelper<GetElementType<typeof StagiaireSessionQuery.data>>();
  const columns = [
    createSelectColumn(columnHelper),
    columnHelper.group({
      header: "Identité",
      columns: [
        columnHelper.accessor((row) => row.Stagiaire?.nom||"", {
          header: "Nom",
          cell: (info) => <MbjLink to={'../../'+info.row.original.Stagiaire?.id?.toString() || "0"}>{info.getValue()}</MbjLink>,
        }),
        columnHelper.accessor((row) => (row.Stagiaire?.prenom||""), {
          header: "Prénom",
        }),
      ],
    }),
    columnHelper.group({
      header: "Email et téléphone",
      columns: [
        columnHelper.accessor((row) => (row.Stagiaire?.email||""), {
          header: "Email",
        }),
        columnHelper.accessor((row) => (row.Stagiaire?.telephone||"") || "--", {
          header: "Téléphone",
        }),

      ],
    }),
    columnHelper.group({
      header: "Informations formation",
      columns: [
        columnHelper.accessor((row) => (row.Stagiaire?.matricule||""), {
          header: "Identifiant",
        })
      ],
    }),
  ];

  const table = useReactTable({
    data: StagiaireSessionQuery?.data ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    debugTable: false,
    initialState: {
      sorting: [{ id: "Nom", desc: false }],
    },
    sortingFns: {
      MySortPrenom: (rowA: any, rowB: any, columnId: any): number =>
        rowA.getValue(columnId).value.localeCompare(rowB.getValue(columnId).value)
      //rowA.getValue(columnId).value < rowB.getValue(columnId).value ? 1 : -1,
    },
  });

  return (
    <div className={`all_apprenants ${className}`}>
      <MbjWidgetClassic bodyGrow={1} grow={1} bodyDirection={"column"} bodyNoWrap>
        {StagiaireSessionQuery.isLoading ? (
          <MbjLoading />
        ) : StagiaireSessionQuery.isError ? (
          <p>Error !</p>
        ) : (
          <>
            <div className={"space-between"}>
              <div className={"ResultNumber"}>{table.getRowModel().rows.length} résultats</div>
            </div>
            <MbjScrollArea overflowX={"auto"}>
              <MbjTableV2 table={table} Multiple={true}/>
            </MbjScrollArea>
          </>
        )}
      </MbjWidgetClassic>
    </div>
  )
}

export const ListApprenantSession = styled(ListApprenantSessionCtrl)`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  gap: 20px;
  height: 100%;
`
