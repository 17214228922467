import { Navigate, Route, Routes, To } from "react-router-dom";
import { NotFound } from "../../../components/NotFound/NotFound";
import { AllApprenant } from "./All/AllApprenant";
import { ByFormationApprenant } from "./ByFormation/ByFormationApprenant";
import { InternSubDesktopLayout } from "@mbj-front-monorepo/ui";
import * as React from "react";

const TabSubMenus: { libelle: string; to: To; onlyUM: boolean }[] = [
  { libelle: "Tous", to: "all", onlyUM: false },
  { libelle: "Par formation", to: "by_formation", onlyUM: false },
];

export const ListingApprenantRooter = () => {
  return (
    <Routes>
      <Route element={<InternSubDesktopLayout Links={TabSubMenus} />}>
        <Route path={""} element={<Navigate to={"all"} />} />
        <Route path={"all"} element={<AllApprenant />} />
        <Route path={"by_formation"} element={<ByFormationApprenant />} />
        <Route path={"*"} element={<NotFound />} />
      </Route>
    </Routes>
  );
};
