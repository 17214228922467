import React from "react";
import styled from "styled-components";
import { Operation, useDeleteFormateurFi } from "@mbj-front-monorepo/data-access";
import { BiEdit } from "react-icons/bi";
import { BsTrash } from "react-icons/bs";
import {
  MbjCard,
  MbjCardBody,
  MbjCardCtrlProps,
  MbjCardFooter,
  MbjCardHeader,
  MbjConfirmModal,
  MbjFlex,
  MbjHeading,
  MbjIconButton,
  MbjModal,
  MbjStaticInfo,
  useModal,
} from "@mbj-front-monorepo/ui";
import { getDateFR, GetElementType } from "@mbj-front-monorepo/utils";
import { MbjFormateurFiEditForm } from "./MbjFormateurFiEditForm";

interface MbjFormateurFiCardCtrlProps extends MbjCardCtrlProps {
  FormateurFi: GetElementType<Operation<"getFormateurFiCollection">>;
  isReadOnly?: boolean;
}

const MbjFormateurFiCardCtrl = (props: MbjFormateurFiCardCtrlProps) => {
  const { isShowing, toggle } = useModal();
  const { isShowing: ShowConfirm, toggle: toggleConfirm } = useModal();
  const mutationDelete = useDeleteFormateurFi();
  const HandleConfirmDelete = () => {
    mutationDelete.mutate(props.FormateurFi.id || -1, {
      onSettled: () => {
        toggleConfirm();
      },
    });
  };
  return (
    <>
      <MbjCard {...props}>
        <MbjCardHeader>
          <MbjHeading>
            Obtenu le {props.FormateurFi.obtentionAt ? getDateFR(new Date(props.FormateurFi.obtentionAt)) : "inconnu"} :{" "}
            {props.FormateurFi.nom}
          </MbjHeading>
        </MbjCardHeader>
        <MbjCardBody>
          <MbjFlex direction={"column"}>
            <MbjFlex gap={"15px"}>
              <MbjStaticInfo
                label="Niveau d'étude : "
                info={props.FormateurFi.NiveauEtude ? props.FormateurFi.NiveauEtude.libelle : "inconnu"}
                direction={"row"}
                gap={"5px"}
              />
              <MbjStaticInfo
                label="Discipline : "
                info={props.FormateurFi.Discipline ? props.FormateurFi.Discipline.libelle : "inconnu"}
                direction={"row"}
                gap={"5px"}
              />
            </MbjFlex>
            {props.FormateurFi.description && (
              <MbjStaticInfo label="Description : " info={props.FormateurFi.description} gap={"5px"} />
            )}
          </MbjFlex>
        </MbjCardBody>
        {!props.isReadOnly && (
          <MbjCardFooter justify={"flex-end"}>
            <MbjFlex direction={"row"} justify={"flex-end"} gap={"7px"}>
              <MbjIconButton
                size={"sm"}
                onClick={toggle}
                ariaLabel={"Editer la formation"}
                icon={<BiEdit />}
                themeColor={"secondary"}
                isRound={false}
              />
              <MbjIconButton
                size={"sm"}
                onClick={toggleConfirm}
                ariaLabel={"Supprimer la formation"}
                icon={<BsTrash />}
                themeColor={"warning"}
                isRound={false}
              />
            </MbjFlex>
          </MbjCardFooter>
        )}
      </MbjCard>
      <MbjModal isShowing={isShowing} hide={toggle} title="Modification d'une formation initiale">
        <MbjFormateurFiEditForm FormateurFi={props.FormateurFi} hideModal={toggle} />
      </MbjModal>
      <MbjConfirmModal
        isShowing={ShowConfirm}
        hide={toggleConfirm}
        title="Confirmation suppression formation"
        confirmText={"Voulez-vous vraiment supprimer cette formation ?"}
        confirmFunction={HandleConfirmDelete}
        isLoading={mutationDelete.isLoading}
      />
    </>
  );
};

export const MbjFormateurFiCard = styled(MbjFormateurFiCardCtrl)((props) => ({}));
