import { fetchApi } from "@mbj-front-monorepo/data-access";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

export const useGetSessionsByFormateur = (idFormateur: number) => {
  const promise = () =>
    fetchApi("/api/session_formation_o_f_m_b_js", { method: "get", query: { formateurs: idFormateur + "" } });
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["sessions", { idFormateur }], promise, {
    enabled: !!idFormateur,
  });
};

export const useGetFormateurSessions = (idFormateur: number) => {
  const promise = () => fetchApi("/api/formateur_sessions", { method: "get", query: { Formateur: idFormateur + "" } });
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["formateur_sessions", { idFormateur }], promise, {
    enabled: !!idFormateur,
  });
};

export const useAddFormateurSession = (idFormateur: number) => {
  const queryClient = useQueryClient();

  return useMutation(
    (idSession: number) => {
      return fetchApi("/api/formateur_sessions", {
        method: "post",
        json: {
          Formateur: `/api/formateurs/${idFormateur}`,
          Session: `/api/session_formation_o_f_m_b_js/${idSession}`,
        },
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["formateur_sessions"]);
        queryClient.invalidateQueries(["sessions"]);
        toast.success("Inscription à la session ajoutée !", { autoClose: 2000 });
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
};

export const useDeleteFormateurSession = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (idSession: number | number[]) => {
      if (Array.isArray(idSession)) {
        return Promise.all(
          idSession.map((id) => fetchApi("/api/formateur_sessions/{id}", { method: "delete", params: { id: id + "" } }))
        );
      } else {
        return fetchApi("/api/formateur_sessions/{id}", { method: "delete", params: { id: idSession + "" } });
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["formateur_sessions"]);
        queryClient.invalidateQueries(["sessions"]);
        toast.success("Session supprimée ", {});
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
};
