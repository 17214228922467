import { CustomSelect, MbjButton, MbjFlex, MbjSimpleInput, MbjSimpleInputContainer } from "@mbj-front-monorepo/ui";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { Operation, useUpdateInfoFormation } from "@mbj-front-monorepo/data-access";
import styled from "styled-components";

interface InformationsUpdateFormCtrlProps {
  data: Operation<"getFormationsOfmbjItem", "responses">;
}

const InformationsUpdateFormCtrl = (props: InformationsUpdateFormCtrlProps) => {
  const mutation = useUpdateInfoFormation(props.data.id);

  const methods = useForm({
    mode: "onChange",
    defaultValues: {
      libelle: props.data.libelle,
      effMin: props.data.effMin || null,
      effMax: props.data.effMax || null,
      cout: props.data.cout || null,
      duree: props.data.duree || null,
      modalite: props.data.modalite,
      isCertifiante: props.data.isCertifiante,
      isCPF: props.data.isCPF,
      isAlternance: props.data.isAlternance,
    },
  });

  return (
    <form
      onSubmit={methods.handleSubmit((data) => {
        console.log(data);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        mutation.mutate(data);
      })}
    >
      <MbjFlex gap={"10px"} direction={"column"}>
        <MbjSimpleInputContainer
          errors={methods.formState.errors}
          label={"Libellé :"}
          direction={"row"}
          labelSize={"small"}
          labelShrink={"0"}
        >
          <MbjSimpleInput
            name={"libelle"}
            id={"libelle"}
            type={"text"}
            noPadding
            register={methods.register}
            registerOptions={{ required: { value: true, message: "Libellé requis" } }}
          />
        </MbjSimpleInputContainer>
        <MbjSimpleInputContainer
          errors={methods.formState.errors}
          label={"Effectif minimal :"}
          direction={"row"}
          labelSize={"small"}
          labelShrink={"0"}
        >
          <MbjSimpleInput
            name={"effMin"}
            id={"effMin"}
            type={"number"}
            noPadding
            register={methods.register}
            registerOptions={{ valueAsNumber: true }}
          />
        </MbjSimpleInputContainer>
        <MbjSimpleInputContainer
          errors={methods.formState.errors}
          label={"Effectif maximal :"}
          direction={"row"}
          labelSize={"small"}
          labelShrink={"0"}
        >
          <MbjSimpleInput
            name={"effMax"}
            id={"effMax"}
            type={"number"}
            noPadding
            register={methods.register}
            registerOptions={{ valueAsNumber: true }}
          />
        </MbjSimpleInputContainer>

        <MbjSimpleInputContainer
          errors={methods.formState.errors}
          label={"Coût :"}
          direction={"row"}
          labelSize={"small"}
          labelShrink={"0"}
        >
          <MbjSimpleInput
            name={"cout"}
            id={"cout"}
            type={"number"}
            noPadding
            register={methods.register}
            registerOptions={{ valueAsNumber: true }}
          />
        </MbjSimpleInputContainer>
        <MbjSimpleInputContainer
          errors={methods.formState.errors}
          label={"Durée :"}
          direction={"row"}
          labelSize={"small"}
          labelShrink={"0"}
        >
          <MbjSimpleInput
            name={"duree"}
            id={"duree"}
            type={"number"}
            noPadding
            register={methods.register}
            registerOptions={{ required: { value: true, message: "Durée requise" }, valueAsNumber: true }}
          />
        </MbjSimpleInputContainer>
        <MbjSimpleInputContainer
          errors={methods.formState.errors}
          label={"Modalité :"}
          labelSize={"small"}
          labelShrink={"0"}
          direction={"row"}
        >
          <Controller
            name={"modalite"}
            control={methods.control}
            rules={{ required: { value: true, message: "Modalité requise" } }}
            render={({ field }) => (
              <CustomSelect
                options={[
                  { value: "Hybride", label: "Hybride" },
                  { value: "Distanciel", label: "Distanciel" },
                  { value: "Présentiel", label: "Présentiel" },
                ]}
                isClearable={true}
                defaultValue={{
                  label: field.value,
                  value: field.value,
                }}
                onChange={(selectedOption, triggeredAction) => {
                  if (selectedOption) {
                    field.onChange(selectedOption.value);
                  }
                  if (triggeredAction.action === "clear") {
                    field.onChange(null);
                  }
                }}
              />
            )}
          />
        </MbjSimpleInputContainer>
        <MbjFlex>
          <MbjSimpleInputContainer
            errors={methods.formState.errors}
            label={"Est certifiante :"}
            direction={"row"}
            labelSize={"small"}
            labelShrink={"0"}
          >
            <MbjSimpleInput
              name={"isCertifiante"}
              id={"isCertifiante"}
              type={"checkbox"}
              noPadding
              register={methods.register}
            />
          </MbjSimpleInputContainer>
          <MbjSimpleInputContainer
            errors={methods.formState.errors}
            label={"Est éligible au CPF :"}
            direction={"row"}
            labelSize={"small"}
            labelShrink={"0"}
          >
            <MbjSimpleInput name={"isCPF"} id={"isCPF"} type={"checkbox"} noPadding register={methods.register} />
          </MbjSimpleInputContainer>
          <MbjSimpleInputContainer
            errors={methods.formState.errors}
            label={"Alternance possible :"}
            direction={"row"}
            labelSize={"small"}
            labelShrink={"0"}
          >
            <MbjSimpleInput
              name={"isAlternance"}
              id={"isAlternance"}
              type={"checkbox"}
              noPadding
              register={methods.register}
            />
          </MbjSimpleInputContainer>
        </MbjFlex>
        <MbjFlex alignSelf={"end"} width={"unset"}>
          {methods.formState.isDirty && (
            <MbjButton size={"md"} type="button" onClick={() => methods.reset(props.data)} themeColor={"warning"}>
              Réinitialiser
            </MbjButton>
          )}
          <MbjButton size={"md"} isPending={mutation.isLoading}>
            Confirmer
          </MbjButton>
        </MbjFlex>
      </MbjFlex>
    </form>
  );
};

export const InformationsUpdateForm = styled(InformationsUpdateFormCtrl)``;
