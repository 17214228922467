import {Navigate, Route, Routes, useParams} from "react-router-dom";
import {NotFound} from "../../../components/NotFound/NotFound";
import {InformationsOneApprenant} from "./00_Informations/InformationsOneApprenant";
import {InternDesktopLayout, MbjHeading, MbjLink, MbjLoading, MbjSectionMenu} from "@mbj-front-monorepo/ui";
import {FaUserCircle} from "react-icons/fa";
import * as React from "react";
import {useGetOneStagiaire} from "@mbj-front-monorepo/data-access";
import {CompetenceOneApprenant} from "./01_Competences/CompetenceOneApprenant";
import {FormationOneApprenant} from "./02_Formations/FormationOneApprenant";
import styled from "styled-components";
import {OneApprenantCV} from "./03_CV/OneApprenantCV";
import {OneApprenantDebouche} from "./04_Debouches/OneApprenantDebouche";
import {OneApprenantRecrutement} from "./05_Recrutements/OneApprenantRecrutement";
import {ApprenantRecrutementRooter} from "./05_Recrutements/ApprenantRecrutementRooter";

interface OneApprenantRooterCtrlProps{
  className?:string;
}

const OneApprenantsRooterCtrl = ({className}:OneApprenantRooterCtrlProps)=>{
  const {id} = useParams();
  const StagiaireQuery = useGetOneStagiaire(id ? parseInt(id) : 0)
  return (
    <Routes>
      <Route element={

        <InternDesktopLayout
          type="OF"
          mbjSectionMenu={
            <MbjSectionMenu
              className={"custom-sectionMenu "+className}
              otherContent={
                <div className={"custom-content-menu"}>
                  {StagiaireQuery.data ? (
                    <>
                      <FaUserCircle className={"icon-profile-menu"} />
                      <MbjHeading marginIn={"0"} themeColor={"primary"} level={"h2"}>
                        {StagiaireQuery.data?.prenom}  {StagiaireQuery.data?.nom?.toUpperCase()}
                      </MbjHeading>
                    </>
                  ) : (
                    <MbjLoading noMargin={true} height={"25px"} />
                  )}
                </div>
              }
            >
              <MbjLink to="informations">Informations</MbjLink>
              <MbjLink to="cv">CV</MbjLink>
              <MbjLink to="competences">Compétences</MbjLink>
              <MbjLink to="formations">Formations</MbjLink>
              <MbjLink to="debouches">Évolutions</MbjLink>
              <MbjLink to="recrutements">Recrutements</MbjLink>
            </MbjSectionMenu>
          }
        />

      }>
        <Route path={""} element={<Navigate to={"informations"}/>}/>
        <Route path={"informations"} element={<InformationsOneApprenant/>}/>
        <Route path={"cv"} element={<OneApprenantCV/>}/>
        <Route path={"competences/*"} element={<CompetenceOneApprenant/>}/>
        <Route path={"formations"} element={<FormationOneApprenant/>}/>
        <Route path={"debouches"} element={<OneApprenantDebouche/>}/>
        <Route path={"recrutements/*"} element={<ApprenantRecrutementRooter/>}/>
        <Route path={"*"} element={<NotFound/>}/>
      </Route>
    </Routes>
  )
}

export const OneApprenantsRooter = styled(OneApprenantsRooterCtrl)`
  min-height: fit-content;
  justify-content: space-between;
  .custom-content-menu{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5em;

    .icon-profile-menu{
      fill: ${props=>props.theme.primaryDarken};
      height: 25px;
      width: 25px;
    }
  }
`
