import { fetchApi, Operation } from "@mbj-front-monorepo/data-access";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

export const useGetFormateurs = (OrganismeFormationMbjId: number | undefined) => {
  const promise = () =>
    fetchApi("/api/formateurs", { method: "get", query: { OrganismeFormationMBJ: OrganismeFormationMbjId + "" } });

  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["formateurs", { OrganismeFormationMbjId }], promise, {
    enabled: !!OrganismeFormationMbjId,
  });
};

export const useGetOneFormateur = (id: number) => {
  const promise = () => fetchApi("/api/formateurs/{id}", { method: "get", params: { id: id + "" } });
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["formateur", { id }], promise, {
    enabled: id !== 0,
  });
};

export const useAddFormateur = (organismeId: number) => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: Operation<"postFormateurCollection", "requestBody">) => {
      console.log(data);
      return fetchApi("/api/formateurs", {
        method: "post",
        json: { ...data, OrganismeFormationMBJ: `/api/organisme_formation_m_b_js/${organismeId}` },
      });
    },
    {
      onSuccess: (newData) => {
        queryClient.invalidateQueries(["formateurs"]);
        toast.success("Formateur ajouté !", { autoClose: 2000 });
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
};

export const useUpdateFormateur = (formateurId: number) => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: Operation<"putFormateurItem", "requestBody">) => {
      return fetchApi("/api/formateurs/{id}", {
        method: "put",
        json: data,
        params: {
          id: formateurId + "",
        },
      });
    },
    {
      onSuccess: (newData) => {
        queryClient.invalidateQueries(["formateurs"]);
        toast.success("Formateur modifié !");
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
};

export const useDeleteFormateur = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (formateurId: number | number[]) => {
      if (Array.isArray(formateurId)) {
        return Promise.all(
          formateurId.map((formateurId) =>
            fetchApi("/api/formateurs/{id}", { method: "delete", params: { id: formateurId + "" } })
          )
        );
      } else {
        return fetchApi("/api/formateurs/{id}", { method: "delete", params: { id: formateurId + "" } });
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["formateurs"]);
        toast.success("Formateur(s) supprimé(s)", {});
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
};

export const useGetFormateurXp = (formateurId?: number) => {
  const promise = () => fetchApi("/api/formateur_xps", { method: "get", query: { Formateur: formateurId + "" } });
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["formateur_xps", { formateurId }], promise, {
    enabled: !!formateurId,
  });
};

export const useAddFormateurXp = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (data: Operation<"postFormateurXpCollection", "requestBody">) => {
      return fetchApi("/api/formateur_xps", { method: "post", json: data });
    },
    {
      onSuccess: (newXp) => {
        queryClient.invalidateQueries(["formateur_xps"]);
        queryClient.invalidateQueries(["competences_formateur"]);
        toast.success("Expérience ajoutée !", {});
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
};

export const useMutateFormateurXp = (formateurXpId: number) => {
  const queryClient = useQueryClient();
  return useMutation(
    (data: Operation<"putFormateurXpItem", "requestBody">) => {
      console.log(formateurXpId);
      return fetchApi("/api/formateur_xps/{id}", { method: "put", json: data, params: { id: formateurXpId + "" } });
    },
    {
      onSuccess: (newExperience) => {
        queryClient.invalidateQueries(["formateur_xps"]);
        toast.success("Expérience modifiée !", {});
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
};

export const useDeleteFormateurXp = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (formateurXpId: number) => {
      return fetchApi("/api/formateur_xps/{id}", { method: "delete", params: { id: formateurXpId + "" } });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["formateur_xps"]);
        queryClient.invalidateQueries(["competences_formateur"]);
        toast.success("Expérience supprimée", {});
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
};

export const useGetFormateurFi = (formateurId?: number) => {
  const promise = () => fetchApi("/api/formateur_fis", { method: "get", query: { Formateur: formateurId + "" } });
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["formateur_fis", { formateurId }], promise, {
    enabled: !!formateurId,
  });
};

export const useMutateFormateurFi = (id: number) => {
  const queryClient = useQueryClient();
  return useMutation(
    (data: Operation<"putFormateurFiItem", "requestBody">) => {
      return fetchApi("/api/formateur_fis/{id}", { method: "put", json: data, params: { id: id + "" } });
    },
    {
      onSuccess: (newFi) => {
        queryClient.invalidateQueries(["formateur_fis"]);
        toast.success("Formation modifiée !", {});
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
};

export const useAddFormateurFi = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (data: Operation<"postFormateurFiCollection", "requestBody">) => {
      return fetchApi("/api/formateur_fis", { method: "post", json: data });
    },
    {
      onSuccess: (newFi) => {
        queryClient.invalidateQueries(["formateur_fis"]);
        queryClient.invalidateQueries(["competences_formateur"]);
        toast.success("Formation ajoutée !", {});
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
};

export const useDeleteFormateurFi = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (formateurFiId: number) => {
      return fetchApi("/api/formateur_fis/{id}", { method: "delete", params: { id: formateurFiId + "" } });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["formateur_fis"]);
        queryClient.invalidateQueries(["competences_formateur"]);
        toast.success("Formation supprimée", {});
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
};

export const useGetFormateurCompetence = (idFormateur: number) => {
  const promise = () => fetchApi("/api/formateur_competences", { method: "get", query: { Formateur: idFormateur } });
  return useQuery(["competences_formateur", { idFormateur }], promise, {
    enabled: idFormateur !== 0,
  });
};
