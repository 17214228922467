import styled from "styled-components";
import { CustomSelect, MbjButton, MbjFlex, MbjSimpleInput, MbjSimpleInputContainer } from "@mbj-front-monorepo/ui";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useAddFormateur } from "@mbj-front-monorepo/data-access";

interface ModalNewFormateurCtrlProps {
  className?: string;
  organismeId: number;
}

const ModalNewFormateurCtrl = (props: ModalNewFormateurCtrlProps) => {
  const mutationFormateur = useAddFormateur(props.organismeId);

  const methods = useForm({
    mode: "onChange",
    defaultValues: {
      nom: undefined,
      prenom: undefined,
      statut: "Permanent",
      mail: undefined,
    },
  });

  return (
    <form
      className={props.className}
      onSubmit={methods.handleSubmit((data) => {
        console.log(data);
        mutationFormateur.mutate(data);
      })}
    >
      <MbjFlex direction={"column"}>
        <MbjSimpleInputContainer
          errors={methods.formState.errors}
          label={"Statut* :"}
          labelShrink={"0"}
          direction={"row"}
        >
          <Controller
            name={"statut"}
            control={methods.control}
            rules={{ required: { value: true, message: "Statut requis" } }}
            render={({ field }) => (
              <CustomSelect
                options={[
                  { value: "Permanent", label: "Permanent" },
                  { value: "Externe", label: "Intervenant extérieur" },
                ]}
                isClearable={true}
                defaultValue={{
                  label: field.value,
                  value: field.value,
                }}
                onChange={(selectedOption, triggeredAction) => {
                  if (selectedOption) {
                    field.onChange(selectedOption.value);
                  }
                  if (triggeredAction.action === "clear") {
                    field.onChange(null);
                  }
                }}
              />
            )}
          />
        </MbjSimpleInputContainer>
        <MbjSimpleInputContainer errors={methods.formState.errors} label={"Nom du formateur*"}>
          <MbjSimpleInput
            name={"nom"}
            id={"nom"}
            type={"text"}
            noPadding
            register={methods.register}
            registerOptions={{ required: { value: true, message: "Nom requis" } }}
          />
        </MbjSimpleInputContainer>
        <MbjSimpleInputContainer errors={methods.formState.errors} label={"Prénom du formateur*"}>
          <MbjSimpleInput
            name={"prenom"}
            id={"prenom"}
            type={"text"}
            noPadding
            register={methods.register}
            registerOptions={{ required: { value: true, message: "Prénom requis" } }}
          />
        </MbjSimpleInputContainer>
        <MbjSimpleInputContainer errors={methods.formState.errors} label={"Mail du formateur"}>
          <MbjSimpleInput name={"mail"} id={"mail"} type={"email"} noPadding register={methods.register} />
        </MbjSimpleInputContainer>

        <MbjButton alignSelf={"flex-end"} isPending={mutationFormateur.isLoading}>
          Ajouter le formateur
        </MbjButton>
      </MbjFlex>
    </form>
  );
};

export const ModalNewFormateur = styled(ModalNewFormateurCtrl)((props) => ({ padding: "1rem" }));
