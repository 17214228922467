import styled from "styled-components";
import {useParams} from "react-router-dom";
import {useGetFormationsByStagaire} from "@mbj-front-monorepo/data-access";
import {useMemo} from "react";
import {GestionFormation} from "./GestionFormation";
import {InscriptionSession} from "./InscriptionSession";

interface FormationOneApprenantCtrlProps {
  className?:string;
}
interface OneFormationThere{
  id?:number;
  libelle?:string;
  startAt?:string;
  endAt?:string;
}
const FormationOneApprenantCtrl = ({className}: FormationOneApprenantCtrlProps) => {
  return (
    <div className={`formation_one_apprenant ${className}`}>
      <div className="block_form">
        <h3>Affecter à une formation</h3>
        <div className="content_block">
          <GestionFormation/>
        </div>
      </div>
      <div className="block_form">
        <h3>Inscrire</h3>
        <InscriptionSession/>
      </div>
    </div>
  )
}

export const FormationOneApprenant = styled(FormationOneApprenantCtrl)`
  padding: 2%;
`
