import styled from "styled-components";
import { useGetFormationsOFMBJ, useGetOFMBJMe } from "@mbj-front-monorepo/data-access";
import * as React from "react";
import {
  createSelectColumn,
  MbjButton,
  MbjErrorData,
  MbjLink,
  MbjLoading,
  MbjModal2,
  MbjScrollArea,
  MbjTableV2,
  useModal,
} from "@mbj-front-monorepo/ui";
import {
  createColumnHelper,
  getCoreRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { GetElementType } from "@mbj-front-monorepo/utils";
import { ModalNewFormation } from "./ModalNewFormation";

interface CentreFormationFormationsCtrlProps {
  className?: string;
}

const CentreFormationFormationsCtrl = (props: CentreFormationFormationsCtrlProps) => {
  const MeQuery = useGetOFMBJMe();
  const FormationsQuery = useGetFormationsOFMBJ(MeQuery.data?.id);
  const { isShowing, toggle } = useModal();

  const AddFormation = () => {
    toggle();
  };
  const columnHelper = createColumnHelper<GetElementType<typeof FormationsQuery.data>>();
  const columns = [
    createSelectColumn(columnHelper),
    columnHelper.group({
      header: "Libelle",
      columns: [
        columnHelper.accessor((row) => row.libelle, {
          header: "Nom",
          cell: (info) => <MbjLink to={"../" + info.row.original.id?.toString() || "0"}>{info.getValue()}</MbjLink>,
        }),
      ],
    }),
    columnHelper.group({
      header: "Informations Formation",
      columns: [
        columnHelper.accessor((row) => row.duree || "--", {
          header: "Durée en heures",
        }),
        columnHelper.accessor((row) => row.description || "--", {
          header: "Description",
        }),
        columnHelper.accessor((row) => row.modalite, {
          header: "Modalités",
        }),
        columnHelper.accessor((row) => (!!row.isCPF && row.isCPF ? "Oui" : "Non"), {
          header: "CPF",
        }),
        columnHelper.accessor((row) => row.type, {
          header: "Type",
        }),
      ],
    }),
  ];

  const table = useReactTable({
    data: FormationsQuery?.data ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    debugTable: false,
    initialState: {
      sorting: [{ id: "Nom", desc: false }],
    },
    sortingFns: {
      MySortPrenom: (rowA: any, rowB: any, columnId: any): number =>
        rowA.getValue(columnId).value.localeCompare(rowB.getValue(columnId).value),
      //rowA.getValue(columnId).value < rowB.getValue(columnId).value ? 1 : -1,
    },
  });
  return (
    <div className={`formations ${props.className}`}>
      <div className={"wrap_bts"}>
        <MbjButton size={"sm"} type="button" onClick={() => AddFormation()} themeColor={"primary"}>
          + Ajouter une formation
        </MbjButton>
      </div>
      {FormationsQuery.isLoading ? (
        <MbjLoading />
      ) : FormationsQuery.isError ? (
        <MbjErrorData />
      ) : (
        <>
          <MbjScrollArea overflowX={"auto"}>
            <MbjTableV2 table={table} Multiple={true} />
          </MbjScrollArea>
          <MbjModal2 open={isShowing} closeMe={toggle} titre={"Nouvelle formation"} Wd={"40%"}>
            <ModalNewFormation closeMe={toggle} />
          </MbjModal2>
        </>
      )}
    </div>
  );
};

export const CentreFormationFormations = styled(CentreFormationFormationsCtrl)`
  padding: 1% 2%;
  height: 100%;
  .wrap_bts {
    display: flex;
    justify-content: flex-end;
  }
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  gap: 20px;
  .space-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .ResultNumber {
    font-size: 20px;
  }
`;
