import styled from "styled-components";
import { useEffect, useMemo, useState } from "react";
import { OneSelectorDate } from "./OneSelectorDate";

interface NBFormeDateCtrlProps {
  className?: string;
  metrix: { annee: string; moisStr: string; moisNB: number; trim: number; nbStagiaire: number }[];
}
interface OneMonth {
  id: number;
  libelle: string;
  nb: number;
}
interface OneTrim {
  id: number;
  libelle: string;
  nb: number;
  mois: OneMonth[];
}
interface OneAnnee {
  id: number;
  libelle: string;
  nb: number;
  trims: OneTrim[];
}
const tabMoisX = [
  "Janvier",
  "Février",
  "Mars",
  "Avril",
  "Mai",
  "Juin",
  "Juillet",
  "Août",
  "Septembre",
  "Octobre",
  "Novembre",
  "Décembre",
];
const ChoixBy = ["Année", "Trimestre", "Mois"];
const NBFormeDateCtrl = ({ className, metrix }: NBFormeDateCtrlProps) => {
  const [choixAnnee, setChoixAnnee] = useState<number[]>([]);
  const [choixTrim, setChoixTrim] = useState<number[]>([]);
  const [choixMois, setChoixMois] = useState<number[]>([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]);
  const [myChoixAnnee, setMyChoixAnnee] = useState<number[]>([]);
  const [myChoixTrim, setMyChoixTrim] = useState<number[]>([1, 2, 3, 4]);
  const [myChoixMois, setMyChoixMois] = useState<number[]>([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]);
  const [byPar, setByPar] = useState("Mois");
  const Switch = (id: number, type: string) => {
    if (type === "an") {
      const myC = myChoixAnnee.find((a) => a === id);
      if (myC) {
        setMyChoixAnnee((as) => [...as.filter((x) => x !== id)]);
      } else {
        setMyChoixAnnee((as) => [...as, id]);
      }
    } else if (type === "trim") {
      const myC = myChoixTrim.find((a) => a === id);
      if (myC) {
        setMyChoixTrim((as) => [...as.filter((x) => x !== id)]);
      } else {
        setMyChoixTrim((as) => [...as, id]);
      }
    } else {
      const myC = myChoixMois.find((a) => a === id);
      if (myC) {
        setMyChoixMois((as) => [...as.filter((x) => x !== id)]);
      } else {
        setMyChoixMois((as) => [...as, id]);
      }
    }
  };
  useEffect(() => {
    const today = new Date();
    const yearToday = today.getFullYear();
    const year3 = yearToday - 3;
    let yearRotate = year3;
    const TabY: number[] = [];
    while (yearRotate <= yearToday) {
      TabY.push(yearRotate);
      yearRotate++;
    }
    setChoixAnnee(TabY);
    setMyChoixAnnee(TabY);
    setChoixTrim([1, 2, 3, 4]);
  }, []);
  const myMetrix = useMemo(() => {
    const list: OneAnnee[] = [];
    myChoixAnnee.forEach((a) => {
      const MetrixAnnee = metrix.filter((m) => m.annee === a + "");

      const tabTrim: OneTrim[] = [];
      let totalAn = 0;
      myChoixTrim.forEach((t) => {
        // console.log(t)
        const MetrixTrim = MetrixAnnee.filter((m) => m.trim === t);

        const tabMois: OneMonth[] = [];
        let totalTrim = 0;
        myChoixMois
          .filter((x) => Math.ceil(x / 3) === t)
          .forEach((m) => {
            const MetrixMois = MetrixTrim.find((mx) => mx.moisNB === m);
            totalTrim += MetrixMois?.nbStagiaire || 0;
            tabMois.push({
              id: m,
              libelle: tabMoisX[m - 1],
              nb: MetrixMois?.nbStagiaire || 0,
            });
          });
        totalAn += totalTrim;
        tabTrim.push({
          id: t,
          libelle: "T" + t,
          mois: tabMois,
          nb: totalTrim,
        });
      });
      list.push({
        id: a,
        libelle: a + "",
        nb: totalAn,
        trims: tabTrim,
      });
    });

    return list;
  }, [myChoixAnnee, myChoixMois, myChoixTrim, metrix]);
  return (
    <div className={`nbFormeDate ${className}`}>
      <div className="wrap_chooses">
        <div className="wrap_selects">
          <OneSelectorDate
            clickOnChoice={Switch}
            choicesCurr={myChoixAnnee.map((x) => {
              return { id: x, libelle: x + "" };
            })}
            Choices={choixAnnee.map((x) => {
              return { id: x, libelle: x + "" };
            })}
            libelle={"Années"}
            id={"an"}
          />
          <OneSelectorDate
            clickOnChoice={Switch}
            choicesCurr={myChoixTrim.map((x) => {
              return { id: x, libelle: "T" + x };
            })}
            Choices={choixTrim.map((x) => {
              return { id: x, libelle: "T" + x };
            })}
            libelle={"Trimestres"}
            id={"trim"}
          />
          <OneSelectorDate
            clickOnChoice={Switch}
            choicesCurr={myChoixMois.map((x) => {
              return { id: x, libelle: tabMoisX[x - 1] };
            })}
            Choices={choixMois.map((x) => {
              return { id: x, libelle: tabMoisX[x - 1] };
            })}
            libelle={"Mois"}
            id={"mois"}
          />
        </div>
        <div className="wrap_types">
          <div className={`one_choice_by ${byPar === "Année" ? "selected" : ""}`} onClick={() => setByPar("Année")}>
            Année
          </div>
          <div
            className={`one_choice_by ${byPar === "Trimestre" ? "selected" : ""}`}
            onClick={() => setByPar("Trimestre")}
          >
            Trimestre
          </div>
          <div className={`one_choice_by ${byPar === "Mois" ? "selected" : ""}`} onClick={() => setByPar("Mois")}>
            Mois
          </div>
        </div>
      </div>
      <div className="wrap_metrix">
        {myMetrix.map((item, idx) => (
          <div className={`wrap_an is_wrap ${byPar === "Année" ? "flexy" : ""}`} key={`A${idx}`}>
            <div className="lib_an is-lib">{item.libelle}</div>
            {byPar === "Année" ? (
              <div className="number_is">{item.nb}</div>
            ) : (
              <div className={`wrapper_trim`}>
                {item.trims.map((itemT, idxT) => (
                  <div className={`one_trim ${byPar === "Trimestre" ? "flexy" : ""} is_wrap`} key={`T${idxT}${idx}`}>
                    <div className="lib_trim is-lib">{itemT.libelle}</div>
                    {byPar === "Trimestre" ? (
                      <div className="number_is">{itemT.nb}</div>
                    ) : (
                      <div className="wrapper_mois">
                        {itemT.mois.map((itemM, idxM) => (
                          <div className="one_mois" key={`M${idx}${idxT}${idxM}`}>
                            <div className="lib_mois is-lib">{itemM.libelle}</div>
                            <div className="number_is">{itemM.nb}</div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export const NBFormeDate = styled(NBFormeDateCtrl)`
  max-height: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  .wrap_types {
    display: flex;
    justify-content: flex-start;
    gap: 12px;
    padding: 0.25rem 0.5rem;
    .one_choice_by {
      border-radius: 4px;
      padding: 0.25rem 0.5rem;
      border: solid 1px ${(props) => props.theme.primaryLighten};
      &:hover {
        cursor: pointer;
      }
      &.selected {
        background: ${(props) => props.theme.primaryLighten};
        color: white;
      }
    }
  }
  .one_mois {
    display: flex;
    justify-content: space-between;
  }
  .wrapper_trim,
  .wrapper_mois {
    margin-left: 10px;
  }
  .is_wrap {
    &.flexy {
      display: flex;
      justify-content: space-between;
      .number_is {
        font-weight: bold;
      }
    }
  }
  .is-lib {
    font-weight: bold;
  }
  .lib_an {
    font-size: 18px;
    padding-bottom: 0.25rem;
    color: ${(props) => props.theme.primaryLighten};
    border-bottom: solid ${(props) => props.theme.primaryLighten} 1px;
  }
  .lib_trim {
    font-size: 16px;
    color: ${(props) => props.theme.secondaryDarken};
  }
  .wrap_metrix {
    flex-grow: 1;
    overflow-y: auto;
  }
  .wrap_chooses {
    padding: 0.5rem;
  }
  .wrap_selects {
    display: flex;
    justify-content: flex-start;
    gap: 6px;
    align-items: center;
    padding: 0.5rem;
  }
  .wrap_metrix {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 0 0.5rem;
  }
`;
