import styled from "styled-components";
import { dataFetcherLocal } from "../../../services/dataFetcherLocal";
import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { BigNumber } from "../../CentreFormation/Dashboard/components/BigNumber";
import { Cell, Legend, Pie, PieChart, ResponsiveContainer } from "recharts";
import { NBFormeDate } from "./composant/NBFormeDate";

interface DashAppreantCtrlProps {
  className?: string;
}

interface MetrixDash {
  nbBySession: { libelleFormation: string; nbParticipants: number }[];
  nbFormations: number;
  nbMulti: number;
  metrix: { annee: string; moisStr: string; moisNB: number; trim: number; nbStagiaire: number }[];
}

function getMetrix(): Promise<MetrixDash> {
  return dataFetcherLocal(`api/getStatistiqueOF`);
}

const useGetAllMetrix = (): UseQueryResult<MetrixDash, Error> => {
  return useQuery(["metrix_dash"], getMetrix);
};
const data01 = [
  { name: "Group A", value: 400 },
  { name: "Group B", value: 300 },
  { name: "Group C", value: 300 },
  { name: "Group D", value: 200 },
];
const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];
const DashAppreantCtrl = ({ className }: DashAppreantCtrlProps) => {
  const MetrixQuery = useGetAllMetrix();
  return (
    <div className={`dash_apprenant ${className}`}>
      <h1>Tableau de bord Apprenants</h1>
      <div className="container_grid">
        <div className="block_infos is_chart">
          <div className="head">Répartition par formation</div>
          <div className="body">
            {MetrixQuery.data && (
              <ResponsiveContainer width="90%" height="90%">
                <PieChart width={500} height={500}>
                  <Pie
                    data={MetrixQuery.data.nbBySession.map((item) => {
                      return { name: item.libelleFormation, value: item.nbParticipants };
                    })}
                    dataKey="value"
                    cx="50%"
                    cy="50%"
                    outerRadius={100}
                    fill="#8884d8"
                    label
                  >
                    {MetrixQuery.data.nbBySession.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                  </Pie>

                  <Legend />
                </PieChart>
              </ResponsiveContainer>
            )}
          </div>
        </div>
        <div className="block_infos" style={{ gridRow: "1/3", gridColumn: "3/5" }}>
          <div className="head">Nombre d’apprenants total dans les formations planifiées</div>
          <div className="body">
            <BigNumber nb={MetrixQuery.data?.nbFormations} isPending={MetrixQuery.isLoading} />
          </div>
        </div>
        <div className="block_infos" style={{ gridRow: "3/5", gridColumn: "3/5" }}>
          <div className="head">Nombre d’apprenants ayant suivi plusieurs sessions</div>
          <div className="body">
            <BigNumber nb={MetrixQuery.data?.nbMulti} isPending={MetrixQuery.isLoading} />
          </div>
        </div>
        <div className="block_infos" style={{ gridRow: "5/-1", gridColumn: "3/5" }}>
          <div className="head">Nombre de cursus décrits</div>
          <div className="body">
            <BigNumber nb={MetrixQuery.data?.nbFormations} isPending={MetrixQuery.isLoading} />
          </div>
        </div>

        <div className="block_infos" style={{ gridRow: "1/-1", gridColumn: "5/7" }}>
          <div className="head">Nombre d’apprenants formés</div>
          <div className="body">{MetrixQuery.data && <NBFormeDate metrix={MetrixQuery.data.metrix} />}</div>
        </div>
      </div>
    </div>
  );
};

export const DashAppreant = styled(DashAppreantCtrl)`
  padding: 1% 2%;
  height: 80%;

  .is_chart {
    grid-column: 1/3;
    grid-row: 1/-1;
  }

  .container_grid {
    display: grid;
    margin-top: 20px;
    height: 90%;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(6, 1fr);
    gap: 12px;
  }

  .block_infos {
    background: white;
    border-radius: 4px;
    display: flex;
    flex-direction: column;

    .head {
      background: ${(props) => props.theme.primary};
      padding: 0.25rem;
      color: white;
      border-radius: 4px 4px 0 0;
    }

    .body {
      flex-grow: 1;
      overflow-y: auto;
    }
  }
`;
