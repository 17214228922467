import styled from "styled-components";
import {GetElementType} from "@mbj-front-monorepo/utils";
import {Operation, useGetOFMBJMe} from "@mbj-front-monorepo/data-access";
import {AvatarFact} from "@mbj-front-monorepo/ui";
import {FaLocationDot} from "react-icons/fa6";
import {MdOutlineEmail} from "react-icons/md";
import {SendMessageRecruteur} from "./SendMessageRecruteur";

interface MemberDetailsRecrutementCtrlProps {
  className?:string;
  Member:GetElementType<Operation<"getMemberCollection">>
}

const MemberDetailsRecrutementCtrl = ({className, Member}: MemberDetailsRecrutementCtrlProps) => {
  const OFQuery = useGetOFMBJMe();
  const handleError = (e:any)=>{
    e.target.src = AvatarFact;
  }
  return (
    <div className={`details_member ${className}`}>
      <div className="infos_general">
        <div className="infos_member_name">
          <div className={"Avatar_place"}>
            <img
              src={`${process.env["REACT_APP_API_URL_ROOT"]}/logos/members/${Member.logo}`}
              alt={"avatar_logo"}
              onError={handleError}
            />
          </div>
          <div className="name_place">{Member.name}</div>
        </div>
        <div className="infos_pratiques">
          <div className="one_infos">
            <div className="wrap_icon"><FaLocationDot /></div>
            <div className="info">{Member.adresse} {Member.codePostal} {Member.ville}</div>
          </div>
          {Member.email &&
            <div className="one_infos">
              <div className="wrap_icon"><MdOutlineEmail /></div>
              <div className="info">{Member.email} {Member.userId}</div>
            </div>
          }
        </div>
      </div>
      {(Member.userId || Member.email) &&
        <div className="Send_message">
          <div className="titre_snd">Envoyer un message au recruteur</div>
          <SendMessageRecruteur idUser={Member.userId} email={Member.email}/>
        </div>
      }
    </div>
  )
}

export const MemberDetailsRecrutement = styled(MemberDetailsRecrutementCtrl)`
  padding: 1rem;
  .one_infos{
    display: flex;
    justify-items: flex-start;
    align-items: center;
    gap: 6px;
  }
  .Send_message{
    width: 50%;
    margin-top: 15px;
  }
  .infos_member_name{
    margin-bottom: 12px;
    display: flex;
    justify-items: flex-start;
    gap: 12px;
    align-items: center;
    .name_place{
      font-size: 17px;
      font-weight: bold;
    }
    .Avatar_place{
      width: 80px;
      img{
        width: 100%;
      }
    }
  }
`
