import styled from "styled-components";
import {useParams} from "react-router-dom";
import {useGetStagiaireRecrutement} from "@mbj-front-monorepo/data-access";
import {MbjLoading} from "@mbj-front-monorepo/ui";
import {OneSearchMCard} from "../02_SearchsM/OneSearchMCard";
import {OneSearchAllianceCard} from "./OneSearchAllianceCard";

interface SearchAllianceCtrlProps {
  className?:string;
}

const SearchAllianceCtrl = (props: SearchAllianceCtrlProps) => {
  const {id} = useParams()
  const RecrutementsQuery = useGetStagiaireRecrutement(parseInt(id as string))
  return (
    <div className={`search_alliances ${props.className}`}>
      {RecrutementsQuery.isLoading ? <MbjLoading/> : (!RecrutementsQuery.data || RecrutementsQuery.isError) ? 'Error...':
        RecrutementsQuery.data.recrutStagiaireAlliance?.length === 0 ?
          <div className={"no_result_recrut"}>Aucun résultat</div>:
          <div className="in_um">
            {RecrutementsQuery.data.recrutStagiaireAlliance?.map((item)=>(
              <OneSearchAllianceCard Recrut={item} key={`SAll${item.id}`}/>
            ))}
          </div>
      }
    </div>
  )
}

export const SearchAlliance = styled(SearchAllianceCtrl)`
  padding: 1.5rem 0;
  height: 100%;
  .in_um{
    padding: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    justify-items: flex-start;
    flex-wrap: wrap;
    overflow-y: auto;
  }
`
