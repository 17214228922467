import { GetListAuthOnglet, logout, useGetOFMBJMe } from "@mbj-front-monorepo/data-access";
import { DesktopLayout, MbjBlockMenu } from "@mbj-front-monorepo/ui";
import { BsBank2, BsChatSquareText } from "react-icons/bs";
import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { FaLink, FaUserCircle } from "react-icons/fa";
import { app_version, queryClient } from "./App";
import { CentreFormationRooter } from "../pages/CentreFormation/CentreFormationRooter";
import { LegalMentions } from "../pages/LegalMentions/LegalMentions";
import { NotFound } from "../components/NotFound/NotFound";
import { PiStudent } from "react-icons/pi";
import { ApprenantsRooter } from "../pages/Apprenants/ApprenantsRooter";
import { Messagerie } from "../pages/Messagerie/Messagerie";
import { AllianceRooter } from "../pages/Alliances/AllianceRooter";

export const OF = () => {
  const OFMe = useGetOFMBJMe();
  const BlockMenus: MbjBlockMenu[] = [
    {
      libelle: "Centre de formation",
      nivo: [-2],
      subMenus: [
        {
          libelle: "Tableau de bord",
          to: "centre_formation/dashboard",
          path: "centre_formation_dashboard",
        },
        { libelle: "Offre de formation", to: "centre_formation/formations", path: "centreformation_formations" },
        { libelle: "Mes formateurs", to: "centre_formation/formateurs", path: "centreformation_formateurs" },
      ],
      icon: <BsBank2 title="OF icône" />,
    },
    {
      libelle: "Apprenants",
      nivo: [-2],
      subMenus: [
        { libelle: "Tableau de bord", to: "apprenants/dashboard", path: "apprenants_dashboard" },
        { libelle: "Mes Apprenants", to: "apprenants/liste", path: "apprenants_liste" },
      ],
      icon: <PiStudent title={"apprenants icône"} />,
    },
    {
      libelle: "Alliances",
      nivo: [-2],
      subMenus: [
        { libelle: "Tableau de bord", to: "alliances/dashboard", path: "alliances_dashboard" },
        { libelle: "Mes Alliances", to: "alliances/mes_alliances", path: "alliances_mes_alliances" },
      ],
      icon: <FaLink title="alliances icône" />,
    },
    {
      libelle: "Messagerie",
      nivo: GetListAuthOnglet("Messagerie"),
      to: "messagerie",
      icon: <BsChatSquareText title="messagerie icône" />,
    },
  ];
  return (
    <div className={`particulier`}>
      <Routes>
        <Route
          element={
            <DesktopLayout
              numVersion={app_version}
              Blocks={BlockMenus}
              type={"OF"}
              DisconnectCallBack={() => logout(queryClient)}
              userLogo={<FaUserCircle className={"icon-profile-menu"} />}
            />
          }
        >
          <Route path="" element={<Navigate to="centre_formation/dashboard" />} />
          <Route path={"centre_formation/*"} element={<CentreFormationRooter />} />
          <Route path={"alliances/*"} element={<AllianceRooter />} />
          <Route path={"apprenants/*"} element={<ApprenantsRooter />} />
          <Route path="/mentions" element={<LegalMentions />} />
          <Route path={"messagerie"} element={<Messagerie />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </div>
  );
};
