import { fetchApi, Operation } from "../../services/fetchApi";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

export const useGetFormateurActivite = (idFormateur: number | undefined) => {
  const promise = () => fetchApi("/api/formateur_activites", { method: "get", query: { Formateur: idFormateur + "" } });
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["formateurs_activites", { idFormateur }], promise, {
    enabled: !!idFormateur,
  });
};

export const useUpdateNiveauActiviteFormateur = (idFormateur?: number) => {
  const queryClient = useQueryClient();
  return useMutation(
    (data: { id: number; niveau: number }) => {
      return fetchApi("/api/formateur_activites/{id}", {
        method: "put",
        params: { id: data.id + "" },
        json: { niveau: data.niveau },
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["formateurs_activites", { idFormateur }]);
        queryClient.invalidateQueries(["competences_formateur"]);
        toast.success("Activité modifiée !", {});
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
};

export const useAddActiviteFormateur = (idFormateur?: number) => {
  const queryClient = useQueryClient();
  return useMutation(
    (data: Operation<"postFormateurActiviteCollection", "requestBody">) => {
      return fetchApi("/api/formateur_activites", { method: "post", json: data });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["formateurs_activites", { idFormateur }]);
        queryClient.invalidateQueries(["competences_formateur", { idFormateur }]);
        toast.success("Activité ajoutée !", {});
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
};

export const useDeleteActiviteFormateur = (idFormateur?: number) => {
  const queryClient = useQueryClient();
  return useMutation(
    (idActivite: number) => {
      return fetchApi("/api/formateur_activites/{id}", { method: "delete", params: { id: idActivite + "" } });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["formations_of_mbj_activites", { idFormateur }]);
        queryClient.invalidateQueries(["competences_formation_of_mbj", { idFormateur }]);
        toast.success("Activité supprimée !", {});
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
};
