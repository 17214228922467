import { Navigate, Route, Routes } from "react-router-dom";
import {NotFound} from "../../components/NotFound/NotFound";
import {DashAppreant} from "./01_Dashboard/DashAppreant";
import {OneApprenantsRooter} from "./03_OneApprenant/OneApprenantRooter";
import {ListingApprenantRooter} from "./02_Listing/ListingApprenantRooter";


export const ApprenantsRooter = ()=>{
  return (
    <Routes>
      <Route path={""} element={<Navigate to={"dashboard"}/>}/>
      <Route path={"dashboard"} element={<DashAppreant/>}/>
      <Route path={"liste/*"} element={<ListingApprenantRooter/>}/>
      <Route path={":id/*"} element={<OneApprenantsRooter/>}/>
      <Route path={"*"} element={<NotFound/>}/>
    </Routes>
  )
}
