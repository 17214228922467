import styled, {keyframes} from "styled-components";
import {NavLink} from "react-router-dom";

interface LinkCompRCtrlProps{
    className?:string;
    libelle:string;
    niveau:number;
    to:string;
    isN?:boolean;
}

const LinkCompRCtrl = (props:LinkCompRCtrlProps)=>{

    return (
        <NavLink to={props.to} className={`one_block_comp ${props.className}`}>
            <div className={`content_link`}>
                <div className={`libelle`}>
                    <span>{props.libelle}</span>

                </div>
                <div className={`wrapper_wrapper`}>
                    <div className={`wrapper_niveau`}>
                        <div className={`wrappy_niveau`} style={{width:props.isN ? props.niveau*100+"%" : (props.niveau/5*100)+"%"}}/>
                    </div>
                </div>
            </div>
        </NavLink>
    )
}

const Progress = () => keyframes`
    from {
        width: 0;
    }

`;

export const LinkCompR = styled(LinkCompRCtrl)`
  text-decoration: none;
  color:black;
  .content_link{
    border-top: solid ${props=>props.theme.primary} 1px;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    padding: 0.3rem 0;
  }
  .libelle{
    font-size: 14px;
    font-weight: bold;
    color:${props=>props.theme.dark};
    width: 40%;
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .wrapper_wrapper{
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .wrapper_niveau{
    width: 100%;
    height: 20px;
    border-radius: 4px;
    background: #ccc;
    position: relative;
    overflow: hidden;
  }
  .wrappy_niveau{
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    background: ${props=>props.theme.third};
    transition: width 0.3s;
    animation: ${Progress} 1.5s ease-out;
    border-radius: 4px;
  }
`
