import styled from "styled-components";
import {useGetFormationsOFMBJ, useGetOFMBJMe} from "@mbj-front-monorepo/data-access";
import {useEffect, useState} from "react";
import {BigNumber} from "./BigNumber";

interface NombreFormationCtrlProps {
  className?:string;
}

const NombreFormationCtrl = (props: NombreFormationCtrlProps) => {
  const OFMEQuery = useGetOFMBJMe();
  const [idOF, setidOF] = useState<number>(0)
  const FormationsQuery = useGetFormationsOFMBJ(idOF)
  useEffect(() => {
    if(OFMEQuery.data) setidOF(OFMEQuery.data.id)
  }, [OFMEQuery.data]);
  return (
    <div className={`nb_formation ${props.className}`}>
      <BigNumber nb={FormationsQuery.data?.length} isPending={FormationsQuery.isLoading}/>
    </div>
  )
}

export const NombreFormation = styled(NombreFormationCtrl)`
  width: 100%;
  height: 100%;
`
