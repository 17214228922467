import styled from "styled-components";
import {InformationCentreCard} from "./components/InformationCentreCard";
import {NombreFormation} from "./components/NombreFormation";
import {NombreApprenants} from "./components/NombreApprenants";
import {NombreRecrutements} from "./components/NombreRecrutements";


interface CentreFormationDashCtrlProps {
  className?:string;
}

const CentreFormationDashCtrl = ({className}: CentreFormationDashCtrlProps) => {
  return (
    <div className={`dash_of ${className}`}>
      <h1>Bienvenue sur l'application à destination des organismes de formations</h1>
      <div className="container_grid">
        <div className="block_infos is-block-centre">
          <div className="head">Votre Centre</div>
          <div className="body">
            <InformationCentreCard/>
          </div>
        </div>
        <div className="block_infos is-block-nbFormation">
          <div className="head">Nombre de formations</div>
          <div className="body">
            <NombreFormation/>
          </div>
        </div>
        <div className="block_infos is-block-nbApprenant">
          <div className="head">Nombre d'Apprenants</div>
          <div className="body">
            <NombreApprenants/>
          </div>
        </div>
        <div className="block_infos is-block-nbFormation">
          <div className="head">Nombre de recrutement possible</div>
          <div className="body">
            <NombreRecrutements/>
          </div>
        </div>
      </div>
    </div>
  )
}

export const CentreFormationDash = styled(CentreFormationDashCtrl)`
  padding: 2%;
  height: 100%;
  .container_grid {
    display: grid;
    margin-top: 20px;
    height: 90%;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(4, 1fr);
    gap: 12px;
  }
  .is-block-centre{
    grid-column: 1/2;
    grid-row: 1/-1;
  }
  .block_infos{
    background: white;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    .head{
      background: ${props => props.theme.primary};
      padding: 0.25rem;
      color: white;
      border-radius: 4px 4px 0 0;
    }
    .body{
      flex-grow: 1;
      overflow-y: auto;
    }
  }
`
