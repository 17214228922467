import styled from "styled-components";
import {useGetOFMBJMe} from "@mbj-front-monorepo/data-access";
import {MbjAvatar, MbjFlex, MbjLoading, useModal} from "@mbj-front-monorepo/ui";

import * as React from "react";
import {useEffect, useState} from "react";
import {SwitchInfo} from "./SwitchInfo";

interface InformationCentreCardCtrlProps {
  className?:string;
}

const InformationCentreCardCtrl = ({className}: InformationCentreCardCtrlProps) => {
  const OFQuery = useGetOFMBJMe();
  const [opened, setOpened] = useState('')
  const {isShowing, toggle} = useModal();
  return (
    <div className={`info_centre_card ${className}`}>
      {OFQuery.isLoading ? <MbjLoading/> : !OFQuery.data ? 'error bd':
        <div className="content_infos">
          <div className={"wrap_avatar"} style={{marginTop: "10px", textAlign: "center"}} onClick={toggle}>
            <MbjAvatar
              className={"profilImage"}
              src={`${process.env.REACT_APP_API_URL_ROOT}/logos/members/${OFQuery.data.logo}`}
              objectFit={"contain"}
              bg={"#ffffff"}
              size={"xl"}
              withShadow
              name={OFQuery.data?.name||""}
            />
          </div>
          <MbjFlex direction={"column"} gap={"12px"}>
            <SwitchInfo type={"area"} opened={opened} setOpened={setOpened} info={OFQuery.data.name || ""}
                        id={"nom"} label={"Nom"} idOF={OFQuery.data.id}/>

            <SwitchInfo type={"text"} opened={opened} setOpened={setOpened} info={OFQuery.data.siret || ""}
                        id={"siret"}
                        label={"Siret"} idOF={OFQuery.data.id}/>
            <SwitchInfo type={"text"} opened={opened} setOpened={setOpened} info={OFQuery.data.telephone || ""}
                        id={"telephone"}
                        label={"Téléphone"} idOF={OFQuery.data.id}/>
            <SwitchInfo type={"text"} opened={opened} setOpened={setOpened} info={OFQuery.data.email || ""}
                        id={"email"}
                        label={"E-mail"} idOF={OFQuery.data.id}/>
            <SwitchInfo type={"text"} opened={opened} setOpened={setOpened} info={OFQuery.data.siteWeb || ""}
                        id={"siteWeb"}
                        label={"site Web"} idOF={OFQuery.data.id}/>
            <SwitchInfo type={"text"} opened={opened} setOpened={setOpened} info={OFQuery.data.contact || ""}
                        id={"contact"}
                        label={"Contact"} idOF={OFQuery.data.id}/>
            <SwitchInfo type={"text"} opened={opened} setOpened={setOpened} info={OFQuery.data.adresse || ""}
                        id={"adresse"}
                        label={"Adresse"} idOF={OFQuery.data.id}/>

            <div style={{width: "100%", display: "flex", gap: "6px"}}>
              <div style={{width: "50%"}}>
                <SwitchInfo type={"text"} opened={opened} setOpened={setOpened}
                            info={OFQuery.data.codePostal || ""} id={"code_postal"}
                            label={"Code Postal"} idOF={OFQuery.data.id}/>
              </div>
              <div style={{width: "50%"}}>
                <SwitchInfo type={"text"} opened={opened} setOpened={setOpened}
                            info={OFQuery.data.ville || ""} id={"ville"}
                            label={"Ville"} idOF={OFQuery.data.id}/>
              </div>
            </div>
          </MbjFlex>
        </div>
      }
    </div>
  )
}

export const InformationCentreCard = styled(InformationCentreCardCtrl)`
  padding: 0.5rem;
  .wrap_avatar{
    margin-bottom: 20px;
  }
`
