import styled from "styled-components";
import {MbjLoading} from "@mbj-front-monorepo/ui";

interface BigNumberCtrlProps {
  className?:string;
  nb?:number;
  isPending:boolean
}

const BigNumberCtrl = ({className, nb, isPending}: BigNumberCtrlProps) => {
  return (
    <div className={`nb_big ${className}`}>
      {isPending ?
        <MbjLoading/> :
        <span>{nb}</span>
      }
    </div>
  )
}

export const BigNumber = styled(BigNumberCtrl)`
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  span{
    font-size: 60px;
  }
`
