import styled from "styled-components";
import {GetElementType} from "@mbj-front-monorepo/utils";
import {Operation} from "@mbj-front-monorepo/data-access";
import {useMemo, useState} from "react";
import {MbjCardBack, MbjListItem, MbjLoading, MbjScrollArea, MbjSearchBar} from "@mbj-front-monorepo/ui";
import {MbjMobiliteListItem} from "@mbj-front-monorepo/data-ui";
import * as React from "react";

interface RecommandationsDeboucheCtrlProps {
  className?:string;
  Proxis:GetElementType<Operation<"getStagiaireProxiMetierCollection">>[];
  setIdMetier:(id:number, idProxi:number)=>void;
}

const RecommandationsDeboucheCtrl = (props: RecommandationsDeboucheCtrlProps) => {
  const ListMobiliteFiltered = useMemo(()=>{
    return props.Proxis
      ?.filter((mob) => {
        return mob.score && mob.score > 0.8
      })
      .sort((a, b) => {
        return (a.score as number) > (b.score as number) ? -1 : 1;
      });
  }, [props.Proxis])
  return (
    <MbjCardBack title={"Recommandés par MBJ"}>
      <div className={`all_mob ${props.className}`}>
        <MbjScrollArea grow={1}>
          {
            ListMobiliteFiltered
              .map((mobilite, idx: number) => (
                <MbjListItem key={mobilite.id} onClick={()=>props.setIdMetier(mobilite.Metier?.id||0, mobilite.id||0)}>
                  <MbjMobiliteListItem mobilite={mobilite} className="in-line" />
                </MbjListItem>
              ))
          }
        </MbjScrollArea>
      </div>
    </MbjCardBack>
  )
}

export const RecommandationsDebouche = styled(RecommandationsDeboucheCtrl)`
  height: 500px;
  display: flex;
  flex-direction: column;
`
