import styled from "styled-components";
import {useGetOFMBJMe, useGetStagiaireByOF, useGetStagiaireRecrutementAll} from "@mbj-front-monorepo/data-access";
import {useEffect, useMemo, useState} from "react";
import {BigNumber} from "./BigNumber";

interface NombreRecrutementsCtrlProps {
  className?:string;
}

const NombreRecrutementsCtrl = (props: NombreRecrutementsCtrlProps) => {
  const OFMEQuery = useGetOFMBJMe();
  const [idOF, setidOF] = useState<number>(0)
  const RecrutementsQuery = useGetStagiaireRecrutementAll(idOF)
  useEffect(() => {
    if(OFMEQuery.data) setidOF(OFMEQuery.data.id)
  }, [OFMEQuery.data]);
  const total = useMemo(()=>{
    if(!RecrutementsQuery.data){
      return 0
    } else {
      let nbTotal = 0;
      RecrutementsQuery.data.forEach(r=>{
        nbTotal += r.recrutStagiaireAlliance?.length||0
        nbTotal += r.recrutStagiaireSearchMetier?.length||0
        nbTotal += r.recrutStagiaireSearch?.length||0
      })
      return nbTotal
    }
  }, [RecrutementsQuery.data])
  return (
    <div className={`nb_formation ${props.className}`}>
      <BigNumber nb={total} isPending={RecrutementsQuery.isLoading}/>
    </div>
  )
}

export const NombreRecrutements = styled(NombreRecrutementsCtrl)`
  width: 100%;
  height: 100%;
`
