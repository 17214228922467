import styled from "styled-components";
import { Navigate, Route, Routes, To } from "react-router-dom";
import { InternSubDesktopLayout } from "@mbj-front-monorepo/ui";
import { NotFound } from "../../../../components/NotFound/NotFound";
import * as React from "react";
import { DashRecrutement } from "./00_DashRecrut/DashRecrutement";
import { SeachsUM } from "./01_SearchsUM/SeachsUM";
import { SearchAlliance } from "./03_Alliances/SearchAlliance";

interface ApprenantRecrutementRooterCtrlProps {}
const TabSubMenus: { libelle: string; to: To; onlyUM: boolean }[] = [
  { libelle: "Tableau de bord", to: "dash", onlyUM: false },
  { libelle: "Postes à pourvoir sur MBJ", to: "searchs_um", onlyUM: false },
  // {libelle: 'Recherche métier sur MBJ', to:"searchs_m", onlyUM:false},
  { libelle: "Recrutements possibles Alliance(s)", to: "alliance", onlyUM: false },
  // {libelle: 'Recruteurs potentiels', to:"recrut_pot", onlyUM:false},
];

const ApprenantRecrutementRooterCtrl = (props: ApprenantRecrutementRooterCtrlProps) => {
  return (
    <Routes>
      <Route element={<InternSubDesktopLayout Links={TabSubMenus} />}>
        <Route path={""} element={<Navigate to={"dash"} />} />
        <Route path={"dash"} element={<DashRecrutement />} />
        <Route path={"searchs_um"} element={<SeachsUM />} />
        {/*<Route path={"searchs_m"} element={<SearchsM/>}/>*/}
        <Route path={"alliance"} element={<SearchAlliance />} />
        <Route path={"*"} element={<NotFound />} />
      </Route>
    </Routes>
  );
};

export const ApprenantRecrutementRooter = styled(ApprenantRecrutementRooterCtrl)``;
