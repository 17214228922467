import styled from "styled-components";
import {
  MbjCard,
  MbjCardBody,
  MbjCardCtrlProps,
  MbjCardFooter,
  MbjCardHeader,
  MbjConfirmModal,
  MbjFlex,
  MbjHeading,
  MbjIconButton,
  MbjModal,
  MbjStaticInfo,
  useModal,
} from "@mbj-front-monorepo/ui";
import { getDateFR, GetElementType } from "@mbj-front-monorepo/utils";
import { Operation, useDeleteFormateurXp } from "@mbj-front-monorepo/data-access";
import { BiEdit } from "react-icons/bi";
import { BsTrash } from "react-icons/bs";
import React from "react";
import { MbjFormateurXpEditForm } from "./MbjFormateurXpEditForm";

interface MbjFormateurXpCardCtrlProps extends MbjCardCtrlProps {
  FormateurXp: GetElementType<Operation<"getFormateurXpCollection">>;
}

const MbjFormateurXpCardCtrl = (props: MbjFormateurXpCardCtrlProps) => {
  const { isShowing, toggle } = useModal();
  const { isShowing: ShowConfirm, toggle: toggleConfirm } = useModal();
  const mutateDelete = useDeleteFormateurXp();
  const HandleConfirmDelete = () => {
    mutateDelete.mutate(props.FormateurXp.id || -1, {
      onSettled: () => {
        toggleConfirm();
      },
    });
  };
  return (
    <>
      <MbjCard {...props}>
        <MbjCardHeader>
          <MbjHeading>
            Du {props.FormateurXp.startAt ? getDateFR(new Date(props.FormateurXp.startAt)) : "inconnu"} à{" "}
            {props.FormateurXp.endAt ? getDateFR(new Date(props.FormateurXp.endAt)) : "maintenant"} :{" "}
            {props.FormateurXp.Metier?.libelle}
          </MbjHeading>
        </MbjCardHeader>
        <MbjCardBody>
          <MbjFlex direction={"column"}>
            <MbjFlex gap={"15px"}>
              <MbjStaticInfo
                label="Type de contrat : "
                info={props.FormateurXp.TypeContrat ? props.FormateurXp.TypeContrat.libelle : "inconnu"}
                direction={"row"}
                gap={"5px"}
              />
              <MbjStaticInfo
                label="Salaire : "
                info={props.FormateurXp.salary ? props.FormateurXp.salary + "€" : "inconnu"}
                direction={"row"}
                gap={"5px"}
              />
            </MbjFlex>
            {props.FormateurXp.description && (
              <MbjStaticInfo label="Description : " info={props.FormateurXp.description} gap={"5px"} />
            )}
          </MbjFlex>
        </MbjCardBody>
        <MbjCardFooter justify={"flex-end"}>
          <MbjFlex direction={"row"} justify={"flex-end"} gap={"7px"}>
            <MbjIconButton
              onClick={toggle}
              size={"sm"}
              ariaLabel={"Editer l'expérience"}
              icon={<BiEdit />}
              themeColor={"secondary"}
              isRound={false}
            />
            <MbjIconButton
              onClick={toggleConfirm}
              size={"sm"}
              ariaLabel={"Supprimer l'expérience"}
              icon={<BsTrash />}
              themeColor={"warning"}
              isRound={false}
            />
          </MbjFlex>
        </MbjCardFooter>
        <MbjModal isShowing={isShowing} hide={toggle} title="Modification d'une expérience professionnelle">
          <MbjFormateurXpEditForm FormateurXp={props.FormateurXp} hideModal={toggle} />
        </MbjModal>
      </MbjCard>
      <MbjConfirmModal
        isShowing={ShowConfirm}
        hide={toggleConfirm}
        title="Confirmation suppression expérience"
        confirmText={"Voulez-vous vraiment supprimer cette expérience ?"}
        confirmFunction={HandleConfirmDelete}
        isLoading={mutateDelete.isLoading}
      />
    </>
  );
};

export const MbjFormateurXpCard = styled(MbjFormateurXpCardCtrl)((props) => ({}));
