import { Navigate, Route, Routes } from "react-router-dom";
import { CentreFormationDash } from "./Dashboard/CentreFormationDash";
import { NotFound } from "../../components/NotFound/NotFound";
import { FormationsRooter } from "./Formations/FormationsRooter";
import { FormateursRooter } from "./Formateurs/FormateursRooter";

export const CentreFormationRooter = () => {
  return (
    <Routes>
      <Route path={""} element={<Navigate to={"dashboard"} />} />
      <Route path={"dashboard"} element={<CentreFormationDash />} />
      <Route path={"formations/*"} element={<FormationsRooter />} />
      <Route path={"formateurs/*"} element={<FormateursRooter />} />
      <Route path={"*"} element={<NotFound />} />
    </Routes>
  );
};
