import { Navigate, Route, Routes } from "react-router-dom";
import { CentreFormationFormations } from "./CentreFormationFormations";
import { NotFound } from "../../../components/NotFound/NotFound";
import React from "react";
import { OneFormationRooter } from "./OneFormation/OneFormationRooter";

export const FormationsRooter = () => {
  return (
    <Routes>
      <Route path={""} element={<Navigate to={"listing"} />} />
      <Route path={"listing"} element={<CentreFormationFormations />} />
      <Route path={":id/*"} element={<OneFormationRooter />} />
      <Route path={"*"} element={<NotFound />} />
    </Routes>
  );
};
