import styled from "styled-components";
import {
  Operation,
  useAddStagiaireSession,
  useGetOneFormationOFMBJ, useGetOneStagiaire,
  useGetSessionFormationsOFMBJ,
  useGetStagiaireSessionByStagiaire
} from "@mbj-front-monorepo/data-access";
import {MbjLoading, MbjModal2, useModal} from "@mbj-front-monorepo/ui";
import {useParams} from "react-router-dom";
import {useMemo, useState} from "react";
import {ModalConfirmInscription} from "./ModalConfirmInscription";

interface InscriptionOneFormationCtrlProps {
  className?:string;
  idFormation:number;
  InvalideFormation:()=>void;
}
interface OneChoiceSession{
  id:number;
  startAt:string;
  endAt:string;
  isFree:boolean;
}

const InscriptionOneFormationCtrl = ({className, idFormation, InvalideFormation}: InscriptionOneFormationCtrlProps) => {
  const FormationQuery = useGetOneFormationOFMBJ(idFormation)
  const SessionsQuery = useGetSessionFormationsOFMBJ(idFormation);
  const mutation = useAddStagiaireSession()
  const {id} = useParams();
  const StagiaireQuery = useGetOneStagiaire(parseInt(id as string))
  const SessionsStagiaire = useGetStagiaireSessionByStagiaire(parseInt(id as string));
  const [SessionCurrent, setSessionCurrent] = useState<OneChoiceSession|null>(null)
  const {isShowing, toggle} = useModal()
  const {isShowing:showMessage, toggle:toggleMessage} = useModal()
  const handleClickSession = (item:OneChoiceSession)=>{
    if(item.isFree) {
      setSessionCurrent(item);
      toggle();
    } else {
      toggleMessage();
    }
  }
  const Cancel = ()=>{
    setSessionCurrent(null);
    toggle();
  }
  const ConfirmInscription = ()=>{
    if(SessionCurrent) {
      const datas: Operation<"postStagiaireSessionCollection", "requestBody"> = {
        Stagiaire: `/api/stagiaires/${parseInt(id as string)}`,
        Session: `/api/session_formation_o_f_m_b_js/${SessionCurrent.id}`
      }
      mutation.mutate(datas, {
        onSuccess:()=>{
          setSessionCurrent(null)
          InvalideFormation();
        }
      })
    }
  }
  const listSession = useMemo(()=>{
    const list:OneChoiceSession[] = []
    if(SessionsStagiaire.data && SessionsQuery.data){
      const idsAlReady = SessionsStagiaire.data.map(s=>s.id);
      const today = new Date();
      SessionsQuery.data.forEach(s=>{
        if(s.startAt && new Date(s.startAt) > today) {
          list.push({
            id: s.id || 0,
            startAt: s.startAt ? new Date(s.startAt).toLocaleDateString() : '--',
            endAt: s.endAt ? new Date(s.endAt).toLocaleDateString() : '--',
            isFree: idsAlReady.indexOf(s.id || 0) === -1
          })
        }
      })
    }
    return list;
  }, [SessionsStagiaire.data, SessionsQuery.data])
  return (
    <div className={`inscription_one_formation ${className}`}>
      {FormationQuery.isLoading || SessionsQuery.isLoading ? <MbjLoading/> : (!FormationQuery.data || !SessionsQuery.data || FormationQuery.isError || SessionsQuery.isError) ? 'ErrorBD' :
        <div className="in_inscription">
          <div className="infos_formations">
            <div className="one_info_formations">
              <span className={`lib_infos`}>Formation Choisie</span>
              <span className={`infos`}>{FormationQuery.data.libelle}</span>
            </div>
            <div className="one_info_formations">
              <span className={`lib_infos`}>Description</span>
              <span className={`infos`}>{FormationQuery.data.description}</span>
            </div>
          </div>
          <div className="list_session">
            {listSession.map(item=>(
              <div className={`one_choice_session ${item.isFree ? 'free' : 'busy'}`} key={`onCS${item.id}`} onClick={()=>handleClickSession(item)}>
                <div className="dates">
                  du {item.startAt} au {item.endAt}
                </div>
              </div>
              ))}
          </div>
        </div>
      }
      <MbjModal2 open={isShowing} closeMe={toggle} titre={"Confirmation inscription"} Wd={"30%"}>
        {SessionCurrent &&
          <ModalConfirmInscription
            CallBackYes={ConfirmInscription}
            libelleFormation={FormationQuery.data?.libelle||''}
            endAt={SessionCurrent.endAt}
            libelleStagiaire={StagiaireQuery.data?.prenom||''}
            CallBackNo={Cancel}
            startAt={SessionCurrent.startAt}
          />
        }
      </MbjModal2>
    </div>
  )
}

export const InscriptionOneFormation = styled(InscriptionOneFormationCtrl)`
  .infos_formations{
    margin: 15px 0;
    .one_info_formations{
      display: flex;
      gap: 8px;
      span{
        &:not(:first-child){
          font-weight: bold;
        }
      }
    }
  }
  .list_session{
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  .one_choice_session{
    padding: 0.5rem;
    background: white;
    border-radius: 4px;
    border-width: 0 0 0 6px;
    border-style: solid;
    transition: margin-left 0.3s;
    &:hover{
      margin-left: 15px;
      transition: margin-left 0.3s;
      cursor: pointer;
    }
    &.free{
      border-color: ${props => props.theme.third};
    }
    &.busy{
      border-color: red;
    }
  }
`
