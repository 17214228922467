import styled from "styled-components";
import { MbjCardClassic, MbjErrorData, MbjLoading, MbjWidgetClassic } from "@mbj-front-monorepo/ui";
import { FormateurUpdateForm } from "./FormateurUpdateForm";
import { useGetOneFormateur } from "@mbj-front-monorepo/data-access";
import { useParams } from "react-router-dom";

interface InformationsCtrlProps {
  className?: string;
}

const InformationsCtrl = (props: InformationsCtrlProps) => {
  const { id } = useParams();
  const formateurQuery = useGetOneFormateur(id ? parseInt(id) : 0);

  if (formateurQuery.isLoading) {
    return <MbjLoading />;
  }

  if (formateurQuery.isError) {
    return <MbjErrorData />;
  }

  return (
    <div className={props.className}>
      <MbjWidgetClassic>
        <MbjCardClassic title={"Informations"} themeColor={"dark"}>
          <FormateurUpdateForm formateur={formateurQuery.data} />
        </MbjCardClassic>
      </MbjWidgetClassic>
    </div>
  );
};

export const Informations = styled(InformationsCtrl)((props) => ({
  padding: "1% 2%",
}));
