import styled from "styled-components";
import {useGetAllianceMe, useGetOFMBJMe} from "@mbj-front-monorepo/data-access";
import {useEffect, useState} from "react";
import {MbjLoading} from "@mbj-front-monorepo/ui";
import {OneAllianceCard} from "./components/OneAllianceCard";

interface MesAlliancesCtrlProps {
  className?:string;
}

const MesAlliancesCtrl = ({className}: MesAlliancesCtrlProps) => {
  const OFMEQuery = useGetOFMBJMe();
  const [idOF, setidOF] = useState<number>(0)
  const AlliancesQuery = useGetAllianceMe(idOF)
  useEffect(() => {
    if(OFMEQuery.data) setidOF(OFMEQuery.data.id)
  }, [OFMEQuery.data]);
  return (
    <div className={`mes_alliances ${className}`}>
      <h3>Vos Alliances</h3>
      {AlliancesQuery.isLoading ? <MbjLoading/> : (!AlliancesQuery.data || AlliancesQuery.isError) ? 'Error BD':
        AlliancesQuery.data.map(item=>(
          <OneAllianceCard ItemAlliance={item} key={`OneA${item.id}`}/>
        ))
      }
    </div>
  )
}

export const MesAlliances = styled(MesAlliancesCtrl)`
  padding: 2%;
  h3{
    margin-bottom: 15px;
  }
`
