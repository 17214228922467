import styled from "styled-components";
import { MbjScrollArea } from "@mbj-front-monorepo/ui";
import { useGetSessionFormationsOFMBJ } from "@mbj-front-monorepo/data-access";
import { getDateFR } from "@mbj-front-monorepo/utils";
import { useMemo } from "react";
import { SessionListItem } from "./SessionListItem";

interface FormationSelectedCtrlProps {
  className?: string;
  idFormation: number;
  idFormateur: number;
  formateursSessionsRegisterdIds: number[];
}

const FormationSelectedCtrl = (props: FormationSelectedCtrlProps) => {
  const SessionsQuery = useGetSessionFormationsOFMBJ(props.idFormation);

  const filteredSessions = useMemo(() => {
    if (SessionsQuery.data) {
      const withoutRegisterd = SessionsQuery.data.filter(
        (session) => !props.formateursSessionsRegisterdIds.includes(session.id || -1)
      );

      return withoutRegisterd.filter((session) => session.startAt && new Date(session.startAt) > new Date());
    }
  }, [SessionsQuery.data, props.formateursSessionsRegisterdIds]);

  return (
    <div className={props.className}>
      {SessionsQuery.isLoading ? (
        <span>Chargement</span>
      ) : SessionsQuery.isError ? (
        <span>Erreur</span>
      ) : filteredSessions?.length === 0 ? (
        <span>Aucune session pour cette formation</span>
      ) : (
        <>
          <span>Les sessions correspondantes :</span>
          <MbjScrollArea maxHeight={"400px"}>
            {filteredSessions?.map((session) => (
              <SessionListItem
                idFormateur={props.idFormateur}
                idSession={session.id || -1}
                startAt={getDateFR(new Date(session.startAt || ""))}
                endAt={getDateFR(new Date(session.endAt || ""))}
                key={session.id}
              />
            ))}
          </MbjScrollArea>
        </>
      )}
    </div>
  );
};

export const FormationSelected = styled(FormationSelectedCtrl)((props) => ({
  padding: "2% 2% 2% 1%",
  ".session-item": {
    borderBottom: "1px solid #e0e0e0",
  },
}));
