import styled from "styled-components";
import {useGetFormationsByStagaire, useGetStagiaireSessionByStagiaire} from "@mbj-front-monorepo/data-access";
import {MbjLoading} from "@mbj-front-monorepo/ui";
import {useMemo} from "react";
import {OneFormationLine} from "./OneFormationLine";
import {useParams} from "react-router-dom";

interface FormationsCardCtrlProps {
  className?:string;

}
interface OneFormationThere{
  id?:number;
  libelle?:string;
  startAt?:string;
  endAt?:string;
}

const FormationsCardCtrl = ({className}: FormationsCardCtrlProps) => {
  const {id} = useParams();
  const FormationsQuery = useGetFormationsByStagaire(id ? parseInt(id) : 0)
  const FormationPast = useMemo(()=>{
    const list:OneFormationThere[] = [];
    if(FormationsQuery.data){
        FormationsQuery.data.forEach(f=>{
          if(f.endAt && new Date(f.endAt) < new Date()){
            list.push(f)
          }
        })
    }
    return list;
  }, [FormationsQuery.data])
  const FormationCurrent = useMemo(()=>{
    const list:OneFormationThere[] = [];
    if(FormationsQuery.data){
      FormationsQuery.data.forEach(f=>{
        if(f.endAt && f.startAt && new Date(f.startAt) <= new Date() && new Date(f.endAt) >= new Date()){
          list.push(f)
        }
      })
    }
    return list;
  }, [FormationsQuery.data])
  const FormationFuture = useMemo(()=>{
    const list:OneFormationThere[] = [];
    if(FormationsQuery.data){
      FormationsQuery.data.forEach(f=>{
        if(f.startAt && new Date(f.startAt) > new Date()){
          list.push(f)
        }
      })
    }
    return list;
  }, [FormationsQuery.data])
  return (
    <div className={`formations_card ${className}`}>
      {FormationsQuery.isLoading ? <MbjLoading/> : !FormationsQuery.data ? 'Error BD':
        <div className={"content_formation"}>
          <div className="one_block_form">
            <div className="head_block_form">Formations déjà suivies</div>
            <div className="body_block_form">
              {FormationPast.length === 0 &&
                <p className={`no_form`}>Aucune formation passée</p>
              }
              {FormationPast.map(item=>(
                <OneFormationLine key={`FP${item.id}`} Formation={item}/>
                ))}
            </div>
          </div>
          <div className="one_block_form">
            <div className="head_block_form">Formations en cours ou prévues</div>
            <div className="body_block_form">
              {(FormationCurrent.length + FormationFuture.length) === 0 &&
                <p className={`no_form`}>Aucune formation</p>
              }
              {FormationCurrent.map(item=>(
                <OneFormationLine key={`FC${item.id}`} Formation={item}/>
              ))}
              {FormationFuture.map(item=>(
                <OneFormationLine key={`FF${item.id}`} Formation={item}/>
              ))}
            </div>
          </div>
        </div>
      }
    </div>
  )
}

export const FormationsCard = styled(FormationsCardCtrl)`
  .one_block_form{
    margin-bottom: 8px;
    .head_block_form{
      padding: 0.25rem;
      font-weight: bold;
    }
    .body_block_form{
      padding: 0.25rem;
      .no_form{
        font-style: italic;
      }
    }
  }
`
