import { Navigate, Route, Routes } from "react-router-dom";
import React from "react";
import { MesFormateurs } from "./MesFormateurs";
import { NotFound } from "../../NotFound/NotFound";
import { OneFormateurRooter } from "./OneFormateur/OneFormateurRooter";

export const FormateursRooter = () => {
  return (
    <Routes>
      <Route path={""} element={<Navigate to={"listing"} />} />
      <Route path={"listing"} element={<MesFormateurs />} />
      <Route path={":id/*"} element={<OneFormateurRooter />} />
      <Route path={"*"} element={<NotFound />} />
    </Routes>
  );
};
