import styled from "styled-components";
import {useParams} from "react-router-dom";
import {useGetStagiaireRecrutement} from "@mbj-front-monorepo/data-access";
import {MbjLoading, MbjModal2, useModal} from "@mbj-front-monorepo/ui";
import {OneSearchMCard} from "./OneSearchMCard";
import {useState} from "react";
import {ModalDetailsSearchM} from "./ModalDetailsSearchM";

interface SearchsMCtrlProps {
  className?:string;
}

export interface OneItemSearchM{
  idMember:number;
  idMetier:number;
}

const SearchsMCtrl = (props: SearchsMCtrlProps) => {
  const {id} = useParams()
  const RecrutementsQuery = useGetStagiaireRecrutement(parseInt(id as string))
  const {isShowing, toggle} = useModal();
  const [mySeachM, setMySearchM] = useState<OneItemSearchM|null>(null)
  const ClickOne = (idMember:number, idMetier:number)=>{
    setMySearchM({idMember, idMetier});
    toggle();
  }
  return (
    <div className={`searchs_stagiaire_m ${props.className}`}>
      {RecrutementsQuery.isLoading ? <MbjLoading/> : (!RecrutementsQuery.data || RecrutementsQuery.isError) ? 'Error...':
        RecrutementsQuery.data.recrutStagiaireSearchMetier?.length === 0 ?
          <div className={"no_result_recrut"}>Aucun résultat</div>:
        <div className="in_um">
          {RecrutementsQuery.data.recrutStagiaireSearchMetier?.map((item)=>(
            <OneSearchMCard Recrut={item} key={`SM${item.id}`} ClickOnIt={()=>ClickOne(item.searchs?.Member?.id||0, item.searchs?.Metier?.id||0)}/>
          ))}
        </div>
      }
      <MbjModal2 open={isShowing} closeMe={toggle} titre={"Détails recherche métier"} Wd={"80%"}>
        {mySeachM &&
          <ModalDetailsSearchM OneSearchM={mySeachM} idCompare={parseInt(id as string)}/>
        }
      </MbjModal2>
    </div>
  )
}

export const SearchsM = styled(SearchsMCtrl)`

  height: 100%;
  .in_um{
    padding: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    justify-items: flex-start;
    flex-wrap: wrap;
    overflow-y: auto;
  }
`
