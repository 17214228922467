import styled from "styled-components";
import {MbjButton} from "@mbj-front-monorepo/ui";

interface ModalConfirmInscriptionCtrlProps {
  className?:string;
  startAt:string;
  endAt:string;
  libelleStagiaire:string;
  libelleFormation:string;
  CallBackYes:()=>void;
  CallBackNo:()=>void;
}

const ModalConfirmInscriptionCtrl = ({className, CallBackNo, CallBackYes, libelleStagiaire, endAt, startAt, libelleFormation}: ModalConfirmInscriptionCtrlProps) => {
  return (
    <div className={`confirm_inscription ${className}`}>
      <div className="question">Vous allez inscrire <span className={`hight`}>{libelleStagiaire}</span> à la session de
        formation <span className={`hight`}>{libelleFormation}</span><br/>
        du <span className={`hight`}>{startAt}</span> au <span className={`hight`}>{endAt}</span> vous confirmez votre
        choix ?
      </div>
      <div className="wrap_actions">
        <MbjButton onClick={()=>CallBackNo()} themeColor={"warning"}>Non</MbjButton>
        <MbjButton onClick={()=>CallBackYes()} themeColor={"third"}>Oui</MbjButton>
      </div>
    </div>
  )
}

export const ModalConfirmInscription = styled(ModalConfirmInscriptionCtrl)`
  padding: 1rem;
  .question{
    font-size: 16px;
    text-align: center;
  }
  .wrap_actions{
    margin: 20px 0 8px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
`
