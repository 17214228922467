import styled from "styled-components";
import {
  createSelectColumn,
  MbjButton,
  MbjCardClassic,
  MbjConfirmModal,
  MbjErrorData,
  MbjFlex,
  MbjLoading,
  MbjNoResult,
  MbjTableV2,
  useModal,
} from "@mbj-front-monorepo/ui";
import * as React from "react";
import {
  createColumnHelper,
  getCoreRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { getDateFR, GetElementType } from "@mbj-front-monorepo/utils";
import {
  useDeleteFormateurSession,
  useGetFormateurSessions,
} from "@mbj-front-monorepo/data-access/src/features/formateurSession/formateurSession.hooks";

interface SessionsListeCtrlProps {
  className?: string;
  idFormateur: number;
}

type sessionState = "En cours" | "Terminée" | "À venir";

const getSessionState = (startAt: string, endAt: string): sessionState => {
  const start = new Date(startAt);
  const end = new Date(endAt);
  const now = new Date();

  if (start <= now && now <= end) return "En cours";
  if (now > end) return "Terminée";
  return "À venir";
};

const SessionsListeCtrl = (props: SessionsListeCtrlProps) => {
  const sessionsQuery = useGetFormateurSessions(props.idFormateur);
  const { isShowing: isShowingDelete, toggle: toggleDeleteSession } = useModal();
  const deleteSessionMutation = useDeleteFormateurSession();

  const columnHelper = createColumnHelper<GetElementType<typeof sessionsQuery.data>>();
  const columns = [
    createSelectColumn(columnHelper),
    columnHelper.accessor((row) => row.Session?.FormationOFMBJ?.libelle || "", {
      header: "Formation",
    }),
    columnHelper.accessor((row) => getDateFR(new Date(row.Session?.startAt || "")), {
      header: "Date de début",
    }),
    columnHelper.accessor((row) => getDateFR(new Date(row.Session?.endAt || "")), {
      header: "Date de fin",
    }),
    columnHelper.accessor((row) => row.Session?.StagiaireSessions?.length || 0, {
      header: "Nombre d'apprenants inscrits",
    }),
    columnHelper.accessor((row) => getSessionState(row.Session?.startAt || "", row.Session?.endAt || ""), {
      header: "Etat",
    }),
  ];

  const table = useReactTable({
    data: sessionsQuery?.data ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    debugTable: false,
    initialState: {
      sorting: [{ id: "Date de début", desc: false }],
    },
  });

  return (
    <MbjCardClassic className={props.className} title={"Récapitulatif des sessions"}>
      <MbjFlex direction={"column"} gap={"10px"}>
        <MbjFlex justify={"end"}>
          {(table.getIsSomeRowsSelected() || table.getIsAllRowsSelected()) && (
            <MbjButton themeColor={"warning"} onClick={toggleDeleteSession} isPending={false}>
              Supprimer {table.getSelectedRowModel().rows.length} session(s)
            </MbjButton>
          )}
        </MbjFlex>
        {sessionsQuery.isLoading ? (
          <MbjLoading />
        ) : sessionsQuery.isError ? (
          <MbjErrorData />
        ) : sessionsQuery.data.length === 0 ? (
          <MbjNoResult text={"Aucune session n'a été assignée à ce formateur"} />
        ) : (
          <MbjTableV2 table={table} Multiple={true} />
        )}
      </MbjFlex>
      <MbjConfirmModal
        isShowing={isShowingDelete}
        hide={toggleDeleteSession}
        title="Confirmation de suppression de session(s)"
        confirmText={`Etes-vous sûr de vouloir supprimer ${table.getSelectedRowModel().rows.length} session(s) ?`}
        confirmFunction={() => {
          deleteSessionMutation.mutate(
            table.getSelectedRowModel().rows.map((row) => row.original.id || 0),
            {
              onSuccess: () => {
                toggleDeleteSession();
                table.toggleAllRowsSelected(false);
              },
            }
          );
        }}
        isLoading={deleteSessionMutation.isLoading}
      />
    </MbjCardClassic>
  );
};

export const SessionsListe = styled(SessionsListeCtrl)((props) => ({}));
