import styled from "styled-components";
import {
  useGetMetier, useGetOFMBJMe,
  useGetOneStagiaire,
  useSaveConsultProxiMetierStagiaire
} from "@mbj-front-monorepo/data-access";
import React, {useEffect} from "react";
import {MbjFlex, MbjLoading} from "@mbj-front-monorepo/ui";
import {MbjCompareReferentiel} from "@mbj-front-monorepo/data-ui";
import {MetrixMetier} from "./MetrixMetier";

interface ComparateurStagiaireMetierCtrlProps{
    className?:string;
    idStagiaire:number;
    idMetier:number;
    idProxi:number;
}

const ComparateurStagiaireMetierCtrl = (props:ComparateurStagiaireMetierCtrlProps)=>{
    const OFQuery = useGetOFMBJMe();
    const stagiaireQuery = useGetOneStagiaire(props.idStagiaire);
    const metierQuery = useGetMetier(props.idMetier!==0 ? props.idMetier : undefined);
    const savedMobiliteMutation = useSaveConsultProxiMetierStagiaire();

    useEffect(() => {
        if (!savedMobiliteMutation.isSuccess && !savedMobiliteMutation.isLoading) {
            console.log("mutateMobiliteSaved");
          savedMobiliteMutation.mutate({StagiaireProxiMetier:"/api/stagiaire_proxi_metiers/"+props.idProxi});
        }
    }, [props.idProxi]);
    if(props.idMetier === 0){
        return null;
    }
    return (
        <div className={"Mobilites " + props.className}>
            {stagiaireQuery.isLoading || metierQuery.isLoading || OFQuery.isLoading ? (
                <MbjLoading />
            ) : stagiaireQuery.isError || metierQuery.isError || OFQuery.isError ||!OFQuery.data ? (
                <p>Erreur ...</p>
            ) : (
              <MbjFlex direction={"column"} basis={"100%"}>
                <h1>Mobilité vers : {metierQuery.data?.libelle}</h1>
                <MbjCompareReferentiel
                  idToCompare1={stagiaireQuery.data.id || -1}
                  idToCompare2={metierQuery.data.id || -1}
                  typeCompared={"SM"}
                />
                <MetrixMetier Metier={metierQuery.data} idOF={OFQuery.data.id||0}/>

              </MbjFlex>
            )}
        </div>
    );
}

export const ComparateurStagiaireMetier = styled(ComparateurStagiaireMetierCtrl)``
