import styled from "styled-components";
import {
  MbjAccordion,
  MbjAccordionContent,
  MbjAccordionItem,
  MbjAccordionTrigger,
  MbjCardClassic,
  MbjErrorData,
  MbjLoading,
  MbjSearchBar,
} from "@mbj-front-monorepo/ui";
import { FormationSelected } from "./parts/FormationSelected";
import { useGetFormationsOFMBJ, useGetSessionsByFormateur } from "@mbj-front-monorepo/data-access";
import { useMemo, useState } from "react";

interface SessionsInscriptionsCtrlProps {
  className?: string;
  OfId: number;
  idFormateur: number;
}

const SessionsInscriptionsCtrl = (props: SessionsInscriptionsCtrlProps) => {
  const datas = ["a", "b", "c", "d"];
  const FormationsQuery = useGetFormationsOFMBJ(props.OfId);
  const SessionsQuery = useGetSessionsByFormateur(props.idFormateur);
  const [selectedFormation, setSelectedFormation] = useState<string | null>(null);

  const filteredFormations = useMemo(() => {
    if (!selectedFormation) {
      return FormationsQuery.data;
    }
    return FormationsQuery.data?.filter((formation) => formation.libelle?.includes(selectedFormation));
  }, [selectedFormation, FormationsQuery.data]);

  const formateurSessionIds = useMemo(() => {
    const list: number[] = [];
    if (SessionsQuery.data) {
      for (const session of SessionsQuery.data) {
        list.push(session.id || -1);
      }
    }
    return list;
  }, [SessionsQuery.data]);

  if (FormationsQuery.isLoading) {
    return <MbjLoading />;
  }

  if (FormationsQuery.isError) {
    return <MbjErrorData />;
  }

  return (
    <MbjCardClassic className={props.className} title={"Assigner le formateur à une session"} noPadding>
      <div className={"filters"}>
        <MbjSearchBar
          placeholder={"Rechercher une formation"}
          onChange={(event) => {
            setSelectedFormation(event.target.value);
          }}
        ></MbjSearchBar>
      </div>
      <MbjAccordion type="single" defaultValue="item-0" collapsible>
        {filteredFormations?.map((formation, idx) => (
          <MbjAccordionItem value={`item-${idx}`} key={formation.id}>
            <MbjAccordionTrigger>{formation.libelle}</MbjAccordionTrigger>
            <MbjAccordionContent>
              <div className={"formation-infos"}>
                {formation.description ? <span>Description : {formation.description}</span> : "Pas de description"}
              </div>
              <FormationSelected
                idFormateur={props.idFormateur}
                idFormation={formation.id || -1}
                formateursSessionsRegisterdIds={formateurSessionIds}
              />
            </MbjAccordionContent>
          </MbjAccordionItem>
        ))}
      </MbjAccordion>
    </MbjCardClassic>
  );
};

export const SessionsInscriptions = styled(SessionsInscriptionsCtrl)((props) => ({
  ".filters": {
    padding: "1%",
  },
  ".formation-infos": {
    padding: "1%",
  },
}));
