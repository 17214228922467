import styled from "styled-components";
import {GetElementType} from "@mbj-front-monorepo/utils";
import {Operation} from "@mbj-front-monorepo/data-access";
import {useMemo, useState} from "react";
import {MbjCardBack, MbjListItem, MbjLoading, MbjScrollArea, MbjSearchBar} from "@mbj-front-monorepo/ui";
import {MbjMobiliteListItem} from "@mbj-front-monorepo/data-ui";
import * as React from "react";

interface AllDeboucheCtrlProps {
  className?:string;
  Proxis:GetElementType<Operation<"getStagiaireProxiMetierCollection">>[];
  setIdMetier:(id:number, idProxi:number)=>void;
}

const AllDeboucheCtrl = (props: AllDeboucheCtrlProps) => {
  const [search, setSearch] = useState<string>("");
  const ListMobiliteFiltered = useMemo(()=>{
    return props.Proxis
      ?.filter((mob) => {
        if (search === "") {
          return mob;
        } else if (mob.Metier?.libelle?.toLowerCase().includes(search.toLowerCase())) {
          return mob;
        }
        return undefined;
      })
      .sort((a, b) => {
        return (a.Metier ? (a.Metier.libelle|| "0") : "0") > (b.Metier ? (b.Metier.libelle|| "0") : "0") ? 1 : -1;
      });
  }, [search, props.Proxis])
  return (
    <MbjCardBack title={"Tous les débouchés"}>
      <div className={`all_mob ${props.className}`}>
        <MbjSearchBar
          placeholder={"Rechercher un métier"}
          className={"SearchBar"}
          onChange={(event) => setSearch(event.target.value)}
        />
        <MbjScrollArea grow={1}>
          {
            ListMobiliteFiltered
              .map((mobilite, idx: number) => (
                <MbjListItem key={mobilite.id} onClick={()=>props.setIdMetier(mobilite.Metier?.id||0, mobilite.id||0)}>
                  <MbjMobiliteListItem mobilite={mobilite} className="in-line" />
                </MbjListItem>
              ))
          }
        </MbjScrollArea>
      </div>
    </MbjCardBack>
  )
}

export const AllDebouche = styled(AllDeboucheCtrl)`
  height: 500px;
  display: flex;
  flex-direction: column;
`
