import * as AccordionPrimitive from "@radix-ui/react-accordion";
import styled, { css, keyframes } from "styled-components";

interface MbjAccordionContentProps {}

const slideDown = keyframes`
  from {
    height: 0;
  }
  to {
    height: var(--radix-accordion-content-height);
  }
`;
const slideUp = keyframes`
  from {
    height: var(--radix-accordion-content-height);
  }
  to {
    height: 0;
  }
`;

export const MbjAccordionContent = styled(AccordionPrimitive.Content)<MbjAccordionContentProps>(
  (props) => ({
    // overflow: "hidden",
    overflow: "auto",
    scrollbarGutter: "stable both-edges",
    fontSize: "calc(10px + 0.5vmin)",
    fontWeight: 600,
    color: props.theme.greyDarken,
    backgroundColor: props.theme.background2,
  }),
  css`
    &[data-state="open"] {
      animation: ${slideDown} 300ms cubic-bezier(0.87, 0, 0.13, 1) forwards;
    }
    &[data-state="closed"] {
      animation: ${slideUp} 300ms cubic-bezier(0.87, 0, 0.13, 1) forwards;
    }
  `
);
MbjAccordionContent.defaultProps = {};
