import { fetchApi, Operation } from "../../services/fetchApi";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

export const useGetSessionFormationsOFMBJ = (idFormation: number | undefined) => {
  const promise = () =>
    fetchApi("/api/session_formation_o_f_m_b_js", { method: "get", query: { FormationOFMBJ: idFormation + "" } });
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["sessions", { idFormation }], promise, {
    enabled: !!idFormation,
  });
};

export const useGetOneSession = (idSession: string | undefined) => {
  const promise = () =>
    fetchApi("/api/session_formation_o_f_m_b_js/{id}", { method: "get", params: { id: idSession || "" } });
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["sessions", { id: idSession }], promise, {
    enabled: !!idSession,
  });
};

export const useUpdateSession = (idSession: string | undefined) => {
  const queryClient = useQueryClient();
  return useMutation(
    (data: Operation<"putSessionFormationOFMBJItem", "requestBody">) => {
      return fetchApi("/api/session_formation_o_f_m_b_js/{id}", {
        method: "put",
        json: data,
        params: { id: idSession || "" },
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["sessions"]);
        toast.success("Session modifiée !", {});
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
};

export const useAddSession = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (data: Operation<"postSessionFormationOFMBJCollection", "requestBody">) => {
      return fetchApi("/api/session_formation_o_f_m_b_js", { method: "post", json: data });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["sessions"]);
        toast.success("Session ajoutée !", {});
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
};
