import styled from "styled-components";
import {OneFormationThere} from "../formation";

interface FormationCurrentCtrlProps {
  className?:string;
  Formation:OneFormationThere;
}

const FormationCurrentCtrl = ({className, Formation}: FormationCurrentCtrlProps) => {
  return (
    <div className={`wrap_one_formation ${className}`}>
      <div className="infos_form">
        <div className="libelle_formation">{Formation.libelle}</div>
        <div
          className="dates">du {Formation.startAt ? new Date(Formation.startAt).toLocaleDateString() : ''} au {Formation.endAt ? new Date(Formation.endAt).toLocaleDateString() : ''}</div>
      </div>
    </div>
  )
}

export const FormationCurrent = styled(FormationCurrentCtrl)`
  .infos_form {
    display: flex;
    justify-content: flex-start;
    gap: 12px;
  }
`
