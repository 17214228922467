import {fetchApi, Operation} from "../../services/fetchApi";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {toast} from "react-toastify";


export const useGetStagiaireActivite = (idStagiaire: number | undefined) => {
  const promise = () => fetchApi("/api/stagiaire_activites", { method: "get", query: { Stagiaire: idStagiaire + "" } });
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["stagiaires_activites", { idStagiaire }], promise, {
    enabled: !!idStagiaire,
  });
};


export const useUpdateNiveauActiviteStagiaire = (idStagiaire?: number) => {
  const queryClient = useQueryClient();
  return useMutation(
    (data: { id: number; niveau: number }) => {
      return fetchApi("/api/stagiaire_activites/{id}", {
        method: "put",
        params: { id: data.id + "" },
        json: { niveau: data.niveau },
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["stagiaires_activites", { idStagiaire }]);
        queryClient.invalidateQueries(["competences_stagiaire"]);
        toast.success("Activité modifiée !", {});
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
};

export const useAddActiviteStagiaire = (idStagiaire?: number) => {
  const queryClient = useQueryClient();
  return useMutation(
    (data: Operation<"postStagiaireActiviteCollection", "requestBody">) => {
      return fetchApi("/api/stagiaire_activites", { method: "post", json: data });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["stagiaires_activites", { idStagiaire }]);
        queryClient.invalidateQueries(["competences_stagiaire", { idStagiaire }]);
        toast.success("Activité ajoutée !", {});
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
};

export const useDeleteActiviteStagiaire = (idStagiaire?: number) => {
  const queryClient = useQueryClient();
  return useMutation(
    (idActivite: number) => {
      return fetchApi("/api/stagiaire_activites/{id}", { method: "delete", params: { id: idActivite + "" } });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["formations_of_mbj_activites", { idStagiaire }]);
        queryClient.invalidateQueries(["competences_formation_of_mbj", { idStagiaire }]);
        toast.success("Activité supprimée !", {});
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
};
