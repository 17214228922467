import styled from "styled-components";
import React, {useEffect, useRef, useState} from "react";
import {useMutateMember, useMutateOFMBJ, useMutateStagiaire} from "@mbj-front-monorepo/data-access";
import {MbjButton} from "@mbj-front-monorepo/ui";
import {FiSave} from "react-icons/fi";
import {GoPencil} from "react-icons/go";
import {IoClose} from "react-icons/io5";

interface SwitchInfoCtrlProps {
  className?:string;
  type:"text"|"area";
  info:string;
  id:string;
  label:string;
  opened:string;
  setOpened:(s:string)=>void;
  idOF:number;
}

const SwitchInfoCtrl = ({className, type, info, id, label, opened, setOpened, idOF}: SwitchInfoCtrlProps) => {
  const [editx, setEditx] = useState(false);
  const mutation = useMutateOFMBJ(idOF);
  const refArea = useRef<HTMLTextAreaElement>(null);
  const refInput = useRef<HTMLInputElement>(null);
  const [myInfos, setMyInfos] = useState(info);
  useEffect(() => {
    setEditx(opened === id);
  }, [opened]);
  const SwitchEdit = ()=>{
    if(opened === id) {
      setOpened('');
    } else{
      setOpened(id);
    }
    setEditx(o=>!o);
  }
  const validInfos = ()=>{
    const data:any = {[id]:myInfos};
    mutation.mutate(data, {
      onSuccess:()=>{
        SwitchEdit();
      }
    })
  }
  const handleChangeArea = ()=>{
    if(refArea.current){
      setMyInfos(refArea.current.value);
    }
  }
  const handleChangeInput = ()=>{
    if(refInput.current){
      setMyInfos(refInput.current.value);
    }
  }
  return (
    <div className={"one_switch_info "+className}>
      <label>{label}</label>
      {!editx ?
        <div className="wrap_infos">
          <div className="info-infos">{info}</div>
          <div className={"wrap_btn"}>
            <div className={"wrap_my_btn"}>
              <button className={"btn_switch"} onClick={SwitchEdit}><GoPencil /></button>
            </div>
          </div>
        </div> :
        <div className="edit_info">
          <div className={`wrap_input`}>
            {type === "area" ?
              <textarea value={myInfos} ref={refArea} onChange={handleChangeArea}/> :
              <input ref={refInput} type={"text"} value={myInfos} onChange={handleChangeInput}/>
              }
          </div>
          <div className={"wrap_btn"}>
            <button className={"btn_switch is-cancel"} onClick={SwitchEdit}><IoClose /></button>
            <button className={"btn_switch is-valid"} onClick={validInfos}><FiSave/></button>
          </div>
        </div>
      }
    </div>
  )
}

export const SwitchInfo = styled(SwitchInfoCtrl)`
  label{
    font-size: 15px;
    font-weight: bold;
    color: ${props=>props.theme.primaryLighten};
  }
  .btn_switch{
    background: transparent;
    border: none;
    font-size: 16px;
    padding: 0.25rem 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    &.is-valid{
      background: ${props => props.theme.thirdLighten};
      border-radius: 4px;
      color:white;
    }
    &.is-cancel{
      background: ${props => props.theme.greyDarken};
      border-radius: 4px;
      color:white;
    }
    &:hover{
      cursor: pointer;
      color:black;
    }
  }
    .wrap_my_btn{
      width: 30px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 0;
      height: 19px;
    }
  .edit_info{
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    .wrap_input{
      flex-grow: 1;
    }
    input{
      height: 32px;
      width: 80%;
      padding-left: 5px;
      border-radius: 4px;
      border:solid ${props => props.theme.primary} 1px;
    }
    textarea{
      width: 90%;
      padding: 0.25rem;
      border-radius: 4px;
      border:solid ${props => props.theme.primary} 1px;
      resize: none;
    }
    .wrap_btn{
      display: flex;
      justify-content: flex-end;
      gap: 5px;
    }
  }
    .wrap_infos{
      display: flex;
      width: 100%;
      justify-content: flex-start;
      align-items: center;
      .info-infos{
        flex-grow: 1;
      }
      .wrap_btn{
        width: 0;
        position: relative;
        overflow: hidden;
        height: 28px;
      }
      &:hover{
        .wrap_btn{
            width: 35px;
        }
      }
    }
`
