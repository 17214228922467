import styled from "styled-components";
import {GoTriangleDown} from "react-icons/go";
import {useEffect, useRef, useState} from "react";
import {FaCheck} from "react-icons/fa";

interface OneSelectorDateCtrlProps {
  className?:string;
  Choices:{id:number, libelle:string}[];
  choicesCurr:{id:number, libelle:string}[];
  clickOnChoice:(id:number, type:string)=>void;
  libelle:string;
  id:string;
}

const OneSelectorDateCtrl = ({className, Choices, clickOnChoice, choicesCurr, libelle, id}: OneSelectorDateCtrlProps) => {
  const [open, setOpen] = useState(false);
  const refObj = useRef<HTMLDivElement>(null)
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event:any) {
      if (refObj.current && !refObj.current.contains(event.target)) {
        setOpen(false);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [refObj]);
  return (
    <div className={`one_selector_date ${className}`} ref={refObj}>
      <div className="lib" onClick={()=>setOpen(o=>!o)}>
        <div className="lib_lib">{libelle}</div>
        <div className="wrap_tri"><GoTriangleDown/></div>
      </div>
      <div className={`list_choice ${open ? 'opened' : ''}`}>
        {Choices.map((item, idx) => (
          <div className="one_choice" key={`C${id}${idx}`} onClick={() => clickOnChoice(item.id, id)}>
            <div className={`marker ${choicesCurr.map(x => x.id).indexOf(item.id) === -1 ? '' : 'selected'}`}><FaCheck/>
            </div>
            <div className="libelle_choice">{item.libelle}</div>
          </div>
        ))}
      </div>
    </div>
  )
}

export const OneSelectorDate = styled(OneSelectorDateCtrl)`
  width: max-content;
  position: relative;
  .list_choice{
    position: absolute;
    left: 0;
    top:100%;
    width: max-content;
    background: white;
    display: none;
    min-width: 100%;
    border: solid #ccc 1px;
    box-shadow: 1px 1px 3px rgba(0,0,0,.2);
    border-radius: 0 0 4px 4px;
    &.opened{
      display: block;
    }
    .one_choice{
      padding: 0.25rem 0.5rem;
      display: flex;
      justify-content: flex-start;
      &:hover{
        cursor: pointer;
        .libelle_choice{
          font-weight: bold;
        }
      }
      .libelle_choice{
        padding: 0 0.25rem;
      }
      .marker{
        width: 22px;
        height: 22px;
        border:solid 1px ${props => props.theme.thirdDarken};
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        svg{
          margin: auto;
          color: white;
          display: none;
        }
        &.selected{
          background: ${props => props.theme.thirdDarken};
          svg{
            display: block;
          }
        }
      }
    }
  }
  .lib {
    display: flex;
    justify-content: space-between;
    border:solid ${props=>props.theme.primary} 1px;
    border-radius: 4px;
    &:hover{
      cursor: pointer;
      .lib_lib{
        font-weight: bold;
      }
    }
    .lib_lib{
      padding: 0.25rem 0.5rem;
    }
    .wrap_tri{
      width: 30px;
      height: 30px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      svg{
        margin: auto;
      }
    }
  }
`
