import React, { useMemo } from "react";
import styled from "styled-components";
import {
  CustomSelect,
  MbjButton,
  MbjSimpleInput,
  MbjSimpleInputContainer,
  MbjSimpleTextArea,
} from "@mbj-front-monorepo/ui";
import { useAddFormateurFi, useGetDisciplines, useGetNiveaux } from "@mbj-front-monorepo/data-access";
import { Controller, useForm } from "react-hook-form";
import { getDateEN, GetElementType } from "@mbj-front-monorepo/utils";

interface MbjFormateurFiNewFormCtrlProps {
  className?: string;
  idFormateur: number;
  hideModal?: () => void;
  isMobile?: boolean;
}

const MbjFormateurFiNewFormCtrl = (props: MbjFormateurFiNewFormCtrlProps) => {
  const mutation = useAddFormateurFi();
  const niveauxQuery = useGetNiveaux();
  const disciplinesQuery = useGetDisciplines();

  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
    watch,
    setValue,
  } = useForm({
    mode: "onChange",
  });

  const mesdisciplines = useMemo(() => {
    const domaineChoix = watch("domaine");
    //console.log(domaineChoix);
    if (!domaineChoix) {
      return [];
    } else {
      const ret: Array<GetElementType<typeof disciplinesQuery.data>> = [];
      disciplinesQuery.data?.map((d) => {
        if (d.Groupe?.id === domaineChoix.id) {
          ret.push(d);
        }
        return d;
      });
      return ret;
    }
  }, [watch("domaine"), disciplinesQuery.data]);

  const onSubmit = (data: any) => {
    const datas = {
      Formateur: "/api/formateurs/" + props.idFormateur,
      NiveauEtude: "/api/niveau_etudes/" + data.niveau.id,
      Discipline: "/api/disciplines/" + data.discipline.id,
      nom: data.nom,
      ObtentionAt: data.obtentionAt ? getDateEN(new Date(data.obtentionAt)) : "",
      description: data.description,
    };
    mutation.mutate(datas, {
      onSuccess: () => {
        if (props.hideModal !== undefined) {
          props.hideModal();
        }
      },
    });
    return;
  };

  return (
    <div className={`FormXpPro ${props.className}`}>
      <form onSubmit={handleSubmit(onSubmit)} className="formNewFormationInitiale">
        <div className={"rowContainer"}>
          <MbjSimpleInputContainer errors={errors} label={"Nom exact du diplôme *"}>
            <MbjSimpleInput
              name={"nom"}
              id={"nom"}
              type={"text"}
              noPadding
              register={register}
              registerOptions={{
                required: {
                  value: true,
                  message: "Nom requis",
                },
                minLength: {
                  value: 5,
                  message: "Nom trop court",
                },
              }}
            />
          </MbjSimpleInputContainer>

          <MbjSimpleInputContainer errors={errors} label={"Date d'obtention *"}>
            <MbjSimpleInput
              name={"obtentionAt"}
              id={"obtentionAt"}
              type={"date"}
              noPadding
              register={register}
              registerOptions={{
                required: {
                  value: true,
                  message: "Date d'obtention requise",
                },
              }}
            />
          </MbjSimpleInputContainer>
        </div>

        <div className={`${!props.isMobile ? "rowContainer niveauInfos" : "on_col"}`}>
          <MbjSimpleInputContainer errors={errors} label={"Niveau *"} htmlFor={"niveau"}>
            <Controller
              name={"niveau"}
              control={control}
              rules={{ required: { value: true, message: "Niveau requis" } }}
              render={({ field }) => (
                <CustomSelect
                  inputId={"niveau"}
                  isLoading={niveauxQuery.isLoading}
                  options={niveauxQuery.data}
                  getOptionLabel={(item) => item.libelle || ""}
                  getOptionValue={(item) => item.id + ""}
                  isClearable={true}
                  onChange={(selectedOption, triggeredAction) => {
                    if (selectedOption) {
                      field.onChange(selectedOption);
                    }
                    if (triggeredAction.action === "clear") {
                      field.onChange(null);
                    }
                    setValue("domaine", null);
                    setValue("discipline", null);
                  }}
                />
              )}
            />
          </MbjSimpleInputContainer>
          {watch("niveau") && (
            <MbjSimpleInputContainer errors={errors} label={"Domaine *"} htmlFor={"domaine"}>
              <Controller
                name={"domaine"}
                control={control}
                rules={{ required: { value: true, message: "Domaine requis" } }}
                render={({ field }) => (
                  <CustomSelect
                    inputId={"domaine"}
                    isLoading={niveauxQuery.isLoading}
                    options={watch("niveau").Groupes}
                    getOptionLabel={(item) => item.libelle}
                    getOptionValue={(item) => item.id + ""}
                    isClearable={true}
                    value={watch("domaine")}
                    onChange={(selectedOption, triggeredAction) => {
                      if (selectedOption) {
                        field.onChange(selectedOption);
                      }
                      if (triggeredAction.action === "clear") {
                        field.onChange(null);
                      }
                      setValue("discipline", null);
                    }}
                  />
                )}
              />
            </MbjSimpleInputContainer>
          )}
          {watch("domaine") && (
            <MbjSimpleInputContainer errors={errors} label={"Discipline *"} htmlFor={"discipline"}>
              <Controller
                name={"discipline"}
                control={control}
                rules={{ required: { value: true, message: "Discipline requise" } }}
                render={({ field }) => (
                  <CustomSelect
                    inputId={"discipline"}
                    isLoading={disciplinesQuery.isLoading}
                    options={mesdisciplines}
                    getOptionLabel={(item) => item.libelle}
                    getOptionValue={(item) => item.id + ""}
                    isClearable={true}
                    value={watch("discipline")}
                    onChange={(selectedOption, triggeredAction) => {
                      if (selectedOption) {
                        field.onChange(selectedOption);
                      }
                      if (triggeredAction.action === "clear") {
                        field.onChange(null);
                      }
                    }}
                  />
                )}
              />
            </MbjSimpleInputContainer>
          )}
        </div>

        <MbjSimpleInputContainer errors={errors} label={"Description"}>
          <MbjSimpleTextArea name={`description`} id={`description`} noPadding register={register} />
        </MbjSimpleInputContainer>
        <MbjButton isPending={mutation.isLoading}>Confirmer</MbjButton>
      </form>
    </div>
  );
};

export const MbjFormateurFiNewForm = styled(MbjFormateurFiNewFormCtrl)((props) => ({
  form: {
    gap: "8px",
    display: "flex",
    flexDirection: "column",
    ".on_col": {
      display: "flex",
      flexDirection: "column",
      gap: "10px",
    },
    ".rowContainer": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "start",
      gap: "20px",
      flexWrap: "wrap",
    },
    ".niveauInfos": {
      "& > *": {
        flex: 1,
      },
    },
    button: {
      alignSelf: "flex-end",
    },
  },
}));
