import styled from "styled-components";
import {AvatarFact} from "@mbj-front-monorepo/ui";

interface MemberAllianceCardCtrlProps {
  className?:string;
  logo?:string|null;
  name?:string|null;
  description?:string|null;
}

const MemberAllianceCardCtrl = ({className, name, description, logo}: MemberAllianceCardCtrlProps) => {
  const handleError = (e:any)=>{
    e.target.src = AvatarFact;
  }
  return (
    <div className={`member_alliance_card ${className}`}>
      <div className="wrap_avatar">
        <img
          src={`${process.env["REACT_APP_API_URL_ROOT"]}/logos/members/${logo}`}
          alt={"avatar_logo"}
          onError={handleError}
        />
      </div>
      <div className="infos">
        <div className="name_entre">{name}</div>
        <div className="descrip_entre">{description ? description : 'aucune description'}</div>
      </div>
    </div>
  )
}

export const MemberAllianceCard = styled(MemberAllianceCardCtrl)`
  display: flex;
  justify-content: flex-start;
  padding: 10px 0.25rem;
  border-bottom: solid #ccc 1px;
  .wrap_avatar{
    width: 90px;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    img{
      width: 100%;
      height: auto;
    }
  }
  .infos{
    padding: 1rem;
    flex-grow: 1;
    .name_entre{
      font-weight: bold;
    }
  }
`
