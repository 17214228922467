import styled from "styled-components";
import { components, useAddFormationOFMBJ, useGetMetiers, useGetOFMBJMe } from "@mbj-front-monorepo/data-access";
import React, { useMemo, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  MbjButton,
  MbjFlex,
  MbjLabel,
  MbjRadioGroup,
  MbjRadioGroupIndicator,
  MbjRadioGroupItem,
  MbjSimpleInput,
  MbjSimpleInputContainer,
  MbjSimpleTextArea,
} from "@mbj-front-monorepo/ui";
import { BsSearch } from "react-icons/bs";
import { GrClose } from "react-icons/gr";

interface ModalNewFormationCtrlProps {
  className?: string;
  closeMe: () => void;
}

const ModalNewFormationCtrl = (props: ModalNewFormationCtrlProps) => {
  const MeQuery = useGetOFMBJMe();

  const metiersQuery = useGetMetiers();
  const mutation = useAddFormationOFMBJ();
  const [search, setSearch] = useState("");
  const [MetierChoice, setMetierChoice] = useState<{ id: number; libelle: string }>({ id: 0, libelle: "Choix metier" });
  const refSearch = useRef<HTMLInputElement>(null);

  const listFiltered = useMemo(() => {
    const regex = new RegExp("" + search + "", "gi");
    const TabMet: components["schemas"]["Metier-read.Metier"][] = [];
    if (metiersQuery.data) {
      metiersQuery.data.forEach((m) => {
        if (search === "" || m.libelle?.match(regex)) {
          TabMet.push(m);
        }
      });
    }
    return TabMet;
  }, [metiersQuery.data, search]);
  const methods = useForm({
    mode: "onChange",
    defaultValues: {
      libelle: "",
      duree: undefined,
      description: "",
      modalite: "Présentiel",
      type: "inter",
      isCPF: false,
    },
  });
  const SubmitForm = (datas: any) => {
    const myDatas: any = { ...datas, Metier: `/api/metiers/${MetierChoice.id}`, duree: parseInt(datas.duree) };
    mutation.mutate(myDatas, {
      onSuccess: () => {
        props.closeMe();
      },
    });
  };
  const handleChangeSearch = () => {
    if (refSearch.current) {
      setSearch(refSearch.current.value);
    }
  };
  return (
    <div className={`new_formation ${props.className}`}>
      {MetierChoice.id === 0 ? (
        <div className="choice_metier">
          <div className={`wrap_search`}>
            <div className={`search_zone`}>
              <BsSearch className={"icon-search"} />
              <input
                type={"text"}
                value={search}
                onChange={handleChangeSearch}
                ref={refSearch}
                placeholder={"Rechercher un métier"}
              />
              {search !== "" && (
                <div className={`round_delete`} onClick={() => setSearch("")}>
                  <GrClose />
                </div>
              )}
            </div>
            <div className={`list_zone`}>
              {listFiltered.map((item) => (
                <div
                  className={`metier_to_add`}
                  key={`Om${item.id}`}
                  onClick={() => setMetierChoice({ id: item.id || 0, libelle: item.libelle || "" })}
                >
                  <span>{item.libelle}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <div className="wrap_form">
          <form onSubmit={methods.handleSubmit(SubmitForm)}>
            <div className="libelle_métier">{MetierChoice.libelle}</div>
            <MbjSimpleInputContainer errors={methods.formState.errors} label={"Libellé de la formation*"}>
              <MbjSimpleInput
                name={"libelle"}
                id={"libelle"}
                type={"text"}
                noPadding
                register={methods.register}
                registerOptions={{ required: { value: true, message: "libellé requis" } }}
              />
            </MbjSimpleInputContainer>
            <MbjSimpleInputContainer errors={methods.formState.errors} label={"durée de la formation*"}>
              <MbjSimpleInput
                name={"duree"}
                id={"duree"}
                type={"number"}
                noPadding
                register={methods.register}
                registerOptions={{ required: { value: true, message: "durée requise" } }}
              />
            </MbjSimpleInputContainer>
            <MbjLabel size={"very-small"} themeColor={"primary"}>
              Modalité de la formation*
            </MbjLabel>
            <Controller
              name={"modalite"}
              control={methods.control}
              render={({ field }) => {
                return (
                  <MbjRadioGroup
                    aria-label="Choix de la modalité de formation"
                    onValueChange={(value) => {
                      field.onChange(value);
                    }}
                    className={"radioContainer"}
                    defaultValue={field.value}
                  >
                    <MbjFlex gap={"20px"} direction={"row"}>
                      <MbjFlex wrap={"nowrap"} align={"center"} width={"none"}>
                        <MbjRadioGroupItem value="Présentiel" id="r1">
                          <MbjRadioGroupIndicator />
                        </MbjRadioGroupItem>
                        <MbjLabel htmlFor="r1" themeColor={"dark"} size={"very-small"}>
                          Présentiel
                        </MbjLabel>
                      </MbjFlex>
                      <MbjFlex wrap={"nowrap"} align={"center"} width={"none"}>
                        <MbjRadioGroupItem value="Distanciel" id="r2">
                          <MbjRadioGroupIndicator />
                        </MbjRadioGroupItem>
                        <MbjLabel htmlFor="r2" themeColor={"dark"} size={"very-small"}>
                          Distanciel
                        </MbjLabel>
                      </MbjFlex>
                      <MbjFlex wrap={"nowrap"} align={"center"} width={"none"}>
                        <MbjRadioGroupItem value="Hybride" id="r3">
                          <MbjRadioGroupIndicator />
                        </MbjRadioGroupItem>
                        <MbjLabel htmlFor="r3" themeColor={"dark"} size={"very-small"}>
                          Hybride
                        </MbjLabel>
                      </MbjFlex>
                    </MbjFlex>
                  </MbjRadioGroup>
                );
              }}
            />
            <MbjLabel size={"very-small"} themeColor={"primary"}>
              Type de formation*
            </MbjLabel>
            <Controller
              name={"type"}
              control={methods.control}
              render={({ field }) => {
                return (
                  <MbjRadioGroup
                    defaultValue={field.value}
                    aria-label="Type de formation"
                    onValueChange={(value) => {
                      field.onChange(value);
                    }}
                    className={"radioContainer"}
                  >
                    <MbjFlex gap={"20px"} direction={"row"}>
                      <MbjFlex wrap={"nowrap"} align={"center"} width={"none"}>
                        <MbjRadioGroupItem value="inter" id="inter">
                          <MbjRadioGroupIndicator />
                        </MbjRadioGroupItem>
                        <MbjLabel htmlFor="inter" themeColor={"dark"} size={"very-small"}>
                          Inter-entreprise
                        </MbjLabel>
                      </MbjFlex>
                      <MbjFlex wrap={"nowrap"} align={"center"} width={"none"}>
                        <MbjRadioGroupItem value="intra" id="intra">
                          <MbjRadioGroupIndicator />
                        </MbjRadioGroupItem>
                        <MbjLabel htmlFor="intra" themeColor={"dark"} size={"very-small"}>
                          Intra-entreprise
                        </MbjLabel>
                      </MbjFlex>
                    </MbjFlex>
                  </MbjRadioGroup>
                );
              }}
            />

            <MbjSimpleInputContainer errors={methods.formState.errors} label={"Éligibilité au CPF*"}>
              <MbjSimpleInput
                name={"isCPF"}
                id={"isCPF"}
                type={"checkbox"}
                noPadding
                register={methods.register}
                required={false}
              />
            </MbjSimpleInputContainer>

            <MbjSimpleInputContainer errors={methods.formState.errors} label={"description"}>
              <MbjSimpleTextArea register={methods.register} name={"description"} />
            </MbjSimpleInputContainer>
            <MbjButton alignSelf={"flex-end"} isPending={mutation.isLoading}>
              Créer la formation
            </MbjButton>
          </form>
        </div>
      )}
    </div>
  );
};

export const ModalNewFormation = styled(ModalNewFormationCtrl)`
  padding: 1rem;
  form {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .wrap_choices {
    display: flex;
    justify-content: flex-start;
  }
  .addZone {
    width: 300px;
    padding-left: 1rem;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
  }
  .list_metiers {
    flex-grow: 1;
    overflow-y: auto;
    padding: 0.25rem 0;
  }
  .valid_zone {
    padding: 0.5rem 0;
    display: flex;
    justify-content: flex-end;
  }
  .wrap_search {
    padding-right: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .list_zone {
    width: 100%;
    height: 325px;
    overflow-y: auto;
    padding: 0.25rem 0;
  }
  .search_zone {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;
    padding-bottom: 0.25rem;
    border-bottom: solid #ccc 1px;
    input {
      flex-grow: 1;
      border: none;
      height: 36px;
    }
  }
  .metier_to_add {
    width: 100%;
    padding: 0.2rem 0.5rem 0.2rem 0.25rem;
    position: relative;
    &:hover {
      background: ${(props) => props.theme.primaryPastel};
      cursor: pointer;
      .add_sig {
        display: flex;
      }
    }
  }
`;
