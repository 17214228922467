import styled from "styled-components";
import {OneFormationThere} from "../formation";
import {MbjIconButton, MbjModal2, useModal} from "@mbj-front-monorepo/ui";
import {BsTrash} from "react-icons/bs";
import {FaCheck} from "react-icons/fa";
import {useGetStagiaireSession, useValidateSession} from "@mbj-front-monorepo/data-access";
import {useState} from "react";
import {ModalValideValidation} from "./ModalValideValidation";

interface FormationPastCtrlProps {
  className?:string;
  Formation:OneFormationThere;
}

const FormationPastCtrl = ({className, Formation}: FormationPastCtrlProps) => {
  const mutationValide = useValidateSession()
  const {isShowing, toggle} = useModal();
  const ValideMe = ()=>{
      if(Formation.id) mutationValide.mutate(Formation.id)
  }
  const ClickOnValide = ()=>{
    toggle();
  }
  return (
    <div className={`wrap_one_formation ${className}`}>
      <div className="infos_form">
        <div className="libelle_formation">{Formation.libelle}</div>
        <div
          className="dates">du {Formation.startAt ? new Date(Formation.startAt).toLocaleDateString() : ''} au {Formation.endAt ? new Date(Formation.endAt).toLocaleDateString() : ''}</div>
      </div>
      <div className="display_actions">
        {!Formation.isValidate &&
          <MbjIconButton size={"xs"} icon={<FaCheck />} themeColor={"primary"} onClick={ClickOnValide} ariaLabel={"valider"}/>
        }
      </div>
      <MbjModal2 open={isShowing} titre={"Validation de la formation"} closeMe={toggle} Wd={"550px"}>
          <ModalValideValidation closeMe={toggle} confirm={ValideMe}/>
      </MbjModal2>
    </div>
  )
}

export const FormationPast = styled(FormationPastCtrl)`
  display: flex;
  justify-content: space-between;
  .infos_form {
    display: flex;
    justify-content: flex-start;
    gap: 12px;
  }
`
