import { Navigate, Route, Routes, useParams } from "react-router-dom";
import { InformationsFormation } from "./informations/InformationsFormation";
import { NotFound } from "../../../../components/NotFound/NotFound";
import { InternDesktopLayout, MbjHeading, MbjLink, MbjLoading, MbjSectionMenu } from "@mbj-front-monorepo/ui";
import { FaUserCircle } from "react-icons/fa";
import * as React from "react";
import styled from "styled-components";
import { useGetOneFormationOFMBJ } from "@mbj-front-monorepo/data-access";
import { FormationCompetences } from "./Competences/FormationCompetences";
import { FormationSessions } from "./Sessions/FormationSessions";

interface OneFormationRooterCtrlProps {
  className?: string;
}

const OneFormationRooterCtrl = (props: OneFormationRooterCtrlProps) => {
  const { id } = useParams();
  const FormationQuery = useGetOneFormationOFMBJ(id ? parseInt(id) : 0);
  return (
    <Routes>
      <Route
        element={
          <InternDesktopLayout
            type="OF"
            mbjSectionMenu={
              <MbjSectionMenu
                className={"custom-sectionMenu " + props.className}
                otherContent={
                  <div className={"custom-content-menu"}>
                    {FormationQuery.data ? (
                      <>
                        <FaUserCircle className={"icon-profile-menu"} />
                        <MbjHeading marginIn={"0"} themeColor={"primary"} level={"h2"}>
                          {FormationQuery.data?.libelle?.toUpperCase()}
                        </MbjHeading>
                      </>
                    ) : (
                      <MbjLoading noMargin={true} height={"25px"} />
                    )}
                  </div>
                }
              >
                <MbjLink to="informations">Informations</MbjLink>
                <MbjLink to="competences">Compétences</MbjLink>
                <MbjLink to="sessions">Sessions</MbjLink>
              </MbjSectionMenu>
            }
          />
        }
      >
        <Route path={""} element={<Navigate to={"informations"} />} />
        <Route path={"informations"} element={<InformationsFormation />} />
        <Route path={"competences/*"} element={<FormationCompetences />} />
        <Route path={"sessions"} element={<FormationSessions />} />
        <Route path={"*"} element={<NotFound />} />
      </Route>
    </Routes>
  );
};

export const OneFormationRooter = styled(OneFormationRooterCtrl)`
  min-height: fit-content;
  justify-content: space-between;
  .custom-content-menu {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5em;

    .icon-profile-menu {
      fill: ${(props) => props.theme.primaryDarken};
      height: 25px;
      width: 25px;
    }
  }
`;
