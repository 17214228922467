import styled from "styled-components";

interface OneBlockDashCtrlProps {
  className?:string;
  libelle:string;
  BigNumber:number;
}

const OneBlockDashCtrl = ({className, BigNumber, libelle}: OneBlockDashCtrlProps) => {
  return (
    <div className={`one_dash_block ${className}`}>
      <div className="libelle">{libelle}</div>
      <div className="number"><span>{BigNumber}</span></div>
    </div>
  )
}

export const OneBlockDash = styled(OneBlockDashCtrl)`
  border-radius: 6px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  background: white;
  .libelle{
    padding: 0.25rem;
    text-align: center;
  }
  .number{
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    span{
      font-size: 75px;
      text-align: center;
      display: block;
    }
  }
`
