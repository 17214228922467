import { MbjButton, MbjFlex, MbjListItem } from "@mbj-front-monorepo/ui";
import { useAddFormateurSession } from "@mbj-front-monorepo/data-access";
import styled from "styled-components";

interface SessionListItemCtrlProps {
  className?: string;
  idFormateur: number;
  idSession: number;
  startAt: string;
  endAt: string;
}

const SessionListItemCtrl = (props: SessionListItemCtrlProps) => {
  const mutationAdd = useAddFormateurSession(props.idFormateur);

  const handleAdd = () => {
    mutationAdd.mutate(props.idSession);
  };

  return (
    <MbjListItem className={props.className}>
      <MbjFlex align={"center"} justify={"space-between"} gap={"8px"}>
        <span>
          - Du {props.startAt} au {props.endAt}
        </span>
        <MbjButton onClick={handleAdd} isPending={mutationAdd.isLoading}>
          Ajouter
        </MbjButton>
      </MbjFlex>
    </MbjListItem>
  );
};

export const SessionListItem = styled(SessionListItemCtrl)((props) => ({}));
