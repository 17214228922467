import styled from "styled-components";
import { MbjErrorData, MbjFlex, MbjLoading } from "@mbj-front-monorepo/ui";
import { SessionsInscriptions } from "./SessionInscription";
import { SessionsListe } from "./SessionsListe";
import { useGetOFMBJMe } from "@mbj-front-monorepo/data-access";
import { useParams } from "react-router-dom";

interface SessionsCtrlProps {
  className?: string;
}

const SessionsCtrl = (props: SessionsCtrlProps) => {
  const OFQuery = useGetOFMBJMe();
  const { id } = useParams();

  return (
    <MbjFlex className={props.className} direction={"row"}>
      {OFQuery.isLoading ? (
        <MbjLoading />
      ) : OFQuery.isError || !id ? (
        <MbjErrorData />
      ) : (
        <>
          <SessionsListe idFormateur={parseInt(id)} />
          <SessionsInscriptions OfId={OFQuery.data.id} idFormateur={parseInt(id)} className={"inscriptions"} />
        </>
      )}
    </MbjFlex>
  );
};

export const Sessions = styled(SessionsCtrl)((props) => ({
  padding: "1% 2%",
  gap: "10px",
  ".inscriptions": {
    // flexShrink: 1,
  },
}));
