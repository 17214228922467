import styled from "styled-components";
import {OneSearchUM} from "./SeachsUM";
import {MemberDetailsRecrutement} from "../components/MemberDetailsRecrutement";
import {useGetSearchOne} from "@mbj-front-monorepo/data-access";
import {MbjLoading} from "@mbj-front-monorepo/ui";
import {MbjCompareReferentiel} from "@mbj-front-monorepo/data-ui";

interface ModalDetailsSearchUMCtrlProps {
  className?:string;
  OneSearchUM:OneSearchUM;
  idCompare:number;
}

const ModalDetailsSearchUMCtrl = ({className, OneSearchUM, idCompare}: ModalDetailsSearchUMCtrlProps) => {
  const SearchQuery = useGetSearchOne(OneSearchUM.idSearch);
  return (
    <div className={`details_um ${className}`}>
      {SearchQuery.isLoading ? <MbjLoading/> : (!SearchQuery.data || SearchQuery.isError) ? 'ErrorBD':
        <div className="in_details">
          {SearchQuery.data.Member &&
            <div className="block_infos">
              <h2>Informations recruteur</h2>
              <MemberDetailsRecrutement Member={SearchQuery.data.Member}/>
            </div>
          }
          <div className="block_infos">
            <h2>Informations poste</h2>
            <div className="libelle_poste">{SearchQuery.data.UserMetier?.namePerso}</div>
            <div className="wrap_compare">
              <MbjCompareReferentiel idToCompare1={idCompare} idToCompare2={SearchQuery.data.UserMetier?.id||0} typeCompared={"SUM"}/>
            </div>
          </div>
        </div>
      }
    </div>
  )
}

export const ModalDetailsSearchUM = styled(ModalDetailsSearchUMCtrl)`
  padding: 1.5rem;
  height: 70vh;
  overflow-y: auto;
  .libelle_poste{
    font-size: 18px;
    font-weight: bold;
    margin: 15px auto;
  }
`
