import styled from "styled-components";
import { useParams } from "react-router-dom";
import {
  useGetFormateurCompetence,
  useGetOneFormateur,
  useGetRefActvites,
  useGetRefCompetences,
  useGetRefDomaines,
  useGetRefFamilles,
} from "@mbj-front-monorepo/data-access";
import { useMemo } from "react";
import {
  MyActivite,
  MyCompetence,
  MyDomaine,
  MyFamille,
} from "../../../../../components/GestionCompetences/CompetencesInterfaces";
import { CompRooter } from "../../../../../components/GestionCompetences/CompRooter";
import {
  useAddActiviteFormateur,
  useDeleteActiviteFormateur,
  useUpdateNiveauActiviteFormateur,
} from "@mbj-front-monorepo/data-access/src/features/formateurActivite";

interface CompetencesCtrlProps {
  className?: string;
}

const CompetencesCtrl = ({ className }: CompetencesCtrlProps) => {
  const { id } = useParams();
  const FormateurQuery = useGetOneFormateur(parseInt(id as string));
  const queryFormateurCompetences = useGetFormateurCompetence(parseInt(id as string));
  const DomainesQuery = useGetRefDomaines();
  const FamillesQuery = useGetRefFamilles();
  const CompQuery = useGetRefCompetences();
  const ActiQuery = useGetRefActvites();
  const mutationAdd = useAddActiviteFormateur(parseInt(id as string));
  const mutationActivite = useUpdateNiveauActiviteFormateur(parseInt(id as string));
  const mutationDeleteActivite = useDeleteActiviteFormateur(parseInt(id as string));
  const ClickOnNiveau = (idx: number, newNiveau: number, idLine: number) => {
    console.log(idx, newNiveau, idLine);
    if (idLine === 0) {
      mutationAdd.mutate({
        Formateur: "/api/formateurs/" + (id ? parseInt(id) : -1),
        niveau: newNiveau,
        Activite: "/api/activites/" + idx,
      });
    } else {
      mutationActivite.mutate({ id: idLine, niveau: newNiveau });
    }
  };
  const TrashActi = (idx: number) => {
    mutationDeleteActivite.mutate(idx);
  };
  const listDomaines = useMemo(() => {
    const listD: MyDomaine[] = [];
    if (
      queryFormateurCompetences.data &&
      DomainesQuery.data &&
      FamillesQuery.data &&
      CompQuery.data &&
      ActiQuery.data
    ) {
      const Domaines = queryFormateurCompetences.data
        .filter((c) => c.Competence && c.Competence.Famille && c.Competence.Famille.Domaine)
        .map((c) =>
          c.Competence &&
          c.Competence.Famille &&
          c.Competence.Famille.Domaine &&
          c.Competence.Famille.Domaine.id &&
          c.Competence.Famille.Domaine.libelle
            ? c.Competence.Famille.Domaine
            : { id: 0, libelle: "" }
        )
        .filter((d) => d.id !== 0);
      const DomainesSSD = Domaines.reduce(
        (acc: { id: number; libelle: string }[], item) =>
          acc.map((i) => i.id).indexOf(item.id || 0) === -1
            ? [...acc, { id: item.id || 0, libelle: item.libelle || "" }]
            : acc,
        []
      );
      DomainesQuery.data.forEach((d) => {
        const CompsConcerned = queryFormateurCompetences.data.filter(
          (c) =>
            c.Competence &&
            c.Competence.Famille &&
            c.Competence.Famille.Domaine &&
            c.Competence.Famille.Domaine.id === d.id
        );
        const ThisFamille = CompsConcerned.map((c) =>
          c.Competence && c.Competence.Famille
            ? { id: c.Competence.Famille.id || 0, libelle: c.Competence.Famille.libelle || "" }
            : { id: 0, libelle: "" }
        );
        const ThisFamilleSSD = FamillesQuery.data.filter((f) => f.Domaine?.id === d.id);
        const TabFamille: MyFamille[] = [];
        ThisFamilleSSD.forEach((f) => {
          const CompsConcerned = queryFormateurCompetences.data.filter(
            (c) => c.Competence && c.Competence.Famille && c.Competence.Famille && c.Competence.Famille.id === f.id
          );
          const ThisComps = CompsConcerned.map((c) =>
            c.Competence ? { id: c.Competence.id || 0, libelle: c.Competence.libelle || "" } : { id: 0, libelle: "" }
          );
          const ThisCompsSSD = CompQuery.data.filter((c) => c.Famille?.id === f.id);
          const TabCompetences: MyCompetence[] = [];
          ThisCompsSSD.forEach((c) => {
            const myComp = queryFormateurCompetences.data.find((i) => i.Competence?.id === c.id);
            const ActivitesComp = ActiQuery.data.filter((a) => a.Competence?.id === c.id);
            const TabActivite: MyActivite[] = [];
            let niveauTot = 0;
            let niveauTot2 = 0;
            ActivitesComp.forEach((a) => {
              let niveauThis = 0;
              let idPartActi = 0;
              // let isValidate = false;
              if (myComp) {
                const Acti = myComp.FormateurActivites;
                if (Acti) {
                  const myActiPart = Acti.find((act) => act.Activite?.id === a.id);
                  if (myActiPart) {
                    idPartActi = myActiPart.id ? myActiPart.id : 0;
                    niveauThis = myActiPart.niveau || 0;
                    // isValidate = myActiPart.isValidate || false;
                  }
                }
              }
              niveauTot += (niveauThis * (a.duree || 0)) / 4;
              niveauTot2 += niveauThis;
              TabActivite.push({
                id: a.id || 0,
                idObject: idPartActi,
                libelle: a.libelle || "",
                Niveau: niveauThis,
                // isValidate: isValidate,
              });
            });
            TabCompetences.push({
              id: c.id || 0,
              libelle: c.libelle || "",
              Activites: TabActivite.sort((a, b) => (a.Niveau > b.Niveau ? -1 : 1)),
              Niveau: niveauTot / TabActivite.length,
              Niveau2: niveauTot2 / TabActivite.length,
              Niveau3: niveauTot2,
            });
          });
          const TotalNiveau = TabCompetences.reduce((acc: number, item) => acc + item.Niveau, 0);
          TabFamille.push({
            id: f.id || 0,
            libelle: f.libelle || "",
            Competences: TabCompetences.sort((a, b) => (a.Niveau > b.Niveau ? -1 : 1)),
            Niveau: TotalNiveau / TabCompetences.length,
            NiveauTotal: TabCompetences.reduce((acc: number, item) => acc + (item.Niveau3 || 0), 0),
          });
        });
        const TotalNiveauF = TabFamille.reduce((acc: number, item) => acc + item.Niveau, 0);

        listD.push({
          id: d.id || 0,
          libelle: d.libelle || "",
          Familles: TabFamille.sort((a, b) => (a.Niveau > b.Niveau ? -1 : 1)),
          Niveau: TotalNiveauF / TabFamille.length,
          NiveauTotal: TabFamille.reduce((acc: number, item) => acc + (item.NiveauTotal || 0), 0),
        });
      });
    }
    const MaxNiveauDomaine = Math.max(...listD.map((d) => d.Niveau));
    return listD
      .map((d) => {
        return { ...d, Niveau: (d.Niveau / MaxNiveauDomaine) * 5 };
      })
      .sort((a, b) => (a.Niveau > b.Niveau ? -1 : 1));
  }, [queryFormateurCompetences.data, DomainesQuery.data, FamillesQuery.data, CompQuery.data, ActiQuery.data]);

  return (
    <CompRooter
      isEmployeeContext={false}
      ClickNiveau={ClickOnNiveau}
      listDomaines={listDomaines}
      loading={queryFormateurCompetences.isLoading}
      Trash={TrashActi}
      ReadOnly={false}
    />
  );
};

export const Competences = styled(CompetencesCtrl)((props) => ({}));
