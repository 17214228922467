import styled from "styled-components";
import {OneFormationThere} from "../formation";
import {useTrashStagiaireSession} from "@mbj-front-monorepo/data-access";
import {MbjIconButton} from "@mbj-front-monorepo/ui";
import {BsTrash} from "react-icons/bs";

interface FormationFutureCtrlProps {
  className?:string;
  Formation:OneFormationThere;
}

const FormationFutureCtrl = ({className, Formation}: FormationFutureCtrlProps) => {
  const mutationTrash = useTrashStagiaireSession();
  const TrashMe = ()=>{
    if(Formation.id) mutationTrash.mutate(Formation.id)
  }
  return (
    <div className={`wrap_one_formation ${className}`}>
        <div className="infos_form">
          <div className="libelle_formation">{Formation.libelle}</div>
          <div className="dates">du {Formation.startAt ? new Date(Formation.startAt).toLocaleDateString() : ''} au {Formation.endAt ? new Date(Formation.endAt).toLocaleDateString() : ''}</div>
        </div>
      <div className="display_actions">
          <MbjIconButton size={"xs"} icon={<BsTrash/>} themeColor={"warning"} onClick={TrashMe} ariaLabel={"supprimer"}/>
      </div>
    </div>
  )
}

export const FormationFuture = styled(FormationFutureCtrl)`
  display: flex;
  justify-content: space-between;
  .infos_form {
    display: flex;
    justify-content: flex-start;
    gap: 12px;
  }
`
