import styled from "styled-components";
import {useParams} from "react-router-dom";
import {useGetStagiaireRecrutement} from "@mbj-front-monorepo/data-access";
import {MbjLoading, MbjModal2, useModal} from "@mbj-front-monorepo/ui";
import {OneSearchUMCard} from "./OneSearchUMCard";
import {useState} from "react";
import {ModalDetailsSearchUM} from "./ModalDetailsSearchUM";
import {SearchsM} from "../02_SearchsM/SearchsM";

interface SeachsUMCtrlProps {
  className?:string
}
export interface OneSearchUM{
  idSearch:number;
  score:number;
}

const SeachsUMCtrl = (props: SeachsUMCtrlProps) => {
  const {id} = useParams()
  const RecrutementsQuery = useGetStagiaireRecrutement(parseInt(id as string))
  const {isShowing, toggle} = useModal();
  const [mySeachUM, setMySearchUM] = useState<OneSearchUM|null>(null)
  const ClickOne = (idSearch:number, score:number)=>{
    setMySearchUM({idSearch:idSearch, score:score});
    toggle();
  }
  return (
    <div className={`searchs_stagiaire_um ${props.className}`}>
      <p style={{marginBottom:"20px"}}>Les entreprises utilisant My-Better-Job dans votre zone d’emploi recrutent :</p>
      {RecrutementsQuery.isLoading ? <MbjLoading/> : (!RecrutementsQuery.data || RecrutementsQuery.isError) ? 'Error...':
        RecrutementsQuery.data.recrutStagiaireSearch?.length === 0 ?
          <div className={"no_result_recrut"}>Aucun résultat</div>:
        <div className="in_um">
          {RecrutementsQuery.data.recrutStagiaireSearch?.map((item)=>(
            <OneSearchUMCard Recrut={item} key={`SUM${item.id}`} ClickOnIt={()=>ClickOne(item.searchs?.id||0, item.stagiaireProxiUserMetier?.score||0)}/>
            ))}
        </div>
      }
      <SearchsM/>
      <MbjModal2 open={isShowing} closeMe={toggle} titre={"Détails poste à pouvoir"} Wd={"80%"}>
        {mySeachUM &&
          <ModalDetailsSearchUM OneSearchUM={mySeachUM} idCompare={parseInt(id as string)}/>
        }
      </MbjModal2>
    </div>
  )
}

export const SeachsUM = styled(SeachsUMCtrl)`
  padding: 1.5rem 0;
  height: 100%;
  .in_um{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    justify-items: flex-start;
    flex-wrap: wrap;
    overflow-y: auto;
  }
`
