import styled from "styled-components";
import {useParams} from "react-router-dom";
import {useGetFormationsOFMBJ, useGetOFMBJMe} from "@mbj-front-monorepo/data-access";
import React, {useEffect, useMemo, useRef, useState} from "react";
import {BsSearch} from "react-icons/bs";
import {GrClose} from "react-icons/gr";
import {InscriptionOneFormation} from "./PagesInscription/InscriptionOneFormation";

interface InscriptionSessionCtrlProps {
  className?:string;
}
interface ChoiceFormation{
  id:number,
  libelle:string;
}

const InscriptionSessionCtrl = (props: InscriptionSessionCtrlProps) => {
  const {id} = useParams();
  const OFQuery = useGetOFMBJMe();
  const [idOF, setIdOF] = useState<number>(0)
  const FormationsQuery = useGetFormationsOFMBJ(idOF)
  const [FormationCurrent, setFormationCurrent] = useState<ChoiceFormation>({id:0, libelle:'choix'})
  const [search, setSearch] = useState('')
  const refSearch = useRef<HTMLInputElement>(null);
  const handleChangeSearch = ()=>{
    if(refSearch.current) setSearch(refSearch.current.value);
  }
  useEffect(() => {
    if(OFQuery.data) setIdOF(OFQuery.data.id)
  }, [OFQuery.data]);
  const listFiltered = useMemo(()=>{
    const list:ChoiceFormation[] = []
    if(FormationsQuery.data){
      const regex = new RegExp(""+search+"", 'gi');
      FormationsQuery.data.filter(f=>f.libelle && f.libelle.match(regex))
        .sort((a,b)=>(a.libelle||'') > (b.libelle||'') ? 1 : -1)
        .forEach(r=>{
          list.push({
            id:r.id||0,
            libelle:r.libelle||''
          })
        })
    }
    return list;
  }, [search, FormationsQuery.data])
  return (
    <div className={`inscription_session ${props.className}`}>
      <h4>1- Choix de la formation</h4>
      <div className={`wrap_search`}>
        <div className={`search_zone`}>
          <BsSearch className={"icon-search"}/>
          <input type={"text"} value={search} onChange={handleChangeSearch} ref={refSearch}
                 placeholder={"Rechercher une formation"}/>
          {search !== '' &&
            <div className={`round_delete`} onClick={() => setSearch('')}><GrClose/></div>
          }
        </div>
        <div className={`list_zone`}>
          {listFiltered.map((item) => (
            <div className={`item_add`} key={`Om${item.id}`}
                 onClick={() => setFormationCurrent(item)}>
              <span>{item.libelle}</span>
            </div>
          ))}
        </div>
      </div>
      {FormationCurrent.id !== 0 &&
        <div className="wrap_choice_session">
          <h4>2- Choix de la session</h4>
          <InscriptionOneFormation idFormation={FormationCurrent.id} InvalideFormation={()=>setFormationCurrent({id:0, libelle:'choix'})}/>
        </div>
      }
    </div>
  )
}

export const InscriptionSession = styled(InscriptionSessionCtrl)`
  padding: 1rem;
    .wrap_search{
      margin: 12px 0 20px 0;
      .list_zone{
        width: 100%;
        background: white;
        max-height: 250px;
        .item_add{
          padding: 0.25rem 0.5rem;
          &:hover{
            cursor: pointer;
            background: ${props=>props.theme.primaryPastel};
          }
        }
      }
      .search_zone{
        width: 100%;
        background: white;
        border-radius: 4px;
        position: relative;
        display: flex;
        align-items: center;
        padding: 0 0.25rem;
        gap: 6px;
        input{
          flex-grow: 1;
          height: 36px;
          border: none;
        }
        .round_delete{
          position: absolute;
          right: 5px;
          top: 50%;
          transform: translateY(-50%);
          width: 24px;
          height: 24px;
          border-radius: 4px;
          display: flex;
          flex-direction: column;
          justify-items: center;
          svg{
            margin: auto;
          }
          &:hover{
            cursor: pointer;
          }
        }
      }
    }
`
