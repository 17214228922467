import styled from "styled-components";
import {
  useAcceptInvitationOF,
  useDeclineInvitationOF,
  useGetInvitationsOF,
  useGetOFMBJMe
} from "@mbj-front-monorepo/data-access";
import {useEffect, useState} from "react";
import {MbjButton, MbjLoading} from "@mbj-front-monorepo/ui";

interface InvitationsAllianceCtrlProps {
  className?:string;
}

const InvitationsAllianceCtrl = ({className}: InvitationsAllianceCtrlProps) => {
  const OFMEQuery = useGetOFMBJMe();
  const [idOF, setIdOF] = useState<number>(0)
  const InvitationsQuery = useGetInvitationsOF(idOF)
  const mutationAccept = useAcceptInvitationOF();
  const mutationDecline = useDeclineInvitationOF();
  useEffect(() => {
    if(OFMEQuery.data) setIdOF(OFMEQuery.data.id)
  }, [OFMEQuery.data]);
  const AcceptME = (id:number)=>{
    mutationAccept.mutate(id)
  }
  const DeclineMe = (id:number)=>{
    mutationDecline.mutate(id)
  }

  return (
    <div className={`invitations_alliance ${className}`}>
      {InvitationsQuery.isLoading ? <MbjLoading/> : (!InvitationsQuery.data || InvitationsQuery.isError) ? 'ErrorDB' :
        <div className="in_inv">
          {InvitationsQuery.data.filter(i=>!i.declineAt && !i.acceptAt).length === 0 &&
            <div className="no_invi">Aucune invitation pour le moment</div>
          }
          {InvitationsQuery.data.filter(i=>!i.declineAt && !i.acceptAt).map((item)=>(
            <div className="one_invitation" key={`invitation${item.id}`}>
              <div className="date_invit">{item.invitAt ? new Date(item.invitAt).toLocaleDateString() : '--'}</div>
              <div className="content_invitation">
                {item.Alliance?.name} souhaite vous inviter à entrer dans l'alliance
              </div>
              <div className="accept_decline">
                  <MbjButton size={"sm"} themeColor={"warning"} onClick={()=>DeclineMe(item.id||0)}>Décliner</MbjButton>
                  <MbjButton size={"sm"} themeColor={"third"} onClick={()=>AcceptME(item.id||0)}>Accepter</MbjButton>
              </div>
            </div>
            ))}
        </div>
      }
    </div>
  )
}

export const InvitationsAlliance = styled(InvitationsAllianceCtrl)`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 2%;
  .no_invi{
    font-size: 18px;
    font-style: italic;
  }
  .one_invitation{
    background: white;
    padding: 1rem;
    .date_invit{
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 15px;
    }
    .accept_decline{
      display: flex;
      justify-content: flex-end;
      padding: 0.5rem 0;
      gap: 12px;
    }
  }
`
