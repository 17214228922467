import { fetchApi, Operation } from "../../services/fetchApi";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

export const useGetFormationsOFMBJ = (OFId: number | undefined) => {
  const promise = () =>
    fetchApi("/api/formations_ofmbjs", { method: "get", query: { OrganismeFormationMBJ: `${OFId}` } });
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["formations_of_mbj", { OFId }], promise, {
    enabled: !!OFId,
  });
};

export const useGetOneFormationOFMBJ = (id: number) => {
  const promise = () => fetchApi("/api/formations_ofmbjs/{id}", { method: "get", params: { id: id + "" } });
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["formation_of_mbj", { id }], promise, {
    enabled: id !== 0,
  });
};

export const useAddFormationOFMBJ = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: Operation<"postFormationsOfmbjCollection", "requestBody">) => {
      console.log(data);
      return fetchApi("/api/formations_ofmbjs", {
        method: "post",
        json: { ...data },
      });
    },
    {
      onSuccess: (newData) => {
        queryClient.invalidateQueries(["formation_of_mbj"]);
        queryClient.invalidateQueries(["formations_of_mbj"]);
        toast.success("Formation ajoutée !", { autoClose: 2000 });
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
};

export const useUpdateInfoFormation = (idFormation: number | undefined) => {
  const queryClient = useQueryClient();
  return useMutation(
    (data: Operation<"putFormationsOfmbjItem", "requestBody">) => {
      return fetchApi("/api/formations_ofmbjs/{id}", {
        method: "put",
        params: { id: idFormation + "" },
        json: data,
      });
    },
    {
      onSuccess: (newFormation) => {
        queryClient.invalidateQueries(["formations_of_mbj"]);
        queryClient.setQueryData(["formations_of_mbj", newFormation.id], newFormation);
        toast.success("formation modifiée !", {});
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
};

export const useGetCompetencesFormationOFMBJ = (idFormation?: number) => {
  const promise = () =>
    fetchApi("/api/formation_m_b_j_competences", { method: "get", query: { Formation: idFormation || -1 } });
  return useQuery(["competences_formation_of_mbj", { idFormation }], promise, {
    enabled: !!idFormation,
  });
};

export const useUpdateNiveauActiviteFormationOFMBJ = (idFormation?: number) => {
  const queryClient = useQueryClient();
  return useMutation(
    (data: { idformationactivite: number; niveau: number }) => {
      return fetchApi("/api/formations_ofmbj_activites/{id}", {
        method: "put",
        params: { id: data.idformationactivite + "" },
        json: { niveau: data.niveau },
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["formations_of_mbj_activites", { idFormation }]);
        queryClient.invalidateQueries(["competences_formation_of_mbj", { idFormation }]);
        toast.success("Activité modifiée !", {});
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
};

export const useAddActiviteFormationOFMBJ = (idFormation?: number) => {
  const queryClient = useQueryClient();
  return useMutation(
    (data: Operation<"postFormationsOfmbjActiviteCollection", "requestBody">) => {
      return fetchApi("/api/formations_ofmbj_activites", { method: "post", json: data });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["formations_of_mbj_activites", { idFormation }]);
        queryClient.invalidateQueries(["competences_formation_of_mbj", { idFormation }]);
        toast.success("Activité ajoutée !", {});
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
};

export const useDeleteActiviteFormationOFMBJ = (idFormation?: number) => {
  const queryClient = useQueryClient();
  return useMutation(
    (idActivite: number) => {
      return fetchApi("/api/formations_ofmbj_activites/{id}", {
        method: "delete",
        params: { id: idActivite + "" },
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["formations_of_mbj_activites", { idFormation }]);
        queryClient.invalidateQueries(["competences_formation_of_mbj", { idFormation }]);
        toast.success("Activité supprimée !", {});
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
};
