import styled from "styled-components";
import {GetElementType} from "@mbj-front-monorepo/utils";
import {Operation} from "@mbj-front-monorepo/data-access";
import {useState} from "react";
import {FiChevronDown} from "react-icons/fi";
import {AvatarFact} from "@mbj-front-monorepo/ui";
import {MemberAllianceCard} from "./MemberAllianceCard";

interface OneAllianceCardCtrlProps {
  className?:string;
  ItemAlliance:GetElementType<Operation<"getOFAllianceCollection">>
}

const OneAllianceCardCtrl = ({className, ItemAlliance}: OneAllianceCardCtrlProps) => {
  const [open, setOpen] = useState<boolean>(true)
  const SwitchOpen = ()=>{
    setOpen(o=>!o);
  }
  return (
    <div className={`one_card_alliance_of ${className}`}>
      <div className={`libelleAlliance ${open ? 'opened' : 'closed'}`} onClick={SwitchOpen}>
        <div className="lib">
          {ItemAlliance.Alliance?.name}
        </div>
        <div className="wrap_chevron">
          <FiChevronDown />
        </div>
      </div>
      {open &&
        <div className="in_alliance">
          {ItemAlliance.Members && ItemAlliance.Members.length>0 &&
            <div className="block_in_alliance">
              <div className="titre_block_in_alliance">Les entreprises</div>
              <div className="main_block_in_alliance">
                {ItemAlliance.Members.map(item=>(
                  <MemberAllianceCard  key={`M${item.id}`} description={item.description} logo={item.logo} name={item.name}/>
                ))}

              </div>
            </div>
          }
          {ItemAlliance.OrganismeFormationMBJS && ItemAlliance.OrganismeFormationMBJS.length>0 &&
            <div className="block_in_alliance">
              <div className="titre_block_in_alliance">Les organismes de formation</div>
              <div className="main_block_in_alliance">
                {ItemAlliance.OrganismeFormationMBJS.map(item=>(
                  <MemberAllianceCard  key={`OF${item.id}`} description={item.description} logo={item.logo} name={item.name}/>
                ))}

              </div>
            </div>
          }
        </div>
      }
    </div>
  )
}

export const OneAllianceCard = styled(OneAllianceCardCtrl)`
  background: white;
  .main_block_in_alliance{
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  .libelleAlliance{
    &:hover{
      cursor: pointer;
      .lib{
        font-weight: bold;
      }
    }
    .lib{
      font-size: 17px;
    }
    padding: 0.25rem 0.5rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
    .wrap_chevron{
      display: flex;
      flex-direction: column;
      justify-content: center;
      transform: rotate(0deg);
      transition: transform 0.3s;
    }
    &.opened{
      .wrap_chevron{
        transform: rotate(180deg);
        transition: transform 0.3s;
      }

      background: ${props => props.theme.primary};
      color:white;

    }
  }
  .block_in_alliance{
    margin-bottom: 20px;
    .titre_block_in_alliance{
      font-size: 18px;
      border-bottom: solid black 2px;
      padding: 0.25rem;
    }
  }
`
