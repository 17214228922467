import React from "react";
import { MbjCardClassic, MbjLoading, MbjWidgetClassic } from "@mbj-front-monorepo/ui";
import styled from "styled-components";
import {Operation, useGetMetrixMetier, useGetMetrixMetierEvaluate} from "@mbj-front-monorepo/data-access";

interface MetrixMetierCtrlProps {
  className?: string;
  Metier: Operation<"getMetierItem">;
  idOF?:number;
}

const MetrixMetierCtrl = ({ className, Metier, idOF }: MetrixMetierCtrlProps) => {
  const metrixQuery = useGetMetrixMetierEvaluate(Metier.id || -1, undefined, idOF);
  const YearToday = new Date().getFullYear();
  return (
    <div className={`wrapMetrix ${className}`}>
      <MbjWidgetClassic title={"Information sur le métier"}>
        {metrixQuery.isLoading ? (
          <MbjLoading />
        ) : metrixQuery.isError ? (
          <p>Erreur ...</p>
        ) : (
          <>
            <MbjCardClassic title="Salaire" basis={"24%"}>
              <div className="centerCard">{metrixQuery.data?.salaire}€</div>
            </MbjCardClassic>
            <MbjCardClassic title="Code Rome" basis={"24%"}>
              <div className="centerCard">{Metier.codeRome}</div>
            </MbjCardClassic>
            <MbjCardClassic title="Champ professionnel" basis={"24%"}>
              <div className="centerCard">{Metier.champPro}</div>
            </MbjCardClassic>
            <MbjCardClassic title="Fonction" basis={"24%"}>
              <div className="centerCard">{Metier.fonctions}</div>
            </MbjCardClassic>
          </>
        )}
      </MbjWidgetClassic>
      <MbjWidgetClassic title={"Situation du marché du travail"}>
        {metrixQuery.isLoading ? (
          <MbjLoading />
        ) : metrixQuery.isError ? (
          <p>Erreur ...</p>
        ) : (
          <>
            <MbjCardClassic title="Equilibre National" basis={"24%"}>
              <div
                className="centerCard"
                style={{
                  color: `${
                    metrixQuery.data?.InformationNationale?.chomage === "fort"
                      ? "red"
                      : metrixQuery.data?.InformationNationale?.chomage === "moyen"
                      ? "orange"
                      : "green"
                  }`,
                }}
              >
                Chômage {metrixQuery.data?.InformationNationale?.chomage}
              </div>
            </MbjCardClassic>
            <MbjCardClassic title="Equilibre Local" basis={"24%"}>
              <div
                className="centerCard"
                style={{
                  color: `${
                    metrixQuery.data?.InformationLocale?.chomage === "fort"
                      ? "red"
                      : metrixQuery.data?.InformationLocale?.chomage === "moyen"
                      ? "orange"
                      : "green"
                  }`,
                }}
              >
                Chômage {metrixQuery.data?.InformationLocale?.chomage}
              </div>
            </MbjCardClassic>
            <MbjCardClassic title={`Recrutements prévu en ${YearToday}`} basis={"24%"}>
              <div className="centerCard">{metrixQuery.data?.r1}</div>
            </MbjCardClassic>
            <MbjCardClassic title={`Recrutements prévu en ${YearToday + 1}`} basis={"24%"}>
              <div className="centerCard">{metrixQuery.data?.r2}</div>
            </MbjCardClassic>
          </>
        )}
      </MbjWidgetClassic>
    </div>
  );
};
export const MetrixMetier = styled(MetrixMetierCtrl)((props) => ({
  display:"flex",
  flexDirection:"column",
  gap:"24px",
  ".centerCard":{
    textAlign:"center",
    padding:"1rem",
    fontSize:"16px"
  }
}));
