import {fetchApi, Operation} from "../../services/fetchApi";
import {useMutation, useQuery, useQueryClient, UseQueryResult} from "@tanstack/react-query";
import {toast} from "react-toastify";


export const useGetStagiaireSession = (idSession: number | undefined) => {
  const promise = () => fetchApi("/api/stagiaire_sessions", { method: "get", query: { Session: idSession + "" } });
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["stagiaires_session", { idSession }], promise, {
    enabled: !!idSession,
  });
};

export const useGetAllStagiaireSession = () => {
  const promise = () => fetchApi("/api/stagiaire_sessions", { method: "get"});
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["stagiaires_session_all",], promise, {
  });
};

export const useGetStagiaireSessionByStagiaire = (idStagiaire:number)=>{
  const promise = () => fetchApi("/api/stagiaire_sessions", { method: "get", query: { Stagiaire: idStagiaire + "" } });
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["stagiaires_session_stagiaire", { idStagiaire }], promise, {
    enabled: !!idStagiaire,
  });
}

export const useGetFormationsByStagaire = (id:number)=>{
  const promise = ()=> fetchApi("/api/formations_stagiaires/one_stagiaire", {method:'get', query:{Stagiaire:id}})
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["formations_stagiaires", { id }], promise, {
    enabled: id!==0,
  });
}

export const useValidateSession = ()=>{
  const queryClient = useQueryClient();
  return useMutation(
    (idSession:number)=>{
      return fetchApi("/api/stagiaire_sessions/{id}/validate", {method:"post", params:{id:idSession+""}})
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["stagiaires_session_stagiaire"]);
        queryClient.invalidateQueries(["stagiaires_session"]);
        queryClient.invalidateQueries(["formations_stagiaires"]);
        toast.success("Session validée ! !", { autoClose: 2000 });
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  )
}

export const useAddStagiaireSession = ()=>{
  const queryClient = useQueryClient();

  return useMutation(
    (data: Operation<"postStagiaireSessionCollection", "requestBody">) => {
      return fetchApi("/api/stagiaire_sessions", { method: "post", json: data });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["stagiaires_session_stagiaire"]);
        queryClient.invalidateQueries(["stagiaires_session"]);
        queryClient.invalidateQueries(["formations_stagiaires"]);
        toast.success("Inscription à la session ajoutée !", { autoClose: 2000 });
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
}

export const useTrashStagiaireSession = ()=>{
  const queryClient = useQueryClient();
  return useMutation(
    (id: number) => {
      return fetchApi("/api/stagiaire_sessions/{id}", { method: "delete", params: { id: id + "" } });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["stagiaires_session_stagiaire"]);
        queryClient.invalidateQueries(["stagiaires_session"]);
        queryClient.invalidateQueries(["formations_stagiaires"]);
        toast.success("session supprimée", {});
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
}


