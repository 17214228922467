import styled from "styled-components";
import { useParams, useSearchParams } from "react-router-dom";
import { useGetSessionFormationsOFMBJ } from "@mbj-front-monorepo/data-access";

import { MbjButton, MbjFlex, MbjIconButton, MbjLoading, MbjModal2, MbjTableV2, useModal } from "@mbj-front-monorepo/ui";
import { ModalNewSession } from "./components/ModalNewSession";
import {
  createColumnHelper,
  getCoreRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { getDateFR, GetElementType } from "@mbj-front-monorepo/utils";
import * as React from "react";
import { FaPen } from "react-icons/fa";
import { UpdateSession } from "./components/UpdateSession";

interface FormationSessionsCtrlProps {
  className?: string;
}

const FormationSessionsCtrl = ({ className }: FormationSessionsCtrlProps) => {
  const { id } = useParams();
  const SessionsQuery = useGetSessionFormationsOFMBJ(id ? parseInt(id) : 0);
  const { isShowing, toggle } = useModal();
  const { isShowing: updateSessionIsShowing, toggle: updateSessionToggle } = useModal();
  const [searchParams, setSearchParams] = useSearchParams();

  const ClickAdd = () => {
    toggle();
  };

  const columnHelper = createColumnHelper<GetElementType<typeof SessionsQuery.data>>();
  const columns = [
    columnHelper.accessor((row) => (row.startAt ? getDateFR(new Date(row.startAt)) : "--"), {
      header: "Date de début",
    }),
    columnHelper.accessor((row) => (row.endAt ? getDateFR(new Date(row.endAt)) : "--"), {
      header: "Date de fin",
    }),
    columnHelper.accessor((row) => row.StagiaireSessions?.length, {
      header: "Nombre d'apprenants",
    }),
    columnHelper.display({
      id: "actions",
      cell: (props) => (
        <MbjFlex justify={"center"}>
          <MbjIconButton
            icon={<FaPen />}
            size={"sm"}
            ariaLabel={"Modifier la session correspondante"}
            onClick={() => {
              console.log(props.row.original.startAt);
              updateSessionToggle();
              setSearchParams({ idSession: props.row.original.id + "" });
              return;
            }}
          />
        </MbjFlex>
      ),
    }),
  ];

  const table = useReactTable({
    data: SessionsQuery?.data ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    debugTable: false,
    initialState: {
      sorting: [{ id: "Date de début", desc: true }],
    },
  });

  return (
    <div className={`formation_competence ${className}`}>
      <div className="wrap_actions">
        <MbjButton size={"sm"} onClick={ClickAdd} themeColor={"third"}>
          Ajouter une session de formation
        </MbjButton>
      </div>
      {SessionsQuery.isLoading ? (
        <MbjLoading />
      ) : SessionsQuery.data?.length === 0 ? (
        <div className="no_session">Aucune Session de formation pour le moment</div>
      ) : (
        <div className={"in_sessions"}>
          <MbjTableV2 table={table} Multiple={true} />
        </div>
      )}
      <MbjModal2 open={isShowing} closeMe={toggle} titre={"Nouvelle session"} Wd={"500px"}>
        <ModalNewSession closeMe={toggle} idFormation={parseInt(id || "0")} />
      </MbjModal2>
      <MbjModal2
        open={updateSessionIsShowing}
        closeMe={() => {
          setSearchParams("");
          updateSessionToggle();
        }}
        titre={"Modification de la session"}
        Wd={"500px"}
      >
        <UpdateSession />
      </MbjModal2>
    </div>
  );
};

export const FormationSessions = styled(FormationSessionsCtrl)`
  padding: 1% 2%;
  .wrap_actions {
    margin-bottom: 15px;
    display: flex;
    justify-content: flex-end;
  }
  .no_session {
    font-size: 18px;
    font-style: italic;
  }
  .line_wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    gap: 16px;
    background: white;
    padding: 0.5rem 1rem;
    margin-bottom: 4px;
    border-radius: 4px;
    &.is-head {
      font-weight: bold;
    }
    .is-start,
    .is-end,
    .is-nb-stagiaire {
      width: 150px;
    }
  }
`;
