import styled from "styled-components";
import {useParams} from "react-router-dom";
import {
  ItemMenuChoixOnPage,
  MbjButton, MbjCardClassic, MbjFlex,
  MbjLoading,
  MbjMenuChoixOnPage, MbjModal, MbjNoResult,
  MbjWidgetClassic,
  useModal
} from "@mbj-front-monorepo/ui";
import {useGetOneStagiaire, useGetStagiaireFi, useGetStagiaireXp} from "@mbj-front-monorepo/data-access";
import {useEffect, useState} from "react";
import * as React from "react";
import {GoPlus} from "react-icons/go";
import {
  MbjStagiaireFiCard, MbjStagiaireFiNewForm,
  MbjStagiaireXpCard, MbjStagiaireXpNewForm
} from "@mbj-front-monorepo/data-ui";

interface OneApprenantCVCtrlProps {
  className?:string;
}
const Menus:ItemMenuChoixOnPage[] = [
  {id:0, libelle:'Expériences professionnelles'},
  {id:1, libelle:'Formations Initiales'},
]

const OneApprenantCVCtrl = ({className}: OneApprenantCVCtrlProps) => {
  const { id } = useParams();
  const { isShowing: showNewXp, toggle: toggleNewXp } = useModal();
  const { isShowing: showNewFi, toggle: toggleNewFi } = useModal();
  const StagiaireQuery = useGetOneStagiaire(id ? parseInt(id) : 0)
  const [MenuCurrent, setMenuCurrent] = useState<ItemMenuChoixOnPage>({id:0, libelle:'Expériences professionnelles'})
  const xpQuery = useGetStagiaireXp(parseInt(id as string))
  const FiQuery = useGetStagiaireFi(parseInt(id as string))
  return (
    <div className={`one_apprenant_cv ${className}`}>
      {StagiaireQuery.isLoading ? (
        <MbjLoading />
      ) : StagiaireQuery.isError ? (
        <p>Erreur ...</p>
      ) : (
        <>
        <MbjMenuChoixOnPage current={MenuCurrent} setChoix={setMenuCurrent} Items={Menus}/>
        {MenuCurrent.id === 0 ?

          <MbjWidgetClassic bodyDirection={"column"} basis={"45%"}>
            <div style={{display: "flex", justifyContent: "flex-end", gap: "10px"}}>
              <MbjButton onClick={toggleNewXp} themeColor={"third"} leftIcon={<GoPlus/>}>
                Ajouter une expérience professionnelle
              </MbjButton>
            </div>
            {xpQuery.data && xpQuery.data?.length > 0 ? (
              <MbjFlex direction={"column"}>
                {xpQuery.isLoading ? (
                  <MbjLoading />
                ) : xpQuery.isError ? (
                  <p>Error...</p>
                ) : (
                  xpQuery.data?.map((xp) => <MbjStagiaireXpCard StagiaireXp={xp} key={xp.id}/>)
                )}
              </MbjFlex>
            ) : (
              <MbjCardClassic>
                <div style={{padding:"20px"}}>
                  <MbjNoResult text={"Aucune expérience professionnelle"} />
                </div>
              </MbjCardClassic>
            )}
          </MbjWidgetClassic> :
          <MbjWidgetClassic bodyDirection={"column"} basis={"45%"}>
            <div style={{display: "flex", justifyContent: "flex-end", gap: "10px"}}>
              <MbjButton onClick={toggleNewFi} themeColor={"third"} leftIcon={<GoPlus/>}>
                Ajouter une formation initiale
              </MbjButton>
            </div>
            {FiQuery.data && FiQuery.data?.length > 0 ? (
              <MbjFlex direction={"column"} gap={"20px"}>
                {FiQuery.isLoading ? (
                  <MbjLoading />
                ) : FiQuery.isError ? (
                  <p>Error...</p>
                ) : (
                  FiQuery.data?.map((fi, idx: number) => <MbjStagiaireFiCard StagiaireFi={fi} key={`${fi.id}_${idx}`}/>)
                )}
              </MbjFlex>
            ) : (
              <MbjCardClassic>
                <div style={{padding:"20px"}}>
                  <MbjNoResult text={"Aucune formation initiale"} />
                </div>
              </MbjCardClassic>
            )}
          </MbjWidgetClassic>
        }
        </>
      )}
      {id && (
        <>

          <MbjModal isShowing={showNewXp} hide={toggleNewXp} fitContent title="Nouvelle expérience professionnelle">
            <MbjStagiaireXpNewForm idStagiaire={parseInt(id.toString())} hideModal={toggleNewXp} />
          </MbjModal>
          <MbjModal isShowing={showNewFi} hide={toggleNewFi} fitContent title="Nouvelle formation initiale">
            <MbjStagiaireFiNewForm idStagiaire={parseInt(id.toString())} hideModal={toggleNewFi} />
          </MbjModal>
        </>
      )}
    </div>
  )
}

export const OneApprenantCV = styled(OneApprenantCVCtrl)`
  padding: 2%;
`
