import styled from "styled-components";
import {useParams} from "react-router-dom";
import {useGetConsultProxiMetierStagiaire} from "@mbj-front-monorepo/data-access";
import {MbjCardBack, MbjListItem, MbjScrollArea, MbjSearchBar} from "@mbj-front-monorepo/ui";
import {MbjMobiliteListItem} from "@mbj-front-monorepo/data-ui";
import * as React from "react";
import {useMemo} from "react";

interface ConsultDeboucheCtrlProps {
  className?:string;
  setIdMetier:(id:number, idProxi:number)=>void;
}
interface OneMob{ id:number, score:number,  Metier:{id:number, libelle:string, salaire?:number}}
const ConsultDeboucheCtrl = ({className, setIdMetier}: ConsultDeboucheCtrlProps) => {
  const {id} = useParams()
  const ConsultQuery = useGetConsultProxiMetierStagiaire(parseInt(id as string))
  const listMobiliteFiltered = useMemo(()=>{
    const list:OneMob[] = []
    if(ConsultQuery.data){
      ConsultQuery.data.forEach(item=>{
        const alR = list.map(l=>l.Metier.id)
        if(alR.indexOf(item.StagiaireProxiMetier?.Metier?.id||0)===-1) {
          list.push({
            id: item.id || 0,
            score: item.StagiaireProxiMetier?.score || 0,
            Metier: {
              id: item.StagiaireProxiMetier?.Metier?.id || 0,
              libelle: item.StagiaireProxiMetier?.Metier?.libelle || '',
              salaire: item.StagiaireProxiMetier?.Metier?.salaire
            }
          })
        }
      })
    }
    return list;
  }, [ConsultQuery.data])
  return (
    <MbjCardBack title={"Dernières consultations"}>
      <div className={`all_mob ${className}`}>
        <MbjScrollArea grow={1}>
          {
            listMobiliteFiltered
              .map((mobilite, idx: number) => (
                <MbjListItem key={mobilite.id} onClick={()=>setIdMetier(mobilite.Metier?.id||0, mobilite.id||0)}>
                  <MbjMobiliteListItem mobilite={mobilite} className="in-line" />
                </MbjListItem>
              ))
          }
        </MbjScrollArea>
        {listMobiliteFiltered.length === 0 &&
          <p>Aucune consultation pour le moment</p>
        }
      </div>
    </MbjCardBack>
  )
}

export const ConsultDebouche = styled(ConsultDeboucheCtrl)`
  height: 500px;
  display: flex;
  flex-direction: column;
`
