import styled from "styled-components";
import { useDeleteFormateur, useGetFormateurs, useGetOFMBJMe } from "@mbj-front-monorepo/data-access";
import {
  createSelectColumn,
  MbjButton,
  MbjConfirmModal,
  MbjErrorData,
  MbjFlex,
  MbjLink,
  MbjLoading,
  MbjModal2,
  MbjNoResult,
  MbjScrollArea,
  MbjTableV2,
  useModal,
} from "@mbj-front-monorepo/ui";
import {
  createColumnHelper,
  getCoreRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { GetElementType } from "@mbj-front-monorepo/utils";
import * as React from "react";
import { ModalNewFormateur } from "./ModalNewFormateur";

interface MesFormateursCtrlProps {
  className?: string;
}

const MesFormateursCtrl = (props: MesFormateursCtrlProps) => {
  const meQuery = useGetOFMBJMe();
  const formateursQuery = useGetFormateurs(meQuery.data?.id);
  const deleteFormateurMutation = useDeleteFormateur();
  const { isShowing, toggle } = useModal();
  const { isShowing: isShowingFormateur, toggle: toggleDeleteFormateur } = useModal();

  const columnHelper = createColumnHelper<GetElementType<typeof formateursQuery.data>>();
  const columns = [
    createSelectColumn(columnHelper),
    columnHelper.accessor((row) => `${row.nom?.toUpperCase()} ${row.prenom}`, {
      header: "Identité",
      cell: (info) => <MbjLink to={"../" + info.row.original.id?.toString() || "0"}>{info.getValue()}</MbjLink>,
    }),
    columnHelper.accessor((row) => row.statut, {
      header: "Statut",
    }),
    columnHelper.accessor((row) => row.mail, {
      header: "Mail",
    }),
  ];

  const table = useReactTable({
    data: formateursQuery?.data ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    debugTable: false,
    initialState: {
      sorting: [{ id: "Identité", desc: false }],
    },
  });

  if (formateursQuery.isLoading) {
    return <MbjLoading />;
  }

  if (formateursQuery.isError) {
    return <MbjErrorData />;
  }

  return (
    <MbjFlex className={props.className} direction={"column"} wrap={"nowrap"}>
      <MbjFlex justify={"end"}>
        {(table.getIsSomeRowsSelected() || table.getIsAllRowsSelected()) && (
          <MbjButton themeColor={"warning"} onClick={toggleDeleteFormateur} isPending={false}>
            Supprimer {table.getSelectedRowModel().rows.length} formateur(s)
          </MbjButton>
        )}
        <MbjButton
          type="button"
          onClick={() => {
            toggle();
          }}
          themeColor={"primary"}
        >
          + Ajouter un formateur
        </MbjButton>
      </MbjFlex>
      {formateursQuery.data.length === 0 ? (
        <MbjNoResult text={"Aucun formateur trouvé. Commencez à les ajouter en cliquant sur le bouton au dessus"} />
      ) : (
        <MbjScrollArea overflowX={"auto"}>
          <MbjTableV2 table={table} Multiple={true} />
        </MbjScrollArea>
      )}
      <MbjModal2 open={isShowing} closeMe={toggle} titre={"Nouveau formateur"} Wd={"40%"}>
        <ModalNewFormateur organismeId={meQuery.data.id} />
      </MbjModal2>
      <MbjConfirmModal
        isShowing={isShowingFormateur}
        hide={toggleDeleteFormateur}
        title="Confirmation de suppression de formateur(s)"
        confirmText={`Etes-vous sûr de vouloir supprimer ${table.getSelectedRowModel().rows.length} formateur(s) ?`}
        confirmFunction={() => {
          deleteFormateurMutation.mutate(
            table.getSelectedRowModel().rows.map((row) => row.original.id || 0),
            {
              onSuccess: () => {
                toggleDeleteFormateur();
                table.toggleAllRowsSelected(false);
              },
            }
          );
        }}
        isLoading={deleteFormateurMutation.isLoading}
      />
    </MbjFlex>
  );
};

export const MesFormateurs = styled(MesFormateursCtrl)((props) => ({
  padding: "1% 2%",
  height: "100%",
}));
