import styled from "styled-components";
import { useDeleteStagiaire, useGetOFMBJMe, useGetStagiaireByOF } from "@mbj-front-monorepo/data-access";
import * as React from "react";
import { useEffect, useState } from "react";
import {
  createColumnHelper,
  getCoreRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { GetElementType } from "@mbj-front-monorepo/utils";
import {
  createSelectColumn,
  MbjButton,
  MbjConfirmModal,
  MbjFlex,
  MbjHeading,
  MbjLink,
  MbjLoading,
  MbjModal2,
  MbjScrollArea,
  MbjTableV2,
  MbjWidgetClassic,
  useModal,
} from "@mbj-front-monorepo/ui";
import { GoPlus } from "react-icons/go";
import { ModalNewApprenant } from "./ModalNewApprenant";

interface AllApprenantCtrlProps {
  className?: string;
}

const AllApprenantCtrl = ({ className }: AllApprenantCtrlProps) => {
  const OFQuery = useGetOFMBJMe();
  const [idOF, setIdOF] = useState<number>(0);
  const StagiairesQuery = useGetStagiaireByOF(idOF);
  const { isShowing: isShowingDeleteApprenant, toggle: toggleDeleteApprenant } = useModal();
  const { isShowing: isShowingNewApprenant, toggle: toggleNewApprenant } = useModal();
  const delteStagiaireMutation = useDeleteStagiaire();
  useEffect(() => {
    if (OFQuery.data) {
      setIdOF(OFQuery.data.id);
    }
  }, [OFQuery.data]);
  const columnHelper = createColumnHelper<GetElementType<typeof StagiairesQuery.data>>();
  const columns = [
    createSelectColumn(columnHelper),
    columnHelper.group({
      header: "Identité",
      columns: [
        columnHelper.accessor((row) => row.nom, {
          header: "Nom",
          cell: (info) => <MbjLink to={"../../" + info.row.original.id?.toString() || "0"}>{info.getValue()}</MbjLink>,
        }),
        columnHelper.accessor((row) => row.prenom, {
          header: "Prénom",
        }),
      ],
    }),
    columnHelper.group({
      header: "Email et téléphone",
      columns: [
        columnHelper.accessor((row) => row.email || "--", {
          header: "Email",
        }),
        columnHelper.accessor((row) => row.telephone || "--", {
          header: "Téléphone",
        }),
      ],
    }),
    columnHelper.group({
      header: "Informations formation",
      columns: [
        columnHelper.accessor((row) => row.matricule || "--", {
          header: "Identifiant",
        }),
      ],
    }),
    columnHelper.accessor((row) => row.StatutPro?.libelle, {
      header: "Statut",
    }),
  ];

  const table = useReactTable({
    data: StagiairesQuery?.data ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    debugTable: false,
    initialState: {
      sorting: [{ id: "Nom", desc: false }],
    },
    sortingFns: {
      MySortPrenom: (rowA: any, rowB: any, columnId: any): number =>
        rowA.getValue(columnId).value.localeCompare(rowB.getValue(columnId).value),
      //rowA.getValue(columnId).value < rowB.getValue(columnId).value ? 1 : -1,
    },
  });

  return (
    <div className={`all_apprenants ${className}`}>
      <MbjWidgetClassic bodyGrow={1} grow={1} bodyDirection={"column"} bodyNoWrap>
        {StagiairesQuery.isLoading ? (
          <MbjLoading />
        ) : StagiairesQuery.isError ? (
          <p>Error !</p>
        ) : (
          <>
            <MbjFlex justify="space-between">
              <MbjHeading>{table.getRowModel().rows.length} résultats</MbjHeading>
              <div>
                {(table.getIsSomeRowsSelected() || table.getIsAllRowsSelected()) && (
                  <MbjButton themeColor={"warning"} onClick={toggleDeleteApprenant} isPending={false}>
                    Supprimer {table.getSelectedRowModel().rows.length} apprenant(s)
                  </MbjButton>
                )}
                <MbjButton onClick={toggleNewApprenant} leftIcon={<GoPlus />} alignSelf={"flex-end"}>
                  Ajouter un apprenant
                </MbjButton>
              </div>
            </MbjFlex>
            <MbjScrollArea overflowX={"auto"}>
              <MbjTableV2 table={table} Multiple={true} />
            </MbjScrollArea>
          </>
        )}
      </MbjWidgetClassic>
      <MbjModal2 open={isShowingNewApprenant} closeMe={toggleNewApprenant} titre={"Nouvel apprenant"} Wd={"40%"}>
        <ModalNewApprenant organismeId={OFQuery.data.id} />
      </MbjModal2>
      <MbjConfirmModal
        isShowing={isShowingDeleteApprenant}
        hide={toggleDeleteApprenant}
        title="Confirmation de suppression de apprenant(s)"
        confirmText={`Etes-vous sûr de vouloir supprimer ${table.getSelectedRowModel().rows.length} apprenant(s) ?`}
        confirmFunction={() => {
          delteStagiaireMutation.mutate(
            table.getSelectedRowModel().rows.map((row) => row.original.id || 0),
            {
              onSuccess: () => {
                toggleDeleteApprenant();
                table.toggleAllRowsSelected(false);
              },
            }
          );
        }}
        isLoading={delteStagiaireMutation.isLoading}
      />
    </div>
  );
};

export const AllApprenant = styled(AllApprenantCtrl)((props) => ({
  display: "flex",
  justifyContent: "flex-start",
  flexDirection: "column",
  gap: "20px",
  height: "100%",
}));
