import styled from "styled-components";
import { MbjButton, MbjFlex, MbjSimpleInput, MbjSimpleInputContainer } from "@mbj-front-monorepo/ui";
import * as React from "react";
import { useRef } from "react";
import { Operation, useMutateStagiaire } from "@mbj-front-monorepo/data-access";
import { Controller, useForm } from "react-hook-form";
import Select, { SelectInstance } from "react-select";
import { useGetStatutPros } from "@mbj-front-monorepo/data-access/src/features/stagiaire/statutPro.hooks";

interface InformationsStagiaireCardCtrlProps {
  className?: string;
  stagiaire: Operation<"getStagiaireItem">;
}

const InformationsStagiaireCardCtrl = (props: InformationsStagiaireCardCtrlProps) => {
  const mutation = useMutateStagiaire(props.stagiaire.id || -1);
  const selectRef = useRef<SelectInstance<{ id?: number; libelle?: string } | null>>(null);
  const StatutProQuery = useGetStatutPros();

  const methods = useForm({
    mode: "onChange",
    defaultValues: {
      nom: props.stagiaire.nom,
      prenom: props.stagiaire.prenom,
      matricule: props.stagiaire.matricule,
      adresse: props.stagiaire.adresse,
      codePostal: props.stagiaire.codePostal,
      ville: props.stagiaire.ville,
      email: props.stagiaire.email,
      StatutPro: props.stagiaire.StatutPro,
      telephone: props.stagiaire.telephone,
    },
  });

  return (
    <div className={`infos_stagiaire ${props.className}`}>
      <form
        className={props.className}
        onSubmit={methods.handleSubmit((data) => {
          const newData = { ...data, StatutPro: `/api/statut_pros/${data.StatutPro?.id}` };
          console.log(newData);
          mutation.mutate(newData);
        })}
      >
        <MbjFlex direction={"column"} gap={"18px"}>
          <MbjSimpleInputContainer errors={methods.formState.errors} label={"Statut *"}>
            <Controller
              name={"StatutPro"}
              control={methods.control}
              rules={{ required: { value: true, message: "Statut requis" } }}
              render={({ field }) => (
                <Select
                  ref={selectRef}
                  inputId={"statutPro"}
                  isLoading={StatutProQuery.isLoading}
                  options={StatutProQuery.data}
                  getOptionLabel={(statut) => statut?.libelle || ""}
                  getOptionValue={(statut) => statut?.id + ""}
                  isClearable={true}
                  defaultValue={field.value}
                  onChange={(selectedOption, triggeredAction) => {
                    if (selectedOption) {
                      field.onChange(selectedOption);
                    }
                    if (triggeredAction.action === "clear") {
                      field.onChange(null);
                    }
                  }}
                />
              )}
            />
          </MbjSimpleInputContainer>
          <MbjFlex direction={"row"} gap={"10px"}>
            <MbjSimpleInputContainer errors={methods.formState.errors} label={"Nom *"} flex={"1 1 45%"}>
              <MbjSimpleInput
                name={"nom"}
                id={"nom"}
                type={"text"}
                noPadding
                register={methods.register}
                registerOptions={{ required: { value: true, message: "Nom requis" } }}
              />
            </MbjSimpleInputContainer>
            <MbjSimpleInputContainer errors={methods.formState.errors} label={"Prénom *"} flex={"1 1 45%"}>
              <MbjSimpleInput
                name={"prenom"}
                id={"prenom"}
                type={"text"}
                noPadding
                register={methods.register}
                registerOptions={{ required: { value: true, message: "Prénom requis" } }}
              />
            </MbjSimpleInputContainer>
          </MbjFlex>
          <MbjFlex direction={"row"} gap={"10px"}>
            <MbjSimpleInputContainer errors={methods.formState.errors} label={"Matricule"} flex={"1 1 45%"}>
              <MbjSimpleInput
                name={"matricule"}
                id={"matricule"}
                type={"matricule"}
                noPadding
                register={methods.register}
              />
            </MbjSimpleInputContainer>
            <MbjSimpleInputContainer errors={methods.formState.errors} label={"Téléphone"} flex={"1 1 45%"}>
              <MbjSimpleInput
                name={"telephone"}
                id={"telephone"}
                type={"telephone"}
                noPadding
                register={methods.register}
              />
            </MbjSimpleInputContainer>
          </MbjFlex>
          <MbjSimpleInputContainer errors={methods.formState.errors} label={"Adresse"}>
            <MbjSimpleInput name={"adresse"} id={"adresse"} type={"adresse"} noPadding register={methods.register} />
          </MbjSimpleInputContainer>
          <MbjSimpleInputContainer errors={methods.formState.errors} label={"Adresse mail"}>
            <MbjSimpleInput name={"email"} id={"email"} type={"email"} noPadding register={methods.register} />
          </MbjSimpleInputContainer>
          <MbjFlex direction={"row"} gap={"10px"}>
            <MbjSimpleInputContainer errors={methods.formState.errors} label={"Code postal *"} flex={"1 1 48%"}>
              <MbjSimpleInput
                name={"codePostal"}
                id={"codePostal"}
                type={"codePostal"}
                noPadding
                register={methods.register}
                registerOptions={{ required: { value: true, message: "Code postal requis" } }}
              />
            </MbjSimpleInputContainer>
            <MbjSimpleInputContainer errors={methods.formState.errors} label={"Ville *"} flex={"1 1 47%"}>
              <MbjSimpleInput
                name={"ville"}
                id={"ville"}
                type={"ville"}
                noPadding
                register={methods.register}
                registerOptions={{ required: { value: true, message: "Ville requise" } }}
              />
            </MbjSimpleInputContainer>
          </MbjFlex>

          <MbjFlex justify={"flex-end"}>
            {methods.formState.isDirty && (
              <MbjButton
                size={"md"}
                type="button"
                onClick={() => {
                  methods.reset(props.stagiaire);
                  selectRef.current?.selectOption({
                    libelle: props.stagiaire.StatutPro?.libelle,
                    id: props.stagiaire.StatutPro?.id,
                  });
                }}
                themeColor={"warning"}
              >
                Réinitialiser
              </MbjButton>
            )}
            <MbjButton isPending={mutation.isLoading}>Confirmer</MbjButton>
          </MbjFlex>
        </MbjFlex>
      </form>
    </div>
  );
};

export const InformationsStagiaireCard = styled(InformationsStagiaireCardCtrl)((props) => ({
  padding: "0.5rem",
  ".wrap_avatar": {
    marginBottom: "20px",
  },
}));
