import styled from "styled-components";
import * as React from "react";
import { useEffect, useState } from "react";
import { MbjMenuFloating } from "../../../molecules/MbjMenu/desktop/MbjMenuFloating";
import { MbjSubMenuAction } from "../../../atoms/MbjItemMenu/desktop/MbjSubMenuAction";
import { FaUnlock } from "react-icons/fa";
import { MbjBlockMenu, MbjDirectLink } from "@mbj-front-monorepo/ui";
import { useLocation, useNavigate } from "react-router-dom";

interface MbjHeaderCtrlProps {
  className?: string;
  userLogo?: React.ReactElement;
  disconnectCallBack: () => void;
  Blocks: MbjBlockMenu[];
}

const MbjHeaderCtrl = (props: MbjHeaderCtrlProps) => {
  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const [myPath, setMyPath] = useState<string>();
  const location = useLocation();
  const navigate = useNavigate();
  const Disconnect = () => {
    props.disconnectCallBack();
    navigate("/login");
  };
  useEffect(() => {
    const TabLocation = location.pathname.split("/");
    if (TabLocation.length > 2) {
      const allSubmenus = props.Blocks.reduce(
        (acc: MbjDirectLink[], item: MbjBlockMenu) => (item.subMenus ? [...acc, ...item.subMenus] : acc),
        []
      );
      const mySub = allSubmenus.find((s) => {
        const TabPath = s.path.split("_");
        const Pathy = TabPath[1];
        return Pathy === TabLocation[3];
      });
      const mySecond = mySub ? mySub.libelle : "";
      const Reste = TabLocation[2].slice(1).replace("_", " ");
      setMyPath(TabLocation[2][0].toUpperCase() + Reste + " > " + mySecond);
    } else {
      setMyPath(TabLocation[2]);
    }
  }, [location, props.Blocks]);
  return (
    <div className={`mbj_header ${props.className}`}>
      <div className={`ariane`}>{myPath}</div>

      <div className={`tool_space`}>
        {/*<AlertNotifications/>*/}
        {props.userLogo && (
          <div className={`btn_space`} onClick={() => setOpenMenu((o) => !o)}>
            {props.userLogo}
          </div>
        )}
      </div>
      {openMenu && (
        <MbjMenuFloating top={"100%"} left={"auto"} right={"10px"} closeMe={() => setOpenMenu(false)}>
          <MbjSubMenuAction
            icon={<FaUnlock title="disconnect icône" />}
            libelle={"Deconnexion"}
            callback={Disconnect}
          />
        </MbjMenuFloating>
      )}
    </div>
  );
};

export const MbjHeader = styled(MbjHeaderCtrl)`
  height: 66px;
  background: transparent;
  padding: 5px;
  display: flex;
  justify-content: space-between;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  .ariane {
    padding: 0 1rem;
    font-size: 22px;
    color: ${(props) => props.theme.primary};
  }
  .tool_space {
    background: transparent;
    //box-shadow: 1px 1px 3px rgba(0,0,0,.2);
    min-width: 450px;
    display: flex;
    justify-content: flex-end;
    gap: 8px;
    padding: 0.25rem;
    border-radius: 4px;
  }
  .btn_space {
    width: 36px;
    background: white;
    height: 36px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: solid 1px ${(props) => props.theme.primaryLighten};
    padding: 0.1rem;
    img {
      width: 100%;
    }
    &:hover {
      cursor: pointer;
    }
  }
`;
