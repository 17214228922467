import styled from "styled-components";
import {MemberDetailsRecrutement} from "../components/MemberDetailsRecrutement";
import {useGetMember, useGetMetier, useGetSearchOne} from "@mbj-front-monorepo/data-access";
import {MbjLoading} from "@mbj-front-monorepo/ui";
import {MbjCompareReferentiel} from "@mbj-front-monorepo/data-ui";
import {OneItemSearchM} from "./SearchsM";

interface ModalDetailsSearchMCtrlProps {
  className?:string;
  OneSearchM:OneItemSearchM;
  idCompare:number;
}

const ModalDetailsSearchMCtrl = ({className, OneSearchM, idCompare}: ModalDetailsSearchMCtrlProps) => {
  const MemberQuery = useGetMember(OneSearchM.idMember);
  const MetierQuery = useGetMetier(OneSearchM.idMetier)
  return (
    <div className={`details_um ${className}`}>
      {(MemberQuery.isLoading || MetierQuery.isLoading) ? <MbjLoading/> : (!MemberQuery.data || MemberQuery.isError || !MetierQuery.data || MetierQuery.isError) ? 'ErrorBD':
        <div className="in_details">
          {MemberQuery.data &&
            <div className="block_infos">
              <h2>Informations recruteur</h2>
              <MemberDetailsRecrutement Member={MemberQuery.data}/>
            </div>
          }
          <div className="block_infos">
            <h2>Informations Métier</h2>
            <div className="libelle_poste">{MetierQuery.data.libelle}</div>
            <div className="wrap_compare">
              <MbjCompareReferentiel idToCompare1={idCompare} idToCompare2={MetierQuery.data.id||0} typeCompared={"SM"}/>
            </div>
          </div>
        </div>
      }
    </div>
  )
}

export const ModalDetailsSearchM = styled(ModalDetailsSearchMCtrl)`
  padding: 1.5rem;
  height: 70vh;
  overflow-y: auto;
  .libelle_poste{
    font-size: 18px;
    font-weight: bold;
    margin: 15px auto;
  }
`
