import styled from "styled-components";
import {components} from "@mbj-front-monorepo/data-access";
import {AvatarFact} from "@mbj-front-monorepo/ui";

interface OneSearchAllianceCardCtrlProps {
  className?:string;
  Recrut:components["schemas"]["OneRecrutStagiaireAlliance-read.stagiaireRecrutement_read.Alliance_read.Member_read.UserMetier_read.Metier_read.StagiaireProxiMetier"]
}

const OneSearchAllianceCardCtrl = ({className, Recrut}: OneSearchAllianceCardCtrlProps) => {
  const handleError = (e:any)=>{
    e.target.src = AvatarFact;
  }
  return (
    <div className={`one_search_um_card ${className}`}>
      <div className={"content_card"}>
        <div className={"wrap_infos_base"}>
          <div className={"Avatar_place"}>
            <img
              src={`${process.env["REACT_APP_API_URL_ROOT"]}/logos/members/${Recrut.member?.logo}`}
              alt={"avatar_logo"}
              onError={handleError}
            />
          </div>
          <div className={"Name_place"}>
            <div className={`ent_name`}>{Recrut.userMetier?.Member?.name}</div>
            <div className={`search_name`}>{Recrut.userMetier?.namePerso}</div>
            <div className={`alliance_name`}>{Recrut.alliance?.name}</div>
          </div>
        </div>
        <div
          className={`Pc_place ${(Recrut.stagiaireProxiUserMetier?.score || 0) > 0.8 ? 'good' : (Recrut.stagiaireProxiUserMetier?.score || 0) > 0.7 ? 'medium' : 'bad'}`}>{Math.round((Recrut.stagiaireProxiUserMetier?.score || 0) * 100)}%
        </div>
      </div>
    </div>
  )
}

export const OneSearchAllianceCard = styled(OneSearchAllianceCardCtrl)`
  padding: 1rem;
  width: 48%;
  height: auto;
  &:hover {
    .content_card {
      box-shadow: 0 24px 38px 3px rgba(0, 0, 0, .14), 0 9px 46px 8px rgba(0, 0, 0, .12), 0 11px 15px -7px rgba(0, 0, 0, .2);
      cursor: pointer;
      transform: translateY(-5px);
      transition: 0.3s;
    }
  }

  .wrap_infos_base {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 69%;
    gap: 10px;
  }

  .Pc_place {
    position: absolute;
    right: 5px;
    top: -5px;
    font-size: 40px;

    &.good {
      color: ${props => props.theme.thirdDarken};
    }

    &.medium {
      color: ${props => props.theme.warningPastel};
    }

    &.bad {
      color: ${props => props.theme.warningDarken};
    }
  }

  .content_card {
    background: white;
    padding: 0.5rem;
    border-radius: 8px;
    position: relative;
  }
  .Avatar_place{
    width: 90px;
    height: 90px;
    margin-right: 8px;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    img{
      width: 100%;
      height: auto;
    }
  }
  .Name_place{
    font-weight: bold;
    .ent_name{
      color: ${props=>props.theme.primary};
    }
  }
`
