import styled from "styled-components";
import { useParams } from "react-router-dom";
import { useGetOneFormationOFMBJ, useUpdateInfoFormation } from "@mbj-front-monorepo/data-access";
import { MbjButton, MbjErrorData, MbjLoading } from "@mbj-front-monorepo/ui";
import React, { ReactNode, useEffect, useRef, useState } from "react";
import { InformationsUpdateForm } from "./InformationsUpdateForm";

interface InformationsFormationCtrlProps {
  className?: string;
}

interface OneInfoProps {
  libelle: string;
  info?: string | number;
  icon?: ReactNode;
}

const OneInfoForm = ({ libelle, info, icon }: OneInfoProps) => {
  return (
    <div className={`one_infos_form`}>
      {icon && <div className="wrap_icon">{icon}</div>}
      <div className="lib_info">{libelle}</div>
      <div className="info">{info ? info : "nc"}</div>
    </div>
  );
};

const InformationsFormationCtrl = ({ className }: InformationsFormationCtrlProps) => {
  const { id } = useParams();
  const FormationQuery = useGetOneFormationOFMBJ(id ? parseInt(id) : 0);
  const refTextA = useRef<HTMLTextAreaElement>(null);
  const [description, setDescription] = useState<string>("");
  const mutation = useUpdateInfoFormation(id ? parseInt(id) : 0);
  useEffect(() => {
    if (FormationQuery.data) {
      setDescription(FormationQuery.data.description || "");
    }
  }, [FormationQuery.data]);
  const handleChangeArea = () => {
    if (refTextA.current) {
      setDescription(refTextA.current.value);
    }
  };
  const saveDescrip = () => {
    mutation.mutate({ description: description });
  };

  return (
    <div className={`formations_infos ${className}`}>
      {FormationQuery.isLoading ? (
        <MbjLoading />
      ) : FormationQuery.isError ? (
        <MbjErrorData />
      ) : (
        <div className="in_infos">
          <div className="block_info">
            <div className="head_block">Description de la formation</div>
            <div className="body_block">
              <textarea ref={refTextA} className={"text_area"} value={description} onChange={handleChangeArea} />
            </div>
            <div className="footer_block">
              <div className={`wrap_buttons_right`}>
                <MbjButton size={"sm"} themeColor={"primary"} onClick={saveDescrip}>
                  Enregistrer
                </MbjButton>
              </div>
            </div>
          </div>
          <div className="wrap_multi">
            <div className="block_info">
              <div className="head_block">Prérequis</div>
              <div className="body_block">{FormationQuery.data?.prerequis}</div>
            </div>
            <div className="block_info">
              <div className="head_block">Informations</div>
              <div className="body_block">
                <InformationsUpdateForm data={FormationQuery.data} />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export const InformationsFormation = styled(InformationsFormationCtrl)`
  padding: 2% 1%;
  .wrap_multi {
    display: flex;
    justify-content: flex-start;
    gap: 12px;
  }
  .flex_col {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .in_infos {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .wrap_buttons_right {
    display: flex;
    justify-content: flex-end;
  }

  .one_infos_form {
    display: flex;
    justify-content: flex-start;
    gap: 8px;
    align-items: center;
    font-size: 18px;

    .wrap_icon {
      display: flex;
      justify-content: center;
      flex-direction: column;
    }

    .lib_info {
      font-weight: bold;
    }
  }

  .block_info {
    width: 100%;
    background: white;
    border-radius: 4px;
    .head_block {
      padding: 0.5rem;
      font-weight: bold;
    }
    .body_block {
      padding: 1rem;
      .text_area {
        width: 100%;
        border: solid ${(props) => props.theme.primaryLighten} 1px;
        border-radius: 4px;
        padding: 0.25rem;
        resize: none;
      }
    }
    .footer_block {
      padding: 0.5rem;
    }
  }
`;
