import {
  MbjButton,
  MbjErrorData,
  MbjFlex,
  MbjLoading,
  MbjSimpleInput,
  MbjSimpleInputContainer,
} from "@mbj-front-monorepo/ui";
import styled from "styled-components";
import { useSearchParams } from "react-router-dom";
import { useGetOneSession, useUpdateSession } from "@mbj-front-monorepo/data-access";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { getDateEN } from "@mbj-front-monorepo/utils";

interface UpdateSessionCtrlProps {
  className?: string;
}
const UpdateSessionCtrl = (props: UpdateSessionCtrlProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const SessionQuery = useGetOneSession(searchParams.get("idSession") || "");
  const mutation = useUpdateSession(searchParams.get("idSession") || "");

  const defaultValues = {
    startAt: SessionQuery.data?.startAt ? getDateEN(new Date(SessionQuery.data?.startAt)) : "",
    endAt: SessionQuery.data?.endAt ? getDateEN(new Date(SessionQuery.data?.endAt)) : "",
  };

  const methods = useForm({
    mode: "onChange",
    defaultValues: defaultValues,
  });

  useEffect(() => {
    methods.reset(defaultValues);
  }, [methods.reset, SessionQuery]);

  return (
    <div className={props.className}>
      {SessionQuery.isLoading ? (
        <MbjLoading />
      ) : SessionQuery.isError ? (
        <MbjErrorData />
      ) : (
        <form
          id={"newSession"}
          onSubmit={methods.handleSubmit((data) => {
            console.log(data);
            mutation.mutate(data);
          })}
        >
          <MbjFlex direction={"column"}>
            <MbjSimpleInputContainer errors={methods.formState.errors} label={"Date de début*"}>
              <MbjSimpleInput
                name={"startAt"}
                id={"startAt"}
                type={"date"}
                noPadding
                register={methods.register}
                registerOptions={{ required: { value: true, message: "date de début requise" } }}
              />
            </MbjSimpleInputContainer>
            <MbjSimpleInputContainer errors={methods.formState.errors} label={"Date de début*"}>
              <MbjSimpleInput
                name={"endAt"}
                id={"endAt"}
                type={"date"}
                noPadding
                register={methods.register}
                registerOptions={{ required: { value: true, message: "date de fin requise" } }}
              />
            </MbjSimpleInputContainer>
            <MbjButton alignSelf={"flex-end"} isPending={mutation.isLoading}>
              Modifier la session
            </MbjButton>
          </MbjFlex>
        </form>
      )}
    </div>
  );
};

export const UpdateSession = styled(UpdateSessionCtrl)((props) => ({
  padding: "1rem",
}));
