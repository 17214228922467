import { Route, Routes } from "react-router-dom";
import React from "react";
import { MesAlliances } from "./MesAlliances";
import { InternDesktopLayout, MbjLink, MbjSectionMenu } from "@mbj-front-monorepo/ui";
import { InvitationsAlliance } from "../Invitations/InvitationsAlliance";

interface MesAlliancesRooterProps {
  className?: string;
}
export function MesAlliancesRooter(props: MesAlliancesRooterProps) {
  return (
    <Routes>
      <Route
        element={
          <InternDesktopLayout
            type="OF"
            mbjSectionMenu={
              <MbjSectionMenu
                className={"custom-sectionMenu " + props.className}
                otherContent={<div className={"custom-content-menu"}></div>}
              >
                <MbjLink to=".">Mes Alliances</MbjLink>
                <MbjLink to="invitations">Invitations</MbjLink>
              </MbjSectionMenu>
            }
          />
        }
      >
        <Route path={""} element={<MesAlliances />} />
        <Route path={"invitations"} element={<InvitationsAlliance />} />
      </Route>
    </Routes>
  );
}
