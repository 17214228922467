import styled from "styled-components";
import { useAddSession } from "@mbj-front-monorepo/data-access";
import { useForm } from "react-hook-form";
import { MbjButton, MbjSimpleInput, MbjSimpleInputContainer } from "@mbj-front-monorepo/ui";
import React from "react";

interface ModalNewSessionCtrlProps {
  className?: string;
  idFormation: number;
  closeMe: () => void;
}

const ModalNewSessionCtrl = ({ className, closeMe, idFormation }: ModalNewSessionCtrlProps) => {
  const mutation = useAddSession();
  const methods = useForm({
    mode: "onChange",
    defaultValues: {
      startAt: "",
      endAt: "",
    },
  });
  const onSubmit = (data: any) => {
    const datas: any = {
      FormationOFMBJ: `/api/formations_ofmbjs/${idFormation}`,
      startAt: data.startAt.slice(0, 10),
      endAt: data.endAt.slice(0, 10),
    };
    mutation.mutate(datas, {
      onSuccess: () => {
        closeMe();
      },
    });
  };
  return (
    <div className={`form_new_session_formation ${className}`}>
      <form id={"newSession"} onSubmit={methods.handleSubmit(onSubmit)}>
        <MbjSimpleInputContainer errors={methods.formState.errors} label={"Date de début*"}>
          <MbjSimpleInput
            name={"startAt"}
            id={"startAt"}
            type={"date"}
            noPadding
            register={methods.register}
            registerOptions={{ required: { value: true, message: "date début requise" } }}
          />
        </MbjSimpleInputContainer>
        <MbjSimpleInputContainer errors={methods.formState.errors} label={"Date de début*"}>
          <MbjSimpleInput
            name={"endAt"}
            id={"endAt"}
            type={"date"}
            noPadding
            register={methods.register}
            registerOptions={{ required: { value: true, message: "date fin requise" } }}
          />
        </MbjSimpleInputContainer>
        <MbjButton alignSelf={"flex-end"} isPending={mutation.isLoading}>
          Créer la session
        </MbjButton>
      </form>
    </div>
  );
};

export const ModalNewSession = styled(ModalNewSessionCtrl)`
  padding: 1rem;
  form {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
`;
